import { ADMIN, GRANUAL_USER, PARTNER, PARTNER_USER } from "@utils/roleRights";

export const NAVLINKS = [
  {
    id: 1,
    label: 'Dashboard',
    path: 'dashboard',
    isDisabled: false,
    extraPaths: ['/'],
    access: [GRANUAL_USER, PARTNER_USER, PARTNER, ADMIN],
  },
  {
    id: 2,
    label: 'Partner Projects',
    path: 'partner',
    isDisabled: false,
    extraPaths: [],
    access: [GRANUAL_USER],
  },
];
