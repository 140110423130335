import Card from '@common/Card';
import Chart from './components/Chart';
import PartnersData from './components/PartnersData';
import FormInput from '@common/FormInput';
import ProjectStatusCard from './components/ProjectStatusCard';
import completedIcon from '@assets/icons/input/completed.svg';
import completedDelayedIcon from '@assets/icons/input/completed_delayed.svg';
import delayedIcon from '@assets/icons/input/delayed.svg';
import ongoingIcon from '@assets/icons/input/ongoing.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBottomPartners,
  getLeadingPartners,
  getProjectsByDate,
  getProjectsByMonth,
  selectAdminSlice,
} from './redux/slice';
import { useEffect, useState } from 'react';
import { Form } from 'antd';
import ProjectDetails from './components/ProjectDetails';
import { getFormattedDateToSend, getTotalMonths } from '@utils/helpers';
import moment from 'moment';

export default function Admin() {
  const {
    projectsData,
    projectsDataLoading,
    leadingPartners,
    leadingPartnersLoading,
    bottomPartners,
    bottomPartnersLoading,
  } = useSelector(selectAdminSlice);
  const dispatch = useDispatch();
  const [activeStatus, setActiveStatus] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(12);
  const [projectStartDate, setProjectStartDate] = useState(null);
  const [totalProjectRangeMonths, setTotalProjectRangeMonths] = useState(null);
  const [form] = Form.useForm();

  //handling click on project status setting active status card
  const handleClickOnProjectStatusCard = (id) => {
    if (activeStatus === id) {
      setActiveStatus(null);
    } else {
      setActiveStatus(id);
    }
  };

  //get percentage
  const getPercentage = (key) => {
    const currentValue = parseInt(projectsData?.data?.[0]?.[key]);
    const lastYearValue = parseInt(projectsData?.last_year_data?.[0]?.[key]);

    if (currentValue === lastYearValue) {
      return '0%';
    }

    if (currentValue == null || lastYearValue == null || currentValue === 0) {
      return '100%';
    }

    const percentage = ((currentValue - lastYearValue) / currentValue) * 100;
    return `${Math.abs(percentage.toFixed(2))}%`;
  };

  //get rise down condition
  const getRiseDownCond = (key) => {
    const currentValue = parseInt(projectsData?.data?.[0]?.[key]);
    const lastYearValue = parseInt(projectsData?.last_year_data?.[0]?.[key]);

    if (currentValue > lastYearValue) {
      return 'rise';
    } else if (currentValue < lastYearValue) {
      return 'fall';
    } else {
      return 'stable';
    }
  };

  //transforming project cards
  const transformedProjectCards = [
    {
      id: 1,
      heading: 'Completed',
      count: projectsData?.data?.[0]?.completedCount,
      percentage: getPercentage('completedCount'),
      icon: <img src={completedIcon} />,
      rise_down_condition: getRiseDownCond('completedCount'),
      countColor: '#0092DD',
    },
    {
      id: 2,
      heading: 'Ongoing',
      count: projectsData?.data?.[0]?.ongoingCount,
      percentage: getPercentage('ongoingCount'),
      icon: <img src={ongoingIcon} />,
      rise_down_condition: getRiseDownCond('ongoingCount'),
      countColor: '#78D1FF',
    },
    {
      id: 3,
      heading: 'Delayed',
      count: projectsData?.data?.[0]?.delayedCount,
      percentage: getPercentage('delayedCount'),
      icon: <img src={delayedIcon} />,
      rise_down_condition: getRiseDownCond('delayedCount'),
      countColor: '#F43E3E',
    },
    {
      id: 4,
      heading: 'Completed-Delayed',
      count: projectsData?.data?.[0]?.completedDelayedCount,
      percentage: getPercentage('completedDelayedCount'),
      icon: <img src={completedDelayedIcon} />,
      rise_down_condition: getRiseDownCond('completedDelayedCount'),
      countColor: '#FF8686',
    },
  ];

  //on form values change
  const onFormValuesChange = (changedVal, values) => {
    // fetching project data on project date change
    if (changedVal.project_date_range) {
      setSelectedMonth(null);
      const payload = {
        startDate: getFormattedDateToSend(values.project_date_range[0]),
        endDate: getFormattedDateToSend(values.project_date_range[1]),
      };
      setTotalProjectRangeMonths(
        getTotalMonths(payload.startDate, payload.endDate),
      );
      dispatch(getProjectsByDate(payload));
    }

    // fetching leading and bottom partners on date change
    if (changedVal.partners_date_range) {
      const payload = {
        startDate: getFormattedDateToSend(values.partners_date_range[0]),
        endDate: getFormattedDateToSend(values.partners_date_range[1]),
      };
      dispatch(getLeadingPartners(payload));
      dispatch(getBottomPartners(payload));
    }
  };

  // project calender state
  const handleCalendarChange = (dates) => {
    setProjectStartDate(dates ? dates[0] : null);
  };

  const disabledEndDate = (current) => {
    if (!projectStartDate) return false;
    const maxEndDate = projectStartDate.add(12, 'month').endOf('month');
    return current && (current < projectStartDate || current > maxEndDate);
  };

  // fetching project data on project month change
  useEffect(() => {
    if (selectedMonth) {
      const payload = {
        type: 'months',
        duration: selectedMonth,
      };
      setTotalProjectRangeMonths(null);
      setProjectStartDate(null);
      form.setFieldsValue({ project_date_range: null });
      dispatch(getProjectsByMonth(payload));
    }
  }, [dispatch, selectedMonth]);

  return (
    <Form
      className="pb-4"
      onValuesChange={onFormValuesChange}
      layout="vertical"
      form={form}
    >
      <Card
        className="flex flex-col gap-8 my-2 md:mt-8 md:mb-4"
        headerClasses={'font-segoe-ui font-semibold text-2xl text-primary-bg'}
        header={'Welcome! Admin'}
      >
        {/* project data */}
        <Card
          loading={projectsDataLoading}
          className={
            ' w-full p-5 flex flex-col gap-2 rounded-lg !bg-primary-card font-segoe-ui shadow-equal-sides'
          }
          header={
            <>
              <h3 className="text-xl font-semibold font-segoe-ui text-tertiary-placeholder">
                Projects
              </h3>
              {/* timelines */}
              <div className="flex">
                <div
                  onClick={() => setSelectedMonth(12)}
                  className={`items-center px-4 pt-2.5 cursor-pointer text-xs rounded-s-lg border border-primary-placeholder ${selectedMonth === 12 ? 'font-semibold bg-[#EEF3F8] text-[#1D2939]' : 'text-tertiary-text'}`}
                >
                  12 Months
                </div>
                <div
                  onClick={() => setSelectedMonth(6)}
                  className={`items-center px-4 pt-2.5 cursor-pointer text-xs border border-primary-placeholder ${selectedMonth === 6 ? 'font-semibold bg-[#EEF3F8] text-[#1D2939]' : 'text-tertiary-text'}`}
                >
                  6 Months
                </div>
                <div
                  onClick={() => setSelectedMonth(1)}
                  className={`items-center px-4 pt-2.5 cursor-pointer text-xs border border-primary-placeholder ${selectedMonth === 1 ? 'font-semibold bg-[#EEF3F8] text-[#1D2939]' : 'text-tertiary-text'}`}
                >
                  1 Month
                </div>
                <FormInput
                  name={'project_date_range'}
                  type="date_range"
                  placeholder={['Start date', 'End date']}
                  inputClasses={
                    '!py-2 rounded-s-none rounded-e-lg bg-primary-card'
                  }
                  disabledDate={disabledEndDate}
                  onCalendarChange={handleCalendarChange}
                  allowClear
                />
              </div>
            </>
          }
          headerClasses={
            'flex justify-between gap-2 md:items-center items-start flex-col md:flex-row'
          }
        >
          {/* chart */}
          <div className="border-b border-[#E8E9EB] mt-2">
            {projectsData?.projects && (
              <Chart
                data={projectsData?.projects}
                monthDuration={selectedMonth}
                totalProjectRangeMonths={
                  totalProjectRangeMonths === 13 ? 12 : totalProjectRangeMonths
                }
                projectStartDate={projectStartDate}
              />
            )}
          </div>

          {/* project status cards */}
          <div className="flex flex-col gap-1 md:gap-0 md:grid md:grid-cols-2 lg:grid-cols-4">
            {transformedProjectCards.map((project) => {
              return (
                <ProjectStatusCard
                  key={project.id}
                  data={project}
                  isActive={project.id === activeStatus}
                  onClick={() => handleClickOnProjectStatusCard(project.id)}
                />
              );
            })}
          </div>

          {/* project details */}
          {activeStatus && (
            <div className="mt-4">
              <ProjectDetails
                activeStatus={activeStatus}
                projectList={projectsData?.projects}
              />
            </div>
          )}
        </Card>

        {/* partners data */}
        <Card
          loading={bottomPartnersLoading || leadingPartnersLoading}
          className={
            'w-full p-5 rounded-lg !bg-primary-card font-segoe-ui shadow-equal-sides'
          }
          header={
            <>
              <h3 className="text-xl font-semibold font-segoe-ui text-tertiary-placeholder">
                Partners
              </h3>
              <FormInput
                name={'partners_date_range'}
                type="date_range"
                placeholder={['Start date', 'End date']}
                inputClasses={
                  '!py-2 rounded-lg bg-primary-card'
                }
              />
            </>
          }
          headerClasses={'flex justify-between items-center'}
        >
          <PartnersData
            leadingPartners={leadingPartners}
            bottomPartners={bottomPartners}
            parentForm={form}
          />
        </Card>
      </Card>
    </Form>
  );
}
