import { FallOutlined, RiseOutlined } from '@ant-design/icons';

export default function ProjectStatusCard({ data, isActive, onClick }) {
  const isRise = data?.rise_down_condition === 'rise';
  const isfall = data?.rise_down_condition === 'fall';
  const isStable = data?.rise_down_condition === 'stable';
  return (
    <div
      onClick={onClick}
      className={`${data.id === 1 && 'md:rounded-ss-lg lg:rounded-s-lg'} ${data.id === 2 && 'md:rounded-se-lg lg:rounded-none'} ${data.id === 3 && 'md:rounded-es-lg lg:rounded-none'} ${data.id === 4 && 'md:rounded-ee-lg lg:rounded-e-lg'} border rounded-lg md:rounded-none shadow-equal-sides relative hover:shadow-equal-sides-dark select-none cursor-pointer ${isActive ? 'bg-secondary-bg border-b-4 border-b-primary-bg' : 'border-[#E8E9EB] bg-[#F9FAFC]'}`}
    >
      <div className="flex flex-col gap-1 px-6 py-3 font-segoe-ui">
        <h4 className="text-sm font-semibold text-[#A4ABBE]">{data.heading}</h4>
        <div className="flex items-end gap-5">
          <span
            className="text-5xl font-semibold font-segoe-ui "
            style={{ color: data.countColor }}
          >
            {data?.count}
          </span>
          <div className="font-segoe-ui">
            {isRise ? (
              <RiseOutlined className="!text-primary-green font-semibold text-md" />
            ) : isfall ? (
              <FallOutlined className="!text-primary-red rotate-90 font-semibold text-md" />
            ) : isStable ? (
              <RiseOutlined className="!text-tertiary-placeholder font-semibold text-md" />
            ) : null}
            <span
              className={`${isRise ? '!text-primary-green' : isfall ? '!text-primary-red' : '!text-tertiary-placeholder'} font-semibold text-xs`}
            >
              {data?.percentage}
            </span>
            <span className="text-tertiary-placeholder text-2xs">
              vs last year
            </span>
          </div>
        </div>
      </div>
      <div className="absolute top-2.5 right-2.5">{data.icon}</div>
    </div>
  );
}
