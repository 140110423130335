import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  checkDupPartner,
  checkDupProject,
  partnerAddresses,
  partners,
  partnerTypes,
} from './api';

const actions = {
  PARTNER_TYPES: 'masters/PARTNER_TYPES',
  PARTNERS: 'masters/PARTNERS',
  PARTNER_ADDRESSES: 'masters/PARTNER_ADDRESSES',
  CHECK_DUP_PROJECT_NAME: 'masters/CHECK_DUP_PROJECT_NAME',
  CHECK_DUP_PARTNER_EMAIL: 'masters/CHECK_DUP_PARTNER_EMAIL',
};

export const getPartnerTypes = createAsyncThunk(
  actions.PARTNER_TYPES,
  async (payload) => {
    const response = await partnerTypes(payload);
    return response;
  },
);

export const getPartners = createAsyncThunk(
  actions.PARTNERS,
  async (payload) => {
    const response = await partners(payload);
    return response;
  },
);

export const getPartnerAddresses = createAsyncThunk(
  actions.PARTNER_ADDRESSES,
  async (payload) => {
    const response = await partnerAddresses(payload);
    return response;
  },
);

export const checkDupProjectName = createAsyncThunk(
  actions.CHECK_DUP_PROJECT_NAME,
  async (payload) => {
    const response = await checkDupProject(payload);
    return response;
  },
);

export const checkDupPartnerEmail = createAsyncThunk(
  actions.CHECK_DUP_PARTNER_EMAIL,
  async (payload) => {
    const response = await checkDupPartner(payload);
    return response;
  },
);

const initialState = {
  partnerTypesLoading: false,
  partnerTypesList: [],
  partnersLoading: false,
  partnersList: [],
  partnerAddressesLoading: false,
  partnerAddressesList: [],
  checkDupProjectLoading: false,
  projectAlreadyExist: false,
  checkDupPartnerEmailLoading: false,
  partnerEmailAlreadyExist: false,
};

export const mastersSlice = createSlice({
  name: 'masters',
  initialState,
  reducers: {
    resetProjectNameStatus: (state) => {
      state.projectAlreadyExist = false;
    },
  },
  extraReducers: (builder) => {
    // get partner types
    builder
      .addCase(getPartnerTypes.pending, (state) => {
        state.partnerTypesLoading = true;
      })
      .addCase(getPartnerTypes.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.partnerTypesLoading = false;

        if (success) {
          state.partnerTypesList = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getPartnerTypes.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.partnerTypesLoading = false;
        message.error(errorMsg);
      });

    // get partners list
    builder
      .addCase(getPartners.pending, (state) => {
        state.partnersLoading = true;
      })
      .addCase(getPartners.fulfilled, (state, action) => {
        const { status, message: msg, data } = action.payload;
        state.partnersLoading = false;

        if (status) {
          state.partnersList = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getPartners.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.partnersLoading = false;
        message.error(errorMsg);
      });

    // get partner addresses
    builder
      .addCase(getPartnerAddresses.pending, (state) => {
        state.partnerAddressesLoading = true;
      })
      .addCase(getPartnerAddresses.fulfilled, (state, action) => {
        const { status, message: msg, data } = action.payload;
        state.partnerAddressesLoading = false;

        if (status) {
          state.partnerAddressesList = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getPartnerAddresses.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.partnerAddressesLoading = false;
        message.error(errorMsg);
      });

    // check duplicate project name
    builder
      .addCase(checkDupProjectName.pending, (state) => {
        state.checkDupProjectLoading = true;
      })
      .addCase(checkDupProjectName.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.checkDupProjectLoading = false;

        if (success) {
          state.projectAlreadyExist = false;
        } else {
          state.projectAlreadyExist = true;
        }
      })
      .addCase(checkDupProjectName.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.checkDupProjectLoading = false;
        message.error(errorMsg);
      });

    // check duplicate partner email
    builder
      .addCase(checkDupPartnerEmail.pending, (state) => {
        state.checkDupPartnerEmailLoading = true;
      })
      .addCase(checkDupPartnerEmail.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.checkDupPartnerEmailLoading = false;

        if (success) {
          state.partnerEmailAlreadyExist = false;
        } else {
          state.partnerEmailAlreadyExist = true;
        }
      })
      .addCase(checkDupPartnerEmail.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.checkDupPartnerEmailLoading = false;
        message.error(errorMsg);
      });
  },
});

export const { resetProjectNameStatus } = mastersSlice.actions;

export const selectMastersSlice = (state) => {
  return state.masters;
};

export default mastersSlice.reducer;
