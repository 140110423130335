import { useEffect, useState } from 'react';
import PartnerCard from './PartnerCard';
import { PARTNER_CARDS } from '@features/data/admin/partner_cards';
import { useDispatch, useSelector } from 'react-redux';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import ActivityDownloadTable from './ActivityDownloadTable';
import {
  columnRenderer,
  getFormattedDateToSend,
  headerRenderer,
} from '@utils/helpers';
import { Button, Col, message, Row, Select, Tooltip } from 'antd';
import apiClient, { getLocalToken } from '@utils/apiClient';
import graphIcon from '@assets/icons/input/graph.svg';
import FormInput from '@common/FormInput';
import { getPartners, selectMastersSlice } from '@features/masterRedux/slice';
import { getTop5ProjectsByPartner, selectAdminSlice } from '../redux/slice';

export default function PartnersData({
  leadingPartners,
  bottomPartners,
  parentForm,
}) {
  const [activePartnerCard, setActivePartnerCard] = useState(null);
  const [activePartner, setActivePartner] = useState(null);
  const [downloadingId, setDownloadingId] = useState(null);
  const dispatch = useDispatch();
  const { partnersLoading, partnersList } = useSelector(selectMastersSlice);
  const { projectsByPartner, projectsByPartnerLoading } =
    useSelector(selectAdminSlice);

  //handling click on partner card setting active partners card
  const handleClickOnPartnerCard = (index) => {
    if (activePartnerCard === index) {
      setActivePartnerCard(null);
    } else {
      if (
        (index === 0 || index === 1) &&
        (!leadingPartners.length || !bottomPartners.length)
      ) {
        message.error('Please select a date');
        return;
      }
      setActivePartnerCard(index);
    }
  };

  //download single activity
  const handleDownloadSingleActivity = async (record, type) => {
    setDownloadingId(record.id);
    try {
      const params = new URLSearchParams({
        type,
        id: record.partner_id,
        project_id: record.project_id,
        type_id: record.partner_type_id,
        partner_user_id: record.partner_user_id,
      });
      const response = await fetch(
        `${apiClient.Urls.downloadActivityByPartnerProjectType}?${params}`,
        {
          headers: {
            Authorization: getLocalToken(),
          },
        },
      );
      if (!response.ok) {
        throw new Error('Failed to download score card...');
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', 'activity.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setDownloadingId(null);
      message.error(error.message || 'Failed to download activity...');
    } finally {
      setDownloadingId(null);
    }
  };

  const columns = [
    {
      title: headerRenderer('Partner Company'),
      dataIndex: 'partner',
      key: 'partner',
      ellipsis: true,
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Project'),
      dataIndex: 'project',
      key: 'project',
      ellipsis: true,
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Partner User'),
      dataIndex: 'partner_user',
      key: 'partner_user',
      ellipsis: true,
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Partner Type'),
      dataIndex: 'partner_type',
      key: 'partner_type',
      ellipsis: true,
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Performance Rating'),
      dataIndex: 'performance_rating',
      key: 'performance_rating',
      ellipsis: true,
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Project Status'),
      dataIndex: 'project_status',
      key: 'project_status',
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Activity'),
      dataIndex: 'activity',
      key: 'activity',
      width: '14%',
      render: (text, record, index) =>
        downloadingId === record?.id ? (
          <LoadingOutlined spin />
        ) : (
          <Tooltip title="Download">
            <DownloadOutlined
              className="text-xl text-primary-bg"
              onClick={() =>
                handleDownloadSingleActivity(record.partnerData, 'Partner')
              }
            />
          </Tooltip>
        ),
    },
  ];

  //download project/partner activity
  const handleDownloadActivity = async (id, type) => {
    setDownloadingId(id);
    try {
      const params = new URLSearchParams({
        type,
        id,
      });
      const response = await fetch(
        `${apiClient.Urls.downloadActivity}?${params}`,
        {
          headers: {
            Authorization: getLocalToken(),
          },
        },
      );
      if (!response.ok) {
        throw new Error('Failed to download score card...');
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', 'activity.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setDownloadingId(null);
      message.error(error.message || 'Failed to download activity...');
    } finally {
      setDownloadingId(null);
    }
  };

  //download all top activities
  const handleDownloadActivitiesByDate = async (type) => {
    setDownloadingId('all');
    try {
      const values = parentForm.getFieldsValue();
      const params = new URLSearchParams({
        type,
        startDate: getFormattedDateToSend(values.partners_date_range[0]),
        endDate: getFormattedDateToSend(values.partners_date_range[1]),
      });
      const response = await fetch(`${apiClient.Urls.downloadTop5}?${params}`, {
        headers: {
          Authorization: getLocalToken(),
        },
      });
      if (!response.ok) {
        throw new Error('Failed to download score card...');
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', 'activity.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setDownloadingId(null);
      message.error(error.message || 'Failed to download activity...');
    } finally {
      setDownloadingId(null);
    }
  };

  //handle partner change
  const handleOnPartnerChange = (e) => {
    const payload = { id: e };
    setActivePartner(e);
    dispatch(getTop5ProjectsByPartner(payload));
  };

  //transforming partners list
  const transformedPartnersList = partnersList?.map((pa) => {
    return { label: pa.partner_company_name, value: pa.id };
  });

  //tranforming leading and bottom partners
  const getTranformedData = (list) => {
    return list.map((item) => {
      return {
        key: item.id,
        id: item.id,
        partner: item.partner?.partner_company_name,
        project: item.Project.project_name,
        partner_type: item.PartnerType.type,
        performance_rating: item.rating,
        partner_user: item.contact.contact_name,
        project_status: item.Project.status,
        partnerData: item,
      };
    });
  };

  //tranformed leading partners data
  const transformedData =
    activePartnerCard === 0
      ? getTranformedData(leadingPartners)
      : activePartnerCard === 1
        ? getTranformedData(bottomPartners)
        : [];

  //tranforming partner projects
  const transformedPartnerProjects = projectsByPartner.map((project) => {
    return {
      key: project.id,
      id: project.id,
      project_id: project.Project.id,
      project: project.Project.project_name,
      partner_type: project.PartnerType.type,
      performance_rating: project.rating,
      project_status: project.Project.status,
      projectData: project,
      partner_user: project.contact.contact_name,
    };
  });

  //fetching partners list
  useEffect(() => {
    dispatch(getPartners());
  }, [dispatch]);

  return (
    <div className="flex flex-col mt-2">
      <div className="flex flex-col gap-1 md:gap-0 md:grid md:grid-cols-2 lg:grid-cols-4">
        {PARTNER_CARDS.map((partner) => {
          return (
            <PartnerCard
              onClick={() => handleClickOnPartnerCard(partner.index)}
              key={partner.index}
              data={partner}
              isActive={activePartnerCard === partner.index}
            />
          );
        })}
      </div>

      {/* table */}
      <div>
        {
          // if leading card selected
          activePartnerCard === 0 ? (
            <ActivityDownloadTable
              columns={columns}
              dataSource={transformedData}
              heading={'5 Leading Partner'}
              onClose={() => setActivePartnerCard(null)}
              headingClasses="font-semibold font-segoe-ui text-tertiary-placeholder"
              containerClasses={'mt-8 px-4'}
              footerComp={() => (
                <Button
                  className="!m-0 float-end !p-0 !font-segoe-ui !text-xs !font-semibold"
                  type="link"
                  icon={<DownloadOutlined />}
                  onClick={() => handleDownloadActivitiesByDate('leading')}
                  loading={downloadingId}
                >
                  Download All
                </Button>
              )}
            />
          ) : // if bottom card selected
          activePartnerCard === 1 ? (
            <ActivityDownloadTable
              columns={columns}
              dataSource={transformedData}
              heading={'5 Bottom Partner'}
              onClose={() => setActivePartnerCard(null)}
              headingClasses="font-semibold font-segoe-ui text-tertiary-placeholder"
              containerClasses={'mt-8 px-4'}
              footerComp={() => (
                <Button
                  className="!m-0 float-end !p-0 !font-segoe-ui !text-xs !font-semibold"
                  type="link"
                  icon={<DownloadOutlined />}
                  onClick={() => handleDownloadActivitiesByDate('bottom')}
                  loading={downloadingId}
                >
                  Download All
                </Button>
              )}
            />
          ) : // if no card selected
          null
        }

        {/* //partner performance card */}
        <div className="flex flex-col gap-1 mt-5 md:gap-0 md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <PartnerCard
            onClick={() => handleClickOnPartnerCard(2)}
            data={{
              index: 2,
              description: '',
              heading: 'Partner Company Performance',
              icon: <img src={graphIcon} />,
            }}
            isActive={activePartnerCard === 2}
          />
        </div>

        {/* partner projects table */}

        {activePartnerCard === 2 ? (
          <div className="p-5">
            <Row>
              <Col className="flex flex-col gap-2" span={24} md={{ span: 6 }}>
                <label className="text-xs font-semibold font-segoe-ui text-tertiary-text">
                  Partner Company
                </label>
                <Select
                  placeholder="Please select partner company"
                  showSearch
                  optionRender={(option) => {
                    return (
                      <span className="text-xs font-segoe-ui text-tertiary-text">
                        {option.label}
                      </span>
                    );
                  }}
                  loading={partnersLoading}
                  className="font-segoe-ui border-2 placeholder:!text-tertiary-placeholder border-[#D0D5DD] !text-xs md:!text-sm input-placeholder selected-label-14 rounded-lg !shadow-none"
                  options={transformedPartnersList}
                  filterOption={(input, option) =>
                    (String(option?.label) ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  value={activePartner}
                  onChange={handleOnPartnerChange}
                />
              </Col>
            </Row>
            {activePartner && (
              <ActivityDownloadTable
                loading={projectsByPartnerLoading}
                columns={columns.slice(1, columns.length - 1).concat({
                  title: headerRenderer('Activity'),
                  dataIndex: 'activity',
                  key: 'activity',
                  width: '14%',
                  render: (text, record, index) =>
                    downloadingId === record?.id ? (
                      <LoadingOutlined spin />
                    ) : (
                      <Tooltip title="Download">
                        <DownloadOutlined
                          className="text-xl text-primary-bg"
                          onClick={() =>
                            handleDownloadSingleActivity(
                              record.projectData,
                              'Partner',
                            )
                          }
                        />
                      </Tooltip>
                    ),
                })}
                dataSource={transformedPartnerProjects}
                heading={
                  transformedPartnersList.find(
                    (partner) => partner.value === activePartner,
                  )?.label
                }
                onClose={() => setActivePartner(null)}
                headingClasses="font-semibold font-segoe-ui text-tertiary-text text-lg"
                containerClasses={'mt-8 px-4'}
                footerComp={
                  projectsByPartner.length
                    ? () => (
                        <Button
                          className="!m-0 float-end !p-0 !font-segoe-ui !text-xs !font-semibold"
                          type="link"
                          icon={<DownloadOutlined />}
                          onClick={() =>
                            handleDownloadActivity(activePartner, 'Partner')
                          }
                          loading={downloadingId}
                        >
                          Download All
                        </Button>
                      )
                    : null
                }
              />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}
