import { CalendarOutlined, CloseOutlined } from '@ant-design/icons';
import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Space,
  Tooltip,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import './FormInput.css';

export default function FormInput({
  name,
  label,
  marginBottom = 'none',
  labelClasess,
  rules,
  validateMsg,
  placeholder = '',
  type = 'text',
  hideColon = false,
  required = false,
  inputClasses,
  prefix = '',
  suffix = '',
  options,
  // defaultStyle = false,
  disabled = false,
  rows = 3,
  multiple = false,
  showTime = false,
  onSearch,
  optionRender,
  removeIcon = <CloseOutlined />,
  selectedRadioId,
  selectInputClasses,
  inputMaxLength,
  placeholderComponent = '',
  tooltipTitle = '',
  popupClassName = '',
  wrapperClasses = '',
  loading = false,
  noStyle = false,
  defaultValue,
  min,
  max,
  suffixIcon,
  notFoundContent,
  defaultActiveFirstOption = true,
  allowClear = false,
  selectplaceholderClasses,
  onKeyDown,
  selectInputSize = 'large',
  classes,
  otpFormatter,
  otpVariant,
  otpMask,
  hideLabel = false,
  showSearch = true,
  autoSize = false,
  checkboxClassess,
  dateFormat,
  spread = false,
  labelEllipsis = false,
  labelEllipsisWithoutTooltip = false,
  tagRender,
  disabledDate,
  onCalendarChange
}) {
  const renderInput = () => {
    const commonClassNames =
      inputClasses ||
      `py-2 px-3.5 font-segoe-ui border-2 placeholder:!text-tertiary-placeholder border-[#D0D5DD] !text-xs md:!text-sm input-placeholder rounded-lg !shadow-none ${disabled ? 'hover:!border-[#D0D5DD] !bg-gray-200 !text-tertiary-text' : 'hover:!border-primary-bg !bg-white'}`;

    switch (type) {
      case 'password':
        return (
          <Input.Password
            title={tooltipTitle}
            disabled={disabled}
            placeholder={placeholder}
            className={commonClassNames}
            prefix={prefix}
            onKeyDown={onKeyDown}
          />
        );
      case 'radio':
        return (
          <Radio.Group disabled={disabled} className={commonClassNames}>
            {options?.map((option) => {
              return (
                <Radio
                  className={
                    selectedRadioId === option.value
                      ? `bg-secondary-filter ${option.classNames}`
                      : option.classNames
                  }
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                >
                  <Space>
                    {option.label}
                    {selectedRadioId === option.value &&
                      option.subOptions?.length && (
                        <Form.Item
                          style={{ margin: 0, padding: 0 }}
                          name={option.subOptionsName}
                        >
                          <Radio.Group
                            disabled={disabled}
                            className="px-1 bg-white border rounded-full border-tertiary-highlight-200 font-open-sans"
                            size="small"
                          >
                            <Space direction="horizontal">
                              {option.subOptions?.map((sub) => {
                                return (
                                  <Radio
                                    key={sub.value}
                                    value={sub.value}
                                    disabled={sub.disabled}
                                    className="font-open-sans"
                                    style={{ margin: 0, padding: 0 }}
                                  >
                                    {sub.label}
                                  </Radio>
                                );
                              })}
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      )}
                  </Space>
                </Radio>
              );
            })}
          </Radio.Group>
        );
      case 'select':
        return (
          <Select
            popupClassName={popupClassName}
            title={tooltipTitle}
            onKeyDown={onKeyDown}
            popupMatchSelectWidth={false}
            className={
              selectInputClasses ||
              `font-segoe-ui border-2 placeholder:!text-tertiary-placeholder border-[#D0D5DD] !text-xs md:!text-sm input-placeholder selected-label-14 rounded-lg !shadow-none ${disabled ? 'hover:border-[#D0D5DD] bg-gray-200 !text-tertiary-placeholder' : 'hover:!border-primary-bg bg-white'}`
            }
            removeIcon={removeIcon}
            showSearch={showSearch}
            loading={loading}
            disabled={disabled}
            onSearch={onSearch}
            defaultActiveFirstOption={defaultActiveFirstOption}
            placeholder={
              <span
                className={
                  selectplaceholderClasses ||
                  'text-sm font-segoe-ui !text-tertiary-placeholder'
                }
              >
                {placeholder}
              </span>
            }
            filterOption={(input, option) =>
              (String(option?.label) ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            defaultValue={defaultValue}
            suffixIcon={suffixIcon}
            notFoundContent={notFoundContent}
            options={options}
            optionRender={optionRender}
            mode={multiple ? 'multiple' : undefined}
            size={selectInputSize}
            variant="borderless"
            tagRender={tagRender}
          />
        );
      case 'checkbox':
        return (
          <Checkbox.Group className={checkboxClassess}>
            {options.map((option) => (
              <Checkbox
                key={option.value}
                className={option.className}
                value={option.value}
              >
                {option.label}
              </Checkbox>
            ))}
          </Checkbox.Group>
        );
      case 'textarea':
        return (
          <TextArea
            onKeyDown={onKeyDown}
            title={tooltipTitle}
            rows={rows}
            placeholder={placeholder}
            disabled={disabled}
            className={commonClassNames}
            style={{ resize: 'none' }}
            autoSize={autoSize}
          />
        );
      case 'date':
        return (
          <DatePicker
            title={tooltipTitle}
            showTime={showTime}
            style={{ width: '100%' }}
            disabled={disabled}
            placeholder={placeholder}
            className={commonClassNames}
            suffixIcon={suffixIcon ? suffixIcon : <CalendarOutlined />}
            format={
              dateFormat
                ? dateFormat
                : showTime
                  ? 'DD/MM/YYYY hh:mm:ss a'
                  : 'DD/MM/YYYY'
            }
            allowClear={allowClear}
            disabledDate={disabledDate}
          />
        );
      case 'date_range':
        return (
          <DatePicker.RangePicker
            title={tooltipTitle}
            showTime={showTime}
            style={{ width: '100%' }}
            disabled={disabled}
            placeholder={placeholder}
            className={commonClassNames}
            suffixIcon={suffixIcon ? suffixIcon : <CalendarOutlined />}
            format={
              dateFormat
                ? dateFormat
                : showTime
                  ? 'DD/MM/YYYY hh:mm:ss a'
                  : 'DD/MM/YYYY'
            }
            allowClear={allowClear}
            disabledDate={disabledDate}
            onCalendarChange={onCalendarChange}
          />
        );
      case 'otp':
        return (
          <Input.OTP
            formatter={otpFormatter}
            disabled={disabled}
            length={max}
            variant={otpVariant}
            mask={otpMask}
            onKeyDown={onKeyDown}
          />
        );
      case 'placeholder':
        return placeholderComponent;
      default:
        return (
          <Input
            onKeyDown={onKeyDown}
            allowClear={allowClear}
            title={tooltipTitle}
            disabled={disabled}
            placeholder={placeholder}
            type={type}
            className={commonClassNames}
            prefix={prefix}
            suffix={suffix}
            maxLength={inputMaxLength}
            defaultValue={defaultValue}
            max={max}
            min={min}
          />
        );
    }
  };

  return (
    <Form.Item
      label={
        label ? (
          <label
            className={
              labelClasess
                ? labelClasess
                : labelEllipsis || labelEllipsisWithoutTooltip
                  ? 'text-xs lg:text-sm text-tertiary-text font-segoe-ui text-ellipsis overflow-hidden whitespace-nowrap'
                  : `${hideLabel ? 'invisible' : 'text-xs lg:text-sm text-tertiary-text font-segoe-ui'}`
            }
          >
            {labelEllipsis ? (
              <Tooltip title={label}>{label}</Tooltip>
            ) : labelEllipsisWithoutTooltip ? (
              label
            ) : (
              label
            )}
          </label>
        ) : (
          ''
        )
      }
      colon={false}
      name={name}
      rules={
        rules?.length ? rules : [{ required: required, message: validateMsg }]
      }
      className={
        hideColon
          ? `hide-required-mark ${classes} border-none ${marginBottom === 'small' ? 'mb-1' : marginBottom === 'medium' ? 'mb-2' : marginBottom === 'large' ? 'mb-3' : marginBottom === 'extraLarge' ? 'mb-4' : marginBottom === 'none' ? 'mb-0' : 'mb-0'}`
          : classes
            ? classes
            : spread
              ? `border-none flex-1 ${marginBottom === 'small' ? 'mb-1' : marginBottom === 'medium' ? 'mb-2' : marginBottom === 'large' ? 'mb-3' : marginBottom === 'extraLarge' ? 'mb-4' : marginBottom === 'none' ? 'mb-0' : 'mb-0'}`
              : `border-none ${marginBottom === 'small' ? 'mb-1' : marginBottom === 'medium' ? 'mb-2' : marginBottom === 'large' ? 'mb-3' : marginBottom === 'extraLarge' ? 'mb-4' : marginBottom === 'none' ? 'mb-0' : 'mb-0'}`
      }
      noStyle={noStyle}
    >
      {wrapperClasses ? (
        <Form.Item name={name} className={wrapperClasses}>
          {renderInput()}
        </Form.Item>
      ) : (
        renderInput()
      )}
    </Form.Item>
  );
}
