import Card from '@common/Card';
import FormInput from '@common/FormInput';
import { Button, Col, Descriptions, Flex, Form, Row } from 'antd';
import PartnerProjDetails from './components/PartnerProjDetails';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectsByDateRange, selectPartnerScoreSlice } from './redux/slice';
import {
  getProjectDetails,
  selectProjectSlice,
} from '@features/project/redux/slice';
import moment from 'moment';
import {
  ACCEPTANCE_STATUS,
  getFormattedDateToSend,
  PROJECT_STATUS,
} from '@utils/helpers';

const initStructuredData = {
  partnerDetails: null,
  partnerTypeDetails: null,
  projectDetails: null,
  overall_rating: '',
};

export default function PartnerScore() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [transformedProjectPartners, setTransformedProjectPartners] = useState(
    [],
  );
  const [transformedPartnerTypes, setTransformedPartnersTypes] = useState([]);
  const [structuredData, setStructuredData] = useState(initStructuredData);
  const { projectsByDateRange, projectsByDateRangeLoading } = useSelector(
    selectPartnerScoreSlice,
  );
  const [overallRating, setOverallRating] = useState(null);
  const { projectDetailsLoading, projectDetailsData } =
    useSelector(selectProjectSlice);

  //tranforming projects
  const transformedProjects = projectsByDateRange
    ?.filter(
      (pro) =>
        pro.status === PROJECT_STATUS.completed ||
        pro.status === PROJECT_STATUS.completedDelayed,
    )
    ?.map((project) => {
      return { label: project.project_name, value: project.id };
    });

  //handle fetch project score
  const handleOnSubmit = (values) => {};

  //handling form value change
  const handleFormValuesChange = (changedVal, values) => {
    if (changedVal.date_range) {
      const payload = {
        startDate: getFormattedDateToSend(values.date_range[0]),
        endDate: getFormattedDateToSend(values.date_range[1]),
      };
      dispatch(getProjectsByDateRange(payload));
    }

    if (changedVal.project) {
      form.resetFields(['partner', 'partner_type']);
      dispatch(getProjectDetails(values.project)).then((res) => {
        setStructuredData(initStructuredData);
        if (res?.payload?.success) {
          const data = res?.payload?.data?.Projects_Partners_Mappings.filter(
            (part) =>
              part.activities.every(
                (p) => p.acceptance_status !== ACCEPTANCE_STATUS.shortClose,
              ),
          );
          const transformedPartners = Array.from(
            new Map(data?.map((item) => [item.partner_id, item])).values(),
          ).map((partner) => {
            return {
              label: partner.partner.partner_name,
              value: partner.id,
              partnerType: partner.PartnerType,
              partnerActivities: partner.activities,
            };
          });
          setTransformedProjectPartners(transformedPartners);
          setTransformedPartnersTypes([]);
        }
      });
    }

    if (changedVal.partner) {
      const foundPartnerId =
        projectDetailsData?.Projects_Partners_Mappings?.find(
          (pa) => pa.id === values.partner,
        )?.partner_id;

      const filteredList =
        projectDetailsData?.Projects_Partners_Mappings?.filter(
          (rec) => rec.partner_id === foundPartnerId && rec.is_active,
        ).filter((part) =>
          part.activities.every(
            (p) => p.acceptance_status !== ACCEPTANCE_STATUS.shortClose,
          ),
        );

      const tranformedPartnerTypes = filteredList.map((type) => {
        return {
          label: `${type.PartnerType.type} (${type.contact.contact_name})`,
          value: type.id,
        };
      });

      setTransformedPartnersTypes(tranformedPartnerTypes);
      form.resetFields(['partner_type']);
      const partnerDetails =
        projectDetailsData?.Projects_Partners_Mappings?.find(
          (pa) => pa.id === values.partner,
        );
      setOverallRating(null);
      setStructuredData({
        ...structuredData,
        partnerDetails,
        overall_rating: null,
      });
    }

    if (changedVal.partner_type) {
      const partnerDetails =
        projectDetailsData?.Projects_Partners_Mappings.find(
          (partner) => partner.id === values.partner_type,
        );

      setOverallRating(partnerDetails?.rating);
      setStructuredData({
        ...structuredData,
        partnerTypeDetails: partnerDetails.PartnerType,
        partnerDetails,
        projectDetails: projectDetailsData,
        overall_rating: partnerDetails?.rating,
      });
    }
  };

  //disabling future dates
  const disabledDates = (current) => {
    return current && current > moment().startOf('day');
  };

  return (
    <Form layout="vertical" form={form} onValuesChange={handleFormValuesChange}>
      <Card
        loading={projectDetailsLoading || projectsByDateRangeLoading}
        header={
          <>
            <h3 className="text-xl font-semibold font-segoe-ui text-tertiary-placeholder">
              Partner Score
            </h3>
            <FormInput
              name={'date_range'}
              type="date_range"
              placeholder={['Start date', 'End date']}
              // disabledDate={disabledDates}
            />
          </>
        }
        headerClasses={'flex justify-between items-center'}
        className={
          'w-full rounded-lg !bg-secondary-bg px-6 py-5 mt-8 font-segoe-ui shadow-equal-sides'
        }
      >
        <Flex vertical gap={'2.25rem'}>
          {/* form */}
          <Row gutter={16} className="mt-4">
            <Col span={24} md={{ span: 8 }}>
              <FormInput
                labelClasess={
                  'text-xs text-tertiary-text font-segoe-ui font-semibold'
                }
                name={'project'}
                label={'Project'}
                placeholder="Please select project"
                type="select"
                selectInputClasses={
                  'font-segoe-ui border-2 placeholder:!text-tertiary-placeholder border-[#D0D5DD] !text-xs md:!text-sm input-placeholder selected-label-12 rounded-lg !shadow-none'
                }
                options={transformedProjects}
                optionRender={(option) => {
                  return (
                    <span className="text-xs font-segoe-ui text-tertiary-text">
                      {option.label}
                    </span>
                  );
                }}
              />
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <FormInput
                labelClasess={
                  'text-xs text-tertiary-text font-segoe-ui font-semibold'
                }
                name={'partner'}
                label={'Partner'}
                placeholder="Please select partner"
                type="select"
                selectInputClasses={
                  'font-segoe-ui border-2 placeholder:!text-tertiary-placeholder border-[#D0D5DD] !text-xs md:!text-sm input-placeholder selected-label-12 rounded-lg !shadow-none'
                }
                options={transformedProjectPartners}
                optionRender={(option) => {
                  return (
                    <span className="text-xs font-segoe-ui text-tertiary-text">
                      {option.label}
                    </span>
                  );
                }}
              />
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <FormInput
                labelClasess={
                  'text-xs text-tertiary-text font-segoe-ui font-semibold'
                }
                name={'partner_type'}
                label={'Partner Type'}
                placeholder="Please select partner type"
                type="select"
                options={transformedPartnerTypes}
                selectInputClasses={
                  'font-segoe-ui border-2 placeholder:!text-tertiary-placeholder border-[#D0D5DD] !text-xs md:!text-sm input-placeholder selected-label-12 rounded-lg !shadow-none'
                }
                optionRender={(option) => {
                  return (
                    <span className="text-xs font-segoe-ui text-tertiary-text">
                      {option.label}
                    </span>
                  );
                }}
              />
            </Col>
          </Row>

          {/* details */}
          {structuredData.partnerTypeDetails && (
            <>
              <PartnerProjDetails
                data={structuredData}
                overallRating={overallRating}
                setOverallRating={setOverallRating}
              />

              {/* save */}
              {/* <Button
                onClick={() => form.submit()}
                className="!p-4.5 font-medium bg-primary-bg w-fit text-secondary-bg hover:border hover:border-primary-bg hover:!bg-transparent hover:!text-primary-bg text-md font-segoe-ui"
              >
                Save
              </Button> */}
            </>
          )}
        </Flex>
      </Card>
    </Form>
  );
}
