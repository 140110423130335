export default function PartnerCard({ data, onClick, isActive }) {
  return (
    <div
      onClick={onClick}
      className={`${data.index === 0 && 'md:rounded-s-lg'} ${data.index === 1 && 'md:rounded-e-lg'} border  rounded-lg md:rounded-none shadow-equal-sides relative hover:shadow-equal-sides-dark select-none cursor-pointer ${isActive ? 'bg-secondary-bg border-b-4 border-b-primary-bg' : 'border-[#E8E9EB] bg-[#F9FAFC]'}`}
    >
      <div className="flex flex-col gap-1 px-3 py-4 xl:px-6 xl:py-5 font-segoe-ui">
        <h4 className="text-base font-semibold text-tertiary-placeholder">
          {data.heading}
        </h4>
        <p className="text-sm text-[#798F9A]">{data.description}</p>
      </div>
      <div className="absolute xl:top-5 top-4 right-3 xl:right-6">
        {data.icon}
      </div>
    </div>
  );
}
