import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from 'react-router-dom';
import { getLoggedInUser } from './utilities/helpers';
import Login from './features/login/pages/Login';
import Otp from './features/login/pages/Otp';
import Home from './features/home';
import Dashboard from './features/dashboard';
import Partner from './features/partner';
import CreatePartner from 'features/partner/pages/CreatePartner';
import CreateProject from 'features/project/pages/CreateProject';
import ProjectDetails from '@features/project/pages/ProjectDetails';
import CreateActivity from '@features/activity/pages/CreateActivity';
import PartnerScore from '@features/partner_score';
import { ADMIN, GRANUAL_USER, PARTNER, PARTNER_USER } from '@utils/roleRights';
import PartnersList from '@features/partner/pages/PartnersList';

const RoleBasedDashboard = () => {
  const { role } = getLoggedInUser();
};

const ROUTES = [
  // dashboard routes
  {
    index: true,
    element: <Dashboard />,
    allowedRoles: [GRANUAL_USER, PARTNER_USER, ADMIN, PARTNER],
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    allowedRoles: [GRANUAL_USER, PARTNER_USER, ADMIN, PARTNER],
  },
  {
    path: '/dashboard/project/:projectId',
    element: <ProjectDetails />,
    allowedRoles: [GRANUAL_USER, PARTNER_USER, PARTNER],
  },

  {
    path: '/dashboard/activity',
    element: <CreateActivity />,
    allowedRoles: [GRANUAL_USER, PARTNER_USER, PARTNER],
  },

  // partner routes
  {
    path: '/partner',
    element: <Partner />,
    allowedRoles: [GRANUAL_USER],
  },
  {
    path: '/create/partner',
    element: <CreatePartner />,
    allowedRoles: [GRANUAL_USER],
  },
  {
    path: '/create/partner/:id',
    element: <CreatePartner />,
    allowedRoles: [GRANUAL_USER],
  },
  {
    path: '/partners',
    element: <PartnersList />,
    allowedRoles: [GRANUAL_USER],
  },

  // project routes
  {
    path: '/project',
    element: <CreateProject />,
    allowedRoles: [GRANUAL_USER],
  },
  {
    path: '/project/:projectId',
    element: <CreateProject />,
    allowedRoles: [GRANUAL_USER],
  },

  // partner score
  {
    path: '/dashboard/partner_score',
    element: <PartnerScore />,
    allowedRoles: [GRANUAL_USER],
  },
];

const PrivateRoute = ({ children, allowedRoles }) => {
  // Now inside a component that is rendered by a <Route>
  const location = useLocation();

  //const { loading } = useSelector((state: State) => state.login);
  const { loggedIn, role } = getLoggedInUser();

  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (allowedRoles && !allowedRoles.includes(role)) {
    return <Navigate to="/" state={{ replace: true }} />;
  }

  return <>{children}</>;
};

const AuthChecker = ({ children }) => {
  // Now inside a component that is rendered by a <Route>
  const location = useLocation();
  const { loggedIn } = getLoggedInUser();
  const goTo = location.state?.from?.pathname
    ? location.state?.from?.pathname
    : // : roleBasedNavigation(user?.user_details?.role || '');
      '';

  if (loggedIn) {
    return <Navigate to={goTo} state={{ from: location }} />;
  }

  return <>{children}</>;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RootRedirect />} />
        <Route
          path="login"
          element={
            <AuthChecker>
              <Login />
            </AuthChecker>
          }
        />
        <Route
          path="otp"
          element={
            <AuthChecker>
              <Otp />
            </AuthChecker>
          }
        />

        <Route
          path="/"
          element={
            <PrivateRoute
              allowedRoles={[GRANUAL_USER, PARTNER_USER, ADMIN, PARTNER]}
            >
              <Home />
            </PrivateRoute>
          }
        >
          {ROUTES.map((route, i) => {
            return (
              <Route
                key={i}
                index={Boolean(route.index)}
                path={route.path ? route.path : ''}
                element={
                  <PrivateRoute allowedRoles={route.allowedRoles}>
                    {route.element}
                  </PrivateRoute>
                }
              />
            );
          })}
        </Route>
      </Routes>
    </Router>
  );
};

// Helper Component for Redirecting to the appropriate path
const RootRedirect = () => {
  const location = useLocation();

  const goTo = location.state?.from?.pathname
    ? location.state?.from?.pathname
    : '/login';

  return <Navigate replace to={goTo} />;
};

export default App;
