import { CloseOutlined } from '@ant-design/icons';
import Table from '@common/Table';
import { useResponsiveness } from '@hooks/useResponsiveness';
import { Tooltip } from 'antd';

export default function ActivityDownloadTable({
  columns,
  loading,
  dataSource,
  onClose,
  heading,
  headingClasses,
  containerClasses,
  footerComp,
}) {
  const { lg, xl, xxl } = useResponsiveness();
  return (
    <div className={containerClasses}>
      <div className="flex items-center justify-between">
        <h5 className={headingClasses}>{heading}</h5>
        {onClose && (
          <Tooltip title="Close">
            <CloseOutlined
              onClick={onClose}
              className="text-lg hover:opacity-50"
            />
          </Tooltip>
        )}
      </div>
      <div className="mt-2">
        <Table
          renderFooter={footerComp}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          hideScroll={lg || xl || xxl ? true : false}
          rowClassName={(record, index) => `hover:!bg-primary-screen`}
          components={{
            body: {
              cell: (props) => (
                <td {...props} className="!px-3 !py-2 !border-gray-200" />
              ),
            },
            header: {
              cell: (props) => (
                <th
                  {...props}
                  className="!bg-secondary-bg !py-2 !px-3 !border-gray-200"
                >
                  {props.children}
                </th>
              ),
            },
          }}
        />
      </div>
    </div>
  );
}
