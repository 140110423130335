import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  partnersProjectList,
  partnerUsersProjectList,
  projectList,
} from './api';
import moment from 'moment';

const actions = {
  PROJECT_LIST: 'dashboard/PROJECT_LIST',
  PARTNERS_PROJECT_LIST: 'dashboard/PARTNERS_PROJECT_LIST',
  PARTNER_USERS_PROJECT_LIST: 'dashboard/PARTNER_USERS_PROJECT_LIST',
};

export const getProjectList = createAsyncThunk(
  actions.PROJECT_LIST,
  async (payload) => {
    const response = await projectList(payload);
    return response;
  },
);

export const getPartnersProjectList = createAsyncThunk(
  actions.PARTNERS_PROJECT_LIST,
  async (payload) => {
    const response = await partnersProjectList(payload);
    return response;
  },
);

export const getPartnerUsersProjectList = createAsyncThunk(
  actions.PARTNER_USERS_PROJECT_LIST,
  async (payload) => {
    const response = await partnerUsersProjectList(payload);
    return response;
  },
);

const initialState = {
  projectListLoading: false,
  projectList: [],
  pagination: {},
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // request otp
    builder
      .addCase(getProjectList.pending, (state) => {
        state.projectListLoading = true;
      })
      .addCase(getProjectList.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.projectListLoading = false;

        if (success) {
          state.projectList = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })
      .addCase(getProjectList.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.projectListLoading = false;
        message.error(errorMsg);
      });

    // get partners project list
    builder
      .addCase(getPartnersProjectList.pending, (state) => {
        state.projectListLoading = true;
      })
      .addCase(getPartnersProjectList.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.projectListLoading = false;

        if (success) {
          const tranformedProjectList = data?.map((item) => {
            return {
              id: item?.Project.id,
              project_name: item?.Project.project_name,
              project_id: item?.Project.project_code,
              partner_name: item.partner.partner_name,
              partner_type: item.PartnerType.type,
              partner_code: item.partner.partner_code,
              start_date: item.Project?.project_start_date
                ? moment(item.Project?.project_start_date).format('DD MMM YY')
                : null,
              end_date: item.Project?.project_end_date
                ? moment(item.Project?.project_end_date).format('DD MMM YY')
                : null,
              actual_end_date: item.Project?.project_actual_end_date
                ? moment(item.Project?.project_actual_end_date).format(
                    'DD MMM YY',
                  )
                : null,
              status: item.Project?.status,
            };
          });

          const groupedProjectList = tranformedProjectList.reduce(
            (acc, project) => {
              if (!acc[project.id]) {
                acc[project.id] = [];
              }
              acc[project.id].push(project);
              return acc;
            },
            {},
          );

          const finalTranformedList = Object.entries(groupedProjectList).map(
            ([id, projects]) => {
              let updatedProject = null;

              const partnerTypes = [];
              const partnerNames = [];
              const partnerCodes = [];

              for (const project of projects) {
                partnerTypes.push(project.partner_type);
                partnerNames.push(project.partner_name);
                partnerCodes.push(project.partner_code);

                updatedProject = {
                  id: project?.id,
                  project_name: project?.project_name,
                  project_id: project?.project_id,
                  start_date: project.start_date
                    ? moment(project.start_date).format('DD MMM YY')
                    : '-',
                  end_date: project.end_date
                    ? moment(project.end_date).format('DD MMM YY')
                    : '-',
                  actual_end_date: project.actual_end_date
                    ? moment(project.actual_end_date).format('DD MMM YY')
                    : '-',
                  status: project.status,
                };
              }

              return {
                ...updatedProject,
                partner_type: [...new Set(partnerTypes)].join(', '),
                partner_name: [...new Set(partnerNames)].join(', '),
                partner_code: [...new Set(partnerCodes)].join(', '),
              };
            },
          );

          state.projectList = finalTranformedList;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })
      .addCase(getPartnersProjectList.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.projectListLoading = false;
        message.error(errorMsg);
      });

    // get partner users project list
    builder
      .addCase(getPartnerUsersProjectList.pending, (state) => {
        state.projectListLoading = true;
      })
      .addCase(getPartnerUsersProjectList.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.projectListLoading = false;

        if (success) {
          const tranformedProjectList = data?.map((item) => {
            return {
              id: item?.Project.id,
              project_name: item?.Project.project_name,
              project_id: item?.Project.project_code,
              partner_name: item.partner.partner_name,
              partner_type: item.PartnerType.type,
              partner_code: item.partner.partner_code,
              start_date: item.Project?.project_start_date
                ? moment(item.Project?.project_start_date).format('DD MMM YY')
                : null,
              end_date: item.Project?.project_end_date
                ? moment(item.Project?.project_end_date).format('DD MMM YY')
                : null,
              actual_end_date: item.Project?.project_actual_end_date
                ? moment(item.Project?.project_actual_end_date).format(
                    'DD MMM YY',
                  )
                : null,
              status: item.Project?.status,
            };
          });

          const groupedProjectList = tranformedProjectList.reduce(
            (acc, project) => {
              if (!acc[project.id]) {
                acc[project.id] = [];
              }
              acc[project.id].push(project);
              return acc;
            },
            {},
          );

          const finalTranformedList = Object.entries(groupedProjectList).map(
            ([id, projects]) => {
              let updatedProject = null;

              const partnerTypes = [];
              const partnerNames = [];
              const partnerCodes = [];

              for (const project of projects) {
                partnerTypes.push(project.partner_type);
                partnerNames.push(project.partner_name);
                partnerCodes.push(project.partner_code);

                updatedProject = {
                  id: project?.id,
                  project_name: project?.project_name,
                  project_id: project?.project_id,
                  start_date: project.start_date
                    ? moment(project.start_date).format('DD MMM YY')
                    : '-',
                  end_date: project.end_date
                    ? moment(project.end_date).format('DD MMM YY')
                    : '-',
                  actual_end_date: project.actual_end_date
                    ? moment(project.actual_end_date).format('DD MMM YY')
                    : '-',
                  status: project.status,
                };
              }

              return {
                ...updatedProject,
                partner_type: [...new Set(partnerTypes)].join(', '),
                partner_name: [...new Set(partnerNames)].join(', '),
                partner_code: [...new Set(partnerCodes)].join(', '),
              };
            },
          );

          state.projectList = finalTranformedList;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })
      .addCase(getPartnerUsersProjectList.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.projectListLoading = false;
        message.error(errorMsg);
      });
  },
});

export const selectDashboardSlice = (state) => {
  return state.dashboard;
};

export default dashboardSlice.reducer;
