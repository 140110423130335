import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Tooltip } from 'antd';
import { useState } from 'react';

export default function IconButton({
  src,
  srcActive,
  className,
  tooltipName,
  onClick,
  loading = false,
  disabled = false,
}) {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <Tooltip title={disabled ? '' : tooltipName}>
      <Spin
        spinning={loading}
        size="small"
        indicator={<LoadingOutlined spin />}
      >
        <img
          onClick={(e) => (!disabled && onClick ? onClick(e) : null)}
          className={`${className} cursor-pointer ${disabled ? 'invisible' : ''}`}
          src={isHovering ? srcActive : src}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        />
      </Spin>
    </Tooltip>
  );
}
