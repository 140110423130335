import { Tooltip } from 'antd';
import LocalStorage from './localStorage';
import moment from 'moment';

// activity completion statuses
export const STATUS = {
  completed: 'Completed',
  inProgress: 'In Progress',
};

//project statuses
export const PROJECT_STATUS = {
  completed: 'completed',
  ongoing: 'ongoing',
  delayed: 'delayed',
  completedDelayed: 'completed-delayed',
};

// activity acceptance statuses
export const ACCEPTANCE_STATUS = {
  accept: 'accept',
  stopActivty: 'stop_activiti',
  shortClose: 'short_close',
};

// table per page item nums
export const PER_PAGE_ITEM = 15;

export const TabTitle = (newTitle) => {
  return (document.title = newTitle);
};

export const getLoggedInUser = () => {
  const loginUser = LocalStorage.getItem('granules_Alliance') || '';
  const user = loginUser || { user: null };
  return {
    ...user,
    user: user.user_details,
    role: user.user_details?.role?.role_name,
    loggedIn: loginUser !== '',
  };
};

// column renderer for table
export const columnRenderer = (
  text,
  hasTooltip,
  showIndicator = false,
  classes,
  nullvalue = '-',
) => {
  return hasTooltip ? (
    <>
      <Tooltip title={text}>
        <p
          className={
            classes
              ? `${classes} overflow-hidden font-segoe-ui text-ellipsis whitespace-nowrap`
              : 'overflow-hidden text-sm text-primary-column font-segoe-ui text-ellipsis whitespace-nowrap'
          }
        >
          {text || nullvalue}
        </p>
      </Tooltip>
      {showIndicator && (
        <div className="absolute top-0 left-0 h-full border-l-[4px] border-primary-green"></div>
      )}
    </>
  ) : (
    <>
      <p
        className={
          classes
            ? `${classes} font-segoe-ui`
            : 'text-sm text-primary-column font-segoe-ui'
        }
      >
        {text || nullvalue}
      </p>
      {showIndicator && (
        <div className="absolute top-0 left-0 h-full border-l-[4px] border-primary-green"></div>
      )}
    </>
  );
};

// header renderer for table
export const headerRenderer = (
  text,
  hasTooltip,
  ellipsis = false,
  nullvalue = '-',
  classes,
) => {
  return hasTooltip && ellipsis ? (
    <Tooltip title={text}>
      <label className="overflow-hidden text-[14px] font-bold text-ellipsis whitespace-nowrap font-segoe-ui text-tertiary-placeholder">
        {text || nullvalue}
      </label>
    </Tooltip>
  ) : hasTooltip ? (
    <Tooltip title={text}>
      <label className="text-[14px] font-bold font-segoe-ui text-tertiary-placeholder">
        {text || nullvalue}
      </label>
    </Tooltip>
  ) : ellipsis ? (
    <label className="overflow-hidden text-[14px] font-bold text-ellipsis whitespace-nowrap font-segoe-ui text-tertiary-placeholder">
      {text || nullvalue}
    </label>
  ) : (
    <label
      className={
        classes ||
        'text-[14px] font-bold font-segoe-ui text-tertiary-placeholder'
      }
    >
      {text || nullvalue}
    </label>
  );
};

//formatted date for table cell
export const getFormattedDate = (date) => {
  return date ? moment(date).format('DD-MMM-YYYY') : null;
};

//formatted date for sending date
export const getFormattedDateToSend = (date) => {
  return date ? moment(new Date(date)).format('YYYY-MM-DD') : null;
};

//get dates difference in months
export const getDateDifference = (d1, d2) => {
  const date1 = moment(d1['$d'] || d1['_d']);
  const date2 = moment(d2['$d'] || d2['_d']);
  const totalDays = date1.diff(date2, 'days');

  const totalMonths = Math.floor(totalDays / 30);
  const remainingDays = totalDays % 30;
  if (remainingDays === 0) {
    return totalMonths;
  }
  return totalMonths + '.' + remainingDays;
};

//acceptance status label
export const getAcceptanceStatusLabel = (status) => {
  switch (status) {
    case ACCEPTANCE_STATUS.accept:
      return 'Accepted';
    case ACCEPTANCE_STATUS.stopActivty:
    case ACCEPTANCE_STATUS.shortClose:
      return 'Activity Stopped';
    default:
      return '-';
  }
};

//acceptance status classes
export const getAcceptanceStatusClasses = (status) => {
  switch (status) {
    case ACCEPTANCE_STATUS.accept:
      return 'bg-[#ECFDF3] rounded-md w-fit px-3 py-0.5 font-semibold text-primary-green text-2xs';
    case ACCEPTANCE_STATUS.stopActivty:
    case ACCEPTANCE_STATUS.shortClose:
      return 'bg-[#FFE4E4] rounded-md w-fit px-3 py-0.5 font-semibold text-primary-red text-2xs';
    default:
      return 'rounded-md w-fit px-3 font-semibold text-tertiary-placeholder text-2xs';
  }
};

//activity status classes
export const getActivityStatusClasses = (status) => {
  switch (status) {
    case STATUS.completed:
      return 'bg-[#ECFDF3] rounded-md w-fit px-3 py-0.5 font-semibold text-primary-green text-2xs';
    case STATUS.inProgress:
      return 'bg-[#EBEBEB] rounded-md w-fit px-3 py-0.5 font-semibold text-tertiary-placeholder text-2xs';
    case ACCEPTANCE_STATUS.shortClose:
      return 'bg-[#FFE4E4] rounded-md w-fit px-3 py-0.5 font-semibold text-primary-red text-2xs';
    default:
      return 'rounded-md w-fit px-3 font-semibold text-tertiary-placeholder text-2xs';
  }
};

//get status label and classes
export const getStatus = (status) => {
  switch (status) {
    case STATUS.completed:
      return (
        <>
          <div className="p-1 rounded-full bg-primary-green"></div>
          <label>Completed</label>
        </>
      );
    case STATUS.inProgress:
      return (
        <>
          <div className="p-1 rounded-full bg-tertiary-placeholder"></div>
          <label>In Progress</label>
        </>
      );
    default:
      return 'NA';
  }
};

//get project status and classes
export const getProjectStatus = (status) => {
  switch (status) {
    case PROJECT_STATUS.completed:
      return (
        <>
          <div className="p-1 rounded-full bg-[#0092DD]"></div>
          <label>Completed</label>
        </>
      );
    case PROJECT_STATUS.ongoing:
      return (
        <>
          <div className="p-1 rounded-full bg-[#78D1FF]"></div>
          <label>Ongoing</label>
        </>
      );
    case PROJECT_STATUS.delayed:
      return (
        <>
          <div className="p-1 rounded-full bg-[#F43E3E]"></div>
          <label>Delayed</label>
        </>
      );
    case PROJECT_STATUS.completedDelayed:
      return (
        <>
          <div className="p-1 rounded-full bg-[#FF8686]"></div>
          <label>Completed-Delayed</label>
        </>
      );
    default:
      return '-';
  }
};

//check is all activities are completed and accepted
export const checkActivityAcceptanceStatus = (activities) => {
  return activities
    .filter(
      (act) =>
        act.acceptance_status !== ACCEPTANCE_STATUS.stopActivty &&
        act.acceptance_status !== ACCEPTANCE_STATUS.shortClose,
    )
    .every(
      (act) =>
        act.acceptance_status === ACCEPTANCE_STATUS.accept &&
        act.completion_status === STATUS.completed,
    );
};

//gte highest Actual End Date
export const getHighestActualEndDate = (activities) => {
  const filteredActivites = activities.filter(
    (act) =>
      (act.actual_completion_date || act.base_end_date) &&
      act.acceptance_status !== ACCEPTANCE_STATUS.shortClose &&
      act.acceptance_status !== ACCEPTANCE_STATUS.stopActivty,
  );

  if (!filteredActivites.length) {
    return moment.invalid();
  }

  const todaysDate = getFormattedDateToSend(moment());
  return moment(
    getFormattedDateToSend(
      [...filteredActivites, { actual_completion_date: todaysDate }]
        ?.map((activity) =>
          activity.actual_completion_date
            ? moment(activity.actual_completion_date)
            : moment(activity.base_end_date),
        )
        ?.reduce((maxDate, currentDate) =>
          currentDate.isAfter(maxDate) ? currentDate : maxDate,
        ),
    ),
  );
};

export const getTotalMonths = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);

  const yearsDifference = end.year() - start.year();
  const monthsDifference = end.month() - start.month() + 1;

  return yearsDifference * 12 + monthsDifference;
};

export const PHONE_NUMBER_REGEX = /^\d{10,12}$/;

export const PER_PAGE_ITEMS = [
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 25, value: 25 },
];
