import Card from '@common/Card';
import Table from '@common/Table';
import {
  ACCEPTANCE_STATUS,
  columnRenderer,
  getActivityStatusClasses,
  getFormattedDate,
  getLoggedInUser,
  headerRenderer,
  PROJECT_STATUS,
  STATUS,
} from '@utils/helpers';
import { Button, message, Space, Spin, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getProjectDetails,
  selectProjectSlice,
  uploadBulkActivity,
} from '../redux/slice';
import plusNumIcon from '@assets/icons/input/plus_with_num.svg';
import plusNumActiveIcon from '@assets/icons/input/plus_with_num_active.svg';
import userIcon from '@assets/icons/input/user.svg';
import userActiveIcon from '@assets/icons/input/user_active.svg';
import downloadIcon from '@assets/icons/input/download.svg';
import downloadActiveIcon from '@assets/icons/input/download_active.svg';
import uploadIcon from '@assets/icons/input/upload.svg';
import uploadActiveIcon from '@assets/icons/input/upload_active.svg';
import IconButton from '@common/IconButton';
import { useResponsiveness } from '@hooks/useResponsiveness';
import expandIcon from '@assets/icons/input/arrow_down.svg';
import expandDisabledIcon from '@assets/icons/input/arrow_down_disable.svg';
import shrinkIcon from '@assets/icons/input/arrow_up.svg';
import ActivityList from '../components/ActivityList';
import { apiUrl, getLocalToken } from '@utils/apiClient';
import Upload from '@common/Upload';
import {
  GRANUAL_USER,
  PARTNER,
  PARTNER_USER,
  PERMISSIONS,
} from '@utils/roleRights';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';

export default function ProjectDetails() {
  const { xs, sm, md, lg, xl, xxl } = useResponsiveness();
  const { role, user } = getLoggedInUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const {
    projectDetailsLoading,
    projectDetailsData,
    uploadBulkActivityLoading,
  } = useSelector(selectProjectSlice);
  const [downloadingId, setDownloadingId] = useState(null);
  const [cuurentPartnerUserType, setCuurentPartnerUserType] = useState(null);
  const [currentPartnerUserPartnerId, setCurrentPartnerUserPartnerId] =
    useState(null);
  const [currentPartnerUserMappingId, setCurrentPartnerUserMappingId] =
    useState(null);
  const [file, setFile] = useState([]);

  const [expandedPartner, setExpandedPartner] = useState(null);
  const [uploadId, setUploadId] = useState(null);

  //handle click on expand icon
  const handleExpandClick = (partner) => {
    setExpandedPartner((prev) => (prev?.id === partner.id ? null : partner));
    dispatch(getProjectDetails(projectId));
  };

  //download activity bulk upload excel file
  const handleDownloadActivityForm = async (record) => {
    setDownloadingId(record.id);
    try {
      const params = new URLSearchParams({
        id: String(projectId),
        partner_id: record.partner?.partner_id,
      });
      const response = await fetch(
        `${apiUrl}/activity/export-activity?${params}`,
        {
          headers: {
            Authorization: getLocalToken(),
          },
        },
      );
      if (!response.ok) {
        throw new Error('Failed to download activity form...');
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', 'activity-form.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setDownloadingId(null);
      message.error(error.message || 'Failed to download activity form...');
    } finally {
      setDownloadingId(null);
    }
  };

  const columns = [
    {
      title: headerRenderer('Partner Name'),
      dataIndex: 'partner_name',
      key: 'partner_name',
      ellipsis: true,
      width: '10%',
      hidden: role === PARTNER_USER,
      render: (text, record, index) => {
        const isExpanded = expandedPartner?.id === record.id;
        const hasNoActivities = record?.partner?.activities?.length === 0;

        return (
          <div className="flex items-center justify-between w-full gap-2 font-segoe-ui">
            <Tooltip title={text}>
              <div className="flex items-center w-3/5 gap-2 cursor-pointer hover:text-primary-bg">
                <p className="overflow-hidden text-sm text-primary-column font-segoe-ui text-ellipsis whitespace-nowrap">
                  {text || 'NA'}
                </p>
                <span className="flex items-center px-1 rounded-sm text-2xs text-secondary-bg font-segoe-ui bg-primary-placeholder aspect-square">
                  {record?.partner?.activities?.length || 0}
                </span>
              </div>
            </Tooltip>

            <button
              className="flex items-center w-2/5 gap-1"
              onClick={() => handleExpandClick(record)}
              disabled={hasNoActivities}
            >
              <span
                className={`font-semibold ${hasNoActivities ? 'text-primary-placeholder' : 'text-primary-bg'}`}
              >
                {isExpanded ? 'Shrink' : 'Expand'}
              </span>
              <img
                src={
                  hasNoActivities
                    ? expandDisabledIcon
                    : isExpanded
                      ? shrinkIcon
                      : expandIcon
                }
              />
            </button>
          </div>
        );
      },
    },
    {
      title: headerRenderer('Partner Type', true, true),
      dataIndex: 'partner_type',
      key: 'partner_type',
      ellipsis: true,
      width: '8%',
      render: (text, record, index) => {
        if (role === PARTNER_USER) {
          const isExpanded = expandedPartner?.id === record.id;
          const hasNoActivities = record?.partner?.activities?.length === 0;
          return (
            <div className="flex items-center justify-between w-full gap-2 font-segoe-ui">
              <div className="flex items-center w-3/5 gap-2">
                <p className="overflow-hidden text-primary-column text-sm font-segoe-ui text-ellipsis whitespace-nowrap">
                  {text || 'NA'}
                </p>
                <span className="flex items-center px-1 rounded-sm text-2xs text-secondary-bg font-segoe-ui bg-primary-placeholder aspect-square">
                  {record.partner.activities.filter(
                    (rec) => rec.partner.id === currentPartnerUserPartnerId,
                  )?.length || 0}
                </span>
              </div>
              <button
                className="flex items-center w-2/5 gap-1"
                onClick={() => handleExpandClick(record)}
                disabled={hasNoActivities}
              >
                <span
                  className={`font-semibold ${hasNoActivities ? 'text-primary-placeholder' : 'text-primary-bg'}`}
                >
                  {isExpanded ? 'Shrink' : 'Expand'}
                </span>
                <img
                  src={
                    hasNoActivities
                      ? expandDisabledIcon
                      : isExpanded
                        ? shrinkIcon
                        : expandIcon
                  }
                />
              </button>
            </div>
          );
        } else {
          return columnRenderer(text);
        }
      },
    },
    {
      title: headerRenderer('Partner User'),
      dataIndex: 'partner_user',
      key: 'partner_user',
      ellipsis: true,
      width: '10%',
      // hidden: role === PARTNER_USER,
      render: (text, record, index) => columnRenderer(text, true, null),
    },
    {
      title: headerRenderer('Start Date'),
      dataIndex: 'start_date',
      key: 'start_date',
      width: '8%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, false, '-'),
    },
    {
      title: headerRenderer('Completion Date', true, true),
      dataIndex: 'completion_date',
      key: 'completion_date',
      width: '8%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, false, '-'),
    },
    {
      title: headerRenderer('Status', true, true),
      dataIndex: 'status',
      key: 'status',
      width: '8%',
      render: (text, record, index) =>
        columnRenderer(
          record.isShortClosed
            ? 'Short Closed'
            : record.isDeactivated
              ? 'Deactivated'
              : text,
          false,
          null,
          getActivityStatusClasses(
            record.isShortClosed || record.isDeactivated
              ? ACCEPTANCE_STATUS.shortClose
              : record.status,
          ),
          '-',
        ),
    },
    {
      title: null,
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      render: (_, record) => (
        <Space
          className={`flex items-center justify-center ${record.project_status === PROJECT_STATUS.completed || record.project_status === PROJECT_STATUS.completedDelayed ? 'hidden' : ''}`}
        >
          {PERMISSIONS.canCreateActivity(role) &&
            !record.isShortClosed &&
            record.partner.is_active && (
              <IconButton
                tooltipName={'Add Activity'}
                src={plusNumIcon}
                srcActive={plusNumActiveIcon}
                onClick={() =>
                  navigate('/dashboard/activity?mode=create', {
                    state: { project: projectDetailsData, partner: record },
                  })
                }
              />
            )}
          {PERMISSIONS.canCreateBulkActivity(role) &&
            !record.isShortClosed &&
            record.partner.is_active && (
              <>
                <IconButton
                  loading={downloadingId === record.id}
                  tooltipName={'Download'}
                  src={downloadIcon}
                  srcActive={downloadActiveIcon}
                  onClick={() => handleDownloadActivityForm(record)}
                />
                {uploadBulkActivityLoading && uploadId === record.id ? (
                  <Spin size="small" />
                ) : (
                  <Upload
                    files={file}
                    setFiles={setFile}
                    allowedFileTypes={['.xlsx']}
                    onFileSet={() => setUploadId(record.id)}
                  >
                    <IconButton
                      loading={uploadBulkActivityLoading}
                      tooltipName={'Upload'}
                      src={uploadIcon}
                      srcActive={uploadActiveIcon}
                    />
                  </Upload>
                )}
              </>
            )}
          {/* <IconButton
              tooltipName={'Add Activity'}
              src={userIcon}
              srcActive={userActiveIcon}
            /> */}
        </Space>
      ),
    },
  ];

  //transforming project partners
  const transformedProjectPartners =
    projectDetailsData?.Projects_Partners_Mappings?.map((partner) => {
      const start_date = partner.activities.length
        ? partner.activities
            ?.map((activity) => moment(activity.base_start_date))
            .reduce((minDate, currentDate) =>
              currentDate.isBefore(minDate) ? currentDate : minDate,
            )
        : false;

      const completion_date =
        partner.activities.length &&
        partner.activities?.filter(
          (act) => act.acceptance_status !== ACCEPTANCE_STATUS.stopActivty,
        ).length &&
        partner.activities
          ?.filter(
            (act) => act.acceptance_status !== ACCEPTANCE_STATUS.stopActivty,
          )
          ?.every(
            (activity) =>
              activity.completion_status === STATUS.completed &&
              activity.acceptance_status === ACCEPTANCE_STATUS.accept,
          )
          ? partner.activities
              ?.filter(
                (act) =>
                  act.acceptance_status !== ACCEPTANCE_STATUS.stopActivty,
              )
              .map((activity) => moment(activity.actual_completion_date))
              .reduce((maxDate, currentDate) =>
                currentDate.isAfter(maxDate) ? currentDate : maxDate,
              )
          : false;

      const status =
        partner.activities.length &&
        partner.activities?.filter(
          (act) => act.acceptance_status !== ACCEPTANCE_STATUS.stopActivty,
        ).length
          ? partner.activities
              ?.filter(
                (act) =>
                  act.acceptance_status !== ACCEPTANCE_STATUS.stopActivty,
              )
              .every(
                (activity) =>
                  activity.completion_status === STATUS.completed &&
                  activity.acceptance_status === ACCEPTANCE_STATUS.accept,
              )
            ? STATUS.completed
            : STATUS.inProgress
          : '-';

      const isShortClosed = partner.activities.length
        ? partner.activities?.every(
            (activity) =>
              activity.acceptance_status === ACCEPTANCE_STATUS.shortClose,
          )
        : false;

      const isDeactivated = partner.is_active === false;

      return {
        partner,
        id: partner?.id,
        key: partner?.id,
        project_id: partner.project_id,
        partner_name: partner.partner.partner_name,
        partner_type: partner.PartnerType.type,
        partner_user: partner.contact.contact_name,
        start_date: getFormattedDate(start_date),
        completion_date: getFormattedDate(completion_date),
        status,
        isShortClosed,
        isDeactivated,
        project_status: projectDetailsData?.status,
      };
    }).sort((a, b) => a.partner_type.localeCompare(b.partner_type));

  //filtered project partners list for partner user
  const filteredProjectPartnersListForPartnerUser =
    transformedProjectPartners?.filter(
      (part) =>
        part.partner.partner_user_id === user?.partner_user_id ||
        (part.partner.partner_type_id === cuurentPartnerUserType &&
          part.partner.partner_id === currentPartnerUserPartnerId),
    );

  //filtered project partners list for partner
  const filteredProjectPartnersListForPartner =
    transformedProjectPartners?.filter(
      (part) => part.partner.partner_id === Number(user.partner_id),
    );

  useEffect(() => {
    if (projectDetailsData) {
      const partnerUser = projectDetailsData?.Projects_Partners_Mappings?.find(
        (par) => par.partner_user_id === user.partner_user_id,
      );
      if (partnerUser) {
        setCurrentPartnerUserMappingId(partnerUser.id);
        setCuurentPartnerUserType(partnerUser.partner_type_id);
        setCurrentPartnerUserPartnerId(partnerUser.partner_id);
      } else {
        setCuurentPartnerUserType(null);
        setCurrentPartnerUserPartnerId(null);
      }
    }
  }, [projectDetailsData]);

  //uploading files on file state change
  useEffect(() => {
    const [uploadedFile] = file;
    if (uploadedFile && uploadId) {
      const fd = new FormData();
      fd.append('file', uploadedFile);
      fd.append('partner_user_mapping_id', uploadId);
      dispatch(uploadBulkActivity(fd)).then((res) => {
        if (res?.payload?.status) {
          setFile([]);
          setUploadId(null);
          dispatch(getProjectDetails(projectId));
        }
      });
    }
  }, [file, uploadId, dispatch, projectId]);

  //fetch project detail by id
  useEffect(() => {
    if (projectId) {
      dispatch(getProjectDetails(projectId));
    }
  }, [projectId, dispatch]);

  return (
    <Card
      className="pb-4 my-2 md:my-8"
      headerClasses={
        'font-segoe-ui flex justify-between items-center font-semibold text-2xl text-primary-bg'
      }
      header={
        <>
          <span>{projectDetailsData?.project_name}</span>
          <Button
            icon={<ArrowLeftOutlined />}
            className="font-medium !border-none border-primary-placeholder text-tertiary-text bg-transparent text-md font-segoe-ui hover:!border-primary-bg hover:!text-primary-bg"
            onClick={() => navigate('/')}
          >
            Go Back
          </Button>
        </>
      }
    >
      <Card
        className={
          'mt-5 w-full hide-footer rounded-lg !bg-secondary-bg font-segoe-ui shadow-equal-sides'
        }
      >
        <Table
          hideScroll={lg || xxl ? true : false}
          columns={columns}
          dataSource={
            role === PARTNER_USER
              ? filteredProjectPartnersListForPartnerUser
              : role === PARTNER
                ? filteredProjectPartnersListForPartner
                : transformedProjectPartners
          }
          loading={projectDetailsLoading}
          components={{
            body: {
              cell: (props) => (
                <td {...props} className="!px-6 !py-2.5 !border-gray-200" />
              ),
            },
            header: {
              cell: (props) => (
                <th
                  {...props}
                  className="!bg-secondary-bg !py-3 !px-6 !border-gray-200"
                >
                  {props.children}
                </th>
              ),
            },
          }}
          rowClassName={(record, index) =>
            record.id === expandedPartner?.id
              ? 'shadow-md'
              : `hover:!bg-primary-screen`
          }
          expandable={{
            expandedRowRender: (record) => (
              <ActivityList
                activities={record.partner.activities}
                project={projectDetailsData}
                partner={record}
                currentPartnerUserPartnerId={currentPartnerUserPartnerId}
                currentPartnerUserMappingId={currentPartnerUserMappingId}
              />
            ),
            rowExpandable: () => true,
            expandIcon: () => null,
            expandedRowKeys: expandedPartner ? [expandedPartner.id] : [],
            showExpandColumn: false,
          }}
        />
      </Card>
    </Card>
  );
}
