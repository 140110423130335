import FormInput from '@common/FormInput';
import IconButton from '@common/IconButton';
import { Col, Form, Row } from 'antd';
import { useEffect } from 'react';
import deleteIcon from '@assets/icons/input/delete.svg';
import deleteActiveIcon from '@assets/icons/input/delete_active.svg';
import { PHONE_NUMBER_REGEX } from '@utils/helpers';

export default function ContactListItem({ contact, onClick }) {
  const [form] = Form.useForm();
  //loading selected contact to fields
  useEffect(() => {
    if (contact) {
      form.setFieldsValue(contact);
    }
  }, [contact]);
  return (
    <Form
      form={form}
      layout="vertical"
      id="partner-address-form"
      //   onFinish={handleOnSubmit}
      //   onValuesChange={handleFormValuesChange}
      className="flex flex-col gap-5 !ps-4 !pe-8 rounded-lg bg-tertiary-bg py-2 m-2"
    >
      <Row gutter={24}>
        <Col span={24} lg={{ span: 7 }}>
          <FormInput
            label={'Contact Name'}
            name={'contact_name'}
            placeholder="Enter contact name"
            labelClasess={'text-xs text-tertiary-text font-segoe-ui'}
            required
            validateMsg={'Contact name is required '}
            disabled
          />
        </Col>
        <Col span={24} lg={{ span: 8 }}>
          <FormInput
            disabled
            label={'Phone Number'}
            name={'phone_number'}
            placeholder="Enter phone number"
            labelClasess={'text-xs text-tertiary-text font-segoe-ui'}
            rules={[
              {
                required: true,
                message: 'Phone number is required',
              },
              {
                pattern: PHONE_NUMBER_REGEX,
                message: 'Please enter a valid phone number',
              },
            ]}
          />
        </Col>
        <Col span={24} lg={{ span: 7 }}>
          <FormInput
            disabled
            label={'Email ID'}
            name={'email_id'}
            placeholder="Enter email id"
            labelClasess={'text-xs text-tertiary-text font-segoe-ui'}
            rules={[
              {
                required: true,
                message: 'Email is required',
              },
              {
                type: 'email',
                message: 'Please enter a valid email',
              },
            ]}
          />
        </Col>
        <Col
          span={24}
          lg={{ span: 2 }}
          className="flex justify-center lg:justify-start"
        >
          <FormInput
            type="placeholder"
            hideLabel
            label={'Action'}
            placeholderComponent={
              <IconButton
                src={deleteIcon}
                tooltipName={'Delete'}
                srcActive={deleteActiveIcon}
                onClick={onClick}
              />
            }
          />
        </Col>
      </Row>
    </Form>
  );
}
