import { Table as AntTable, Button, Flex, Space } from 'antd';
import './Table.css';

export default function Table(props) {
  const {
    pagination,
    pageSize = 10,
    currentPage = 1,
    setCurrentPage,
    columns,
    dataSource,
    totalPages = 1,
    bordered = false,
    emptySpaceEl,
    components,
    spanWidth,
    loading,
    hideScroll,
    handleTableChange,
    rowClassName,
    onRow,
    expandable,
    renderFooter,
    extraFooterContent = null,
  } = props;
  // const totalPages = Math.ceil(dataSource.length / pageSize);

  const handlePageChange = (page) => {
    if (setCurrentPage) {
      setCurrentPage(page);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  return (
    <AntTable
      loading={loading}
      locale={emptySpaceEl ? { emptyText: emptySpaceEl } : undefined}
      rowClassName={
        rowClassName
          ? rowClassName
          : (record, index) => `hover:!bg-primary-screen cursor-pointer`
      }
      expandable={expandable}
      components={
        components
          ? components
          : {
              body: {
                // row: (props: any) => {
                //   return (
                //     <tr className="bg-tertiary-highlight-100 hover:bg-secondary-dark">
                //       {props.children}
                //     </tr>
                //   );
                // },
                cell: (props) => {
                  return (
                    <td
                      {...props}
                      style={{ border: bordered ? '' : 'none' }}
                      className="!px-6 !py-4 !border-gray-200"
                    />
                  );
                },
              },
              header: {
                cell: (props) => {
                  return (
                    <th
                      {...props}
                      style={{
                        border: bordered ? '' : 'none',
                      }}
                      className="!bg-secondary-bg !py-3 !px-6 !border-gray-200"
                    >
                      {props.children}
                    </th>
                  );
                },
              },
            }
      }
      scroll={hideScroll ? {} : { x: spanWidth || 1050 }}
      className="w-full"
      sticky
      onRow={onRow}
      dataSource={dataSource}
      columns={columns}
      bordered={false}
      pagination={false}
      showSorterTooltip={false}
      onChange={handleTableChange}
      footer={
        renderFooter
          ? renderFooter
          : () =>
              pagination && (
                <Flex justify="space-between" align="center" className="!mx-2">
                  <div className="flex flex-col items-start gap-2 text-sm font-segoe-ui text-tertiary-text md:gap-4 md:items-center md:flex-row">
                    <span className="text-sm font-segoe-ui text-tertiary-text">
                      Page {currentPage} of{' '}
                      {totalPages === 0 ? '1' : totalPages}
                    </span>
                    {extraFooterContent}
                  </div>
                  <Space size="middle">
                    <Button
                      disabled={currentPage === 1}
                      onClick={() => handlePageChange(currentPage - 1)}
                      className="!p-4.5 border border-primary-placeholder text-tertiary-text !bg-transparent text-sm font-segoe-ui"
                    >
                      Previous
                    </Button>

                    <Button
                      disabled={currentPage === totalPages}
                      onClick={() => handlePageChange(currentPage + 1)}
                      className="!p-4.5 border border-primary-placeholder text-tertiary-text !bg-transparent text-sm font-segoe-ui"
                    >
                      Next
                    </Button>
                  </Space>
                </Flex>
              )
      }
    />
  );
}
