import FormInput from '@common/FormInput';
import { Button, Col, Form, message, Row, Space, Spin, Tooltip } from 'antd';
import calenderIcon from '@assets/icons/input/calender.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import infoIcon from '@assets/icons/input/info.svg';
import { getPartners, selectMastersSlice } from '@features/masterRedux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  createActivity,
  selectActivitySlice,
  updateActivity,
} from '../redux/slice';
import {
  ACCEPTANCE_STATUS,
  checkActivityAcceptanceStatus,
  getDateDifference,
  getFormattedDate,
  getFormattedDateToSend,
  getHighestActualEndDate,
  getLoggedInUser,
  highestActualEndDate,
  PROJECT_STATUS,
  STATUS,
} from '@utils/helpers';
import {
  getProjectDetails,
  selectProjectSlice,
  stopActivity,
} from '@features/project/redux/slice';
import ModalCtx from '@common/ModalCtx';
import {
  GRANUAL_USER,
  PARTNER,
  PARTNER_USER,
  PERMISSIONS,
} from '@utils/roleRights';
import moment from 'moment';
import { openNotification } from '@utils/notification';
import dayjs from 'dayjs';

export default function ActivityDetailsForm({
  project,
  partner,
  activityDetails,
  isEdit,
  isCreate,
  activityId,
  activities,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { role } = getLoggedInUser();

  const { partnersLoading, partnersList } = useSelector(selectMastersSlice);
  const { createActvityLoading, updateActivityLoading } =
    useSelector(selectActivitySlice);
  const { stopActivityLoading, projectDetailsData } =
    useSelector(selectProjectSlice);
  const [isStopActivityModalOpen, setIsStopActivityModalOpen] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //get project actual end date
  const getProjectActualEndDate = (mappings) => {
    // const partners = res?.payload?.data?.Projects_Partners_Mappings;
    const partners = mappings;
    const currentPartnerActivities = partners.find(
      (part) => part.id === partner.id,
    )?.activities;

    //if current partner activities are pending date = null
    if (
      currentPartnerActivities.some(
        (act) => act.completion_status === STATUS.inProgress,
      )
    ) {
      return null;
    }

    if (
      checkActivityAcceptanceStatus(
        partners
          .flatMap((part) => part.activities)
          .filter((act) => act.id !== activityDetails.id),
      )
    ) {
      return getFormattedDateToSend(
        getHighestActualEndDate(partners.flatMap((part) => part.activities)),
      );
    } else {
      return null;
    }
  };

  //get project actual end date for stop activity
  const getProjectActualEndDateForStopActivity = (mappings) => {
    const partners = mappings;
    const activities = partners
      .flatMap((act) => act.activities)
      .filter((act) => act.id !== activityId);

    if (!activities.length) {
      return null;
    }

    if (checkActivityAcceptanceStatus(activities)) {
      return getFormattedDateToSend(
        getHighestActualEndDate(partners.flatMap((part) => part.activities)),
      );
    } else {
      return null;
    }
  };

  //fetching project details data
  useEffect(() => {
    if (partner?.project_id) {
      dispatch(getProjectDetails(partner.project_id));
    }
  }, [partner]);

  //get project status on create and edit
  const getProjectStatusForCreateAndEdit = () => {
    const projectEndDate = moment(
      getFormattedDateToSend(projectDetailsData?.project_end_date),
    );

    // if create
    if (isCreate) {
      const activities = projectDetailsData?.Projects_Partners_Mappings.filter(
        (p) => p.is_active,
      )
        ?.flatMap((act) => act.activities)
        .filter(
          (act) =>
            act.acceptance_status !== ACCEPTANCE_STATUS.shortClose &&
            act.acceptance_status !== ACCEPTANCE_STATUS.stopActivty,
        );

      if (!activities.length) {
        return PROJECT_STATUS.ongoing;
      }

      if (getHighestActualEndDate(activities).isAfter(projectEndDate)) {
        return PROJECT_STATUS.delayed;
      } else {
        return PROJECT_STATUS.ongoing;
      }

      // if edit
    } else {
      const activities = projectDetailsData?.Projects_Partners_Mappings.filter(
        (p) => p.is_active,
      )
        ?.flatMap((act) => act.activities)
        .filter(
          (act) =>
            act.acceptance_status !== ACCEPTANCE_STATUS.shortClose &&
            act.acceptance_status !== ACCEPTANCE_STATUS.stopActivty,
        );
      const values = form.getFieldsValue();
      if (values.actual_completion_date) {
        const updatedActivites = activities.filter(
          (act) => act.id !== activityDetails.id,
        );

        if (
          getHighestActualEndDate([
            ...updatedActivites,
            {
              actual_completion_date: getFormattedDateToSend(
                values.actual_completion_date,
              ),
            },
          ]).isAfter(projectEndDate)
        ) {
          return PROJECT_STATUS.delayed;
        } else {
          return PROJECT_STATUS.ongoing;
        }
      } else {
        if (getHighestActualEndDate(activities).isAfter(projectEndDate)) {
          return PROJECT_STATUS.delayed;
        } else {
          return PROJECT_STATUS.ongoing;
        }
      }
    }
  };

  //get project status on aceept
  const getProjectStatusForAccept = () => {
    const projectEndDate = moment(
      getFormattedDateToSend(projectDetailsData?.project_end_date),
    );

    const activities = projectDetailsData?.Projects_Partners_Mappings.filter(
      (p) => p.is_active,
    )
      ?.flatMap((act) => act.activities)
      .filter(
        (act) =>
          act.id !== activityDetails.id &&
          act.acceptance_status !== ACCEPTANCE_STATUS.shortClose &&
          act.acceptance_status !== ACCEPTANCE_STATUS.stopActivty,
      );

    const activitiesWithCurrentOpen =
      projectDetailsData?.Projects_Partners_Mappings.filter((p) => p.is_active)
        ?.flatMap((act) => act.activities)
        .filter(
          (act) =>
            act.acceptance_status !== ACCEPTANCE_STATUS.shortClose &&
            act.acceptance_status !== ACCEPTANCE_STATUS.stopActivty,
        );

    if (checkActivityAcceptanceStatus(activities) || !activities.length) {
      if (
        getHighestActualEndDate(activitiesWithCurrentOpen).isAfter(
          projectEndDate,
        )
      ) {
        return PROJECT_STATUS.completedDelayed;
      } else {
        return PROJECT_STATUS.completed;
      }
    } else {
      if (
        getHighestActualEndDate(activitiesWithCurrentOpen).isAfter(
          projectEndDate,
        )
      ) {
        return PROJECT_STATUS.delayed;
      } else {
        return PROJECT_STATUS.ongoing;
      }
    }
  };

  //get project status on stop activity
  const getProjectStatusForStopActivity = () => {
    const projectEndDate = moment(
      getFormattedDateToSend(projectDetailsData?.project_end_date),
    );

    const activities = projectDetailsData?.Projects_Partners_Mappings.filter(
      (p) => p.is_active,
    )
      ?.flatMap((act) => act.activities)
      .filter(
        (act) =>
          act.id !== activityDetails.id &&
          act.acceptance_status !== ACCEPTANCE_STATUS.shortClose &&
          act.acceptance_status !== ACCEPTANCE_STATUS.stopActivty,
      );

    const activitiesWithCurrentOpen =
      projectDetailsData?.Projects_Partners_Mappings.filter((p) => p.is_active)
        ?.flatMap((act) => act.activities)
        .filter(
          (act) =>
            act.acceptance_status !== ACCEPTANCE_STATUS.shortClose &&
            act.acceptance_status !== ACCEPTANCE_STATUS.stopActivty,
        );

    if (!activities.length) {
      return PROJECT_STATUS.ongoing;
    }

    if (checkActivityAcceptanceStatus(activities)) {
      if (
        getHighestActualEndDate(activitiesWithCurrentOpen).isAfter(
          projectEndDate,
        )
      ) {
        return PROJECT_STATUS.completedDelayed;
      } else {
        return PROJECT_STATUS.completed;
      }
    } else {
      if (
        getHighestActualEndDate(activitiesWithCurrentOpen).isAfter(
          projectEndDate,
        )
      ) {
        return PROJECT_STATUS.delayed;
      } else {
        return PROJECT_STATUS.ongoing;
      }
    }
  };

  //handle craete pr update activity
  const handleOnSubmit = (values) => {
    if (!partner.id) {
      if (project.id) {
        navigate(`dashboard/project/${project.id}`);
      } else {
        navigate('/');
      }
    } else {
      const payload = {
        remarks_by_granules: role === GRANUAL_USER ? values.remarks : null,
        remarks_by_partner:
          role === PARTNER || role === PARTNER_USER ? values.remarks : null,
        partner_user_mapping_id: partner.id,
        ...values,
        base_start_date: getFormattedDateToSend(values.base_start_date),
        base_end_date: getFormattedDateToSend(values.base_end_date),
        current_start_date: getFormattedDateToSend(values.current_start_date),
        current_end_date: getFormattedDateToSend(values.current_end_date),
        estimated_date: getFormattedDateToSend(values.estimated_date),
        actual_completion_date: getFormattedDateToSend(
          values.actual_completion_date,
        ),
        estimated_duration: getDateDifference(
          values.base_end_date,
          values.base_start_date,
        ),
        status: getProjectStatusForCreateAndEdit(),
        project_actual_end_date: null,
        id: isEdit ? activityId : null,
      };
      if (isEdit) {
        dispatch(updateActivity(payload)).then((res) => {
          if (res?.payload?.success) {
            navigate(`/dashboard/project/${project.id}`);
          }
        });
      } else {
        dispatch(createActivity(payload)).then((res) => {
          if (res?.payload?.status) {
            navigate(`/dashboard/project/${project.id}`);
          }
        });
      }
    }
  };

  //handling form value change
  const handleFormValuesChange = (changedVal, values) => {
    if (changedVal.base_start_date) {
      form.setFieldsValue({ completion_status: STATUS.inProgress });
      if (values.actual_completion_date) {
        form.setFieldsValue({
          overall_duration: String(
            getDateDifference(
              values.actual_completion_date,
              values.base_start_date,
            ),
          ),
        });
      }

      if (values.base_end_date) {
        if (
          moment(getFormattedDateToSend(values.base_end_date)).isBefore(
            moment(getFormattedDateToSend(values.base_start_date)),
          )
        ) {
          form.setFieldsValue({
            estimated_duration: null,
            base_end_date: null,
          });
        } else {
          form.setFieldsValue({
            estimated_duration: String(
              getDateDifference(values.base_end_date, values.base_start_date),
            ),
          });
        }
      }

      if (values.current_start_date) {
        if (
          moment(getFormattedDateToSend(values.current_start_date)).isBefore(
            moment(getFormattedDateToSend(values.base_start_date)),
          )
        ) {
          form.setFieldsValue({
            current_start_date: null,
          });
        }
      }
    }

    if (!values.base_start_date || !values.base_end_date) {
      form.setFieldsValue({
        estimated_duration: null,
      });
    }

    if (changedVal.current_start_date && values.current_end_date) {
      if (
        moment(getFormattedDateToSend(values.current_end_date)).isBefore(
          moment(getFormattedDateToSend(values.current_start_date)),
        )
      ) {
        form.setFieldsValue({
          current_end_date: null,
        });
      }
    }

    if (changedVal.actual_completion_date && values.base_start_date) {
      form.setFieldsValue({
        overall_duration: String(
          getDateDifference(
            values.actual_completion_date,
            values.base_start_date,
          ),
        ),
      });
    }

    if (changedVal.base_end_date && values.base_start_date) {
      form.setFieldsValue({
        estimated_duration: String(
          getDateDifference(values.base_end_date, values.base_start_date),
        ),
      });
    }

    if (isEdit) {
      if (changedVal.actual_completion_date) {
        form.setFieldsValue({
          completion_status: STATUS.completed,
          estimated_date: null,
        });
      }
      if (!values.actual_completion_date) {
        form.setFieldsValue({
          completion_status: STATUS.inProgress,
          overall_duration: null,
        });
      }

      if (changedVal.estimated_date) {
        form.setFieldsValue({
          actual_completion_date: null,
          overall_duration: null,
        });
      }

      if (changedVal.current_end_date) {
        form.setFieldsValue({
          estimated_date: null,
          actual_completion_date: null,
          overall_duration: null,
        });
      }

      if (changedVal.completion_status) {
        if (values.completion_status === STATUS.inProgress) {
          form.setFieldsValue({
            actual_completion_date: null,
            overall_duration: null,
          });
          setIsFormDirty(true);
        } else {
          form.setFieldsValue({
            actual_completion_date:
              activityDetails?.actual_completion_date &&
              moment(activityDetails?.actual_completion_date),
            overall_duration: activityDetails.overall_duration,
          });
          setIsFormDirty(false);
        }
      }
    }
  };

  //transforming partners list
  const transformedPartnersList = partnersList
    ?.filter((item) => {
      return project.Projects_Partners_Mappings.some(
        (partner) => partner.partner_id === item.id,
      );
    })
    ?.map((pa) => {
      return { label: pa.partner_name, value: pa.id };
    })
    .filter((item) => item.value === partner.partner.partner_id);

  //transforming status list
  const transformedStatusList = [
    { label: STATUS.inProgress, value: STATUS.inProgress },
    { label: STATUS.completed, value: STATUS.completed },
  ];

  //activity Accept
  const handleActivityAccept = () => {
    const values = form.getFieldsValue();
    const payload = {
      id: activityId,
      acceptance_status: ACCEPTANCE_STATUS.accept,
      status_type: ACCEPTANCE_STATUS.accept,
      status: getProjectStatusForAccept(),
      project_actual_end_date: getProjectActualEndDate(
        projectDetailsData?.Projects_Partners_Mappings.filter(
          (p) => p.is_active,
        ) || [],
      ),
      remarks_by_granules: role === GRANUAL_USER ? values.remarks : null,
      remarks_by_partner:
        role === PARTNER || role === PARTNER_USER ? values.remarks : null,
    };
    dispatch(updateActivity(payload)).then((res) => {
      if (res?.payload?.success) {
        navigate('/dashboard/project/' + project.id);
      }
    });
  };

  //stopping activity
  const handleStopActivity = (values) => {
    if (isStopActivityModalOpen) {
      if (!values.stop_activity_remarks) {
        message.error('Please enter remarks');
        return;
      }
      const payload = {
        id: activityId,
        acceptance_status: ACCEPTANCE_STATUS.stopActivty,
        status_type: ACCEPTANCE_STATUS.stopActivty,
        remarks_by_granules: values.stop_activity_remarks,
        completion_status: '',
        status: getProjectStatusForStopActivity(),
        project_actual_end_date: getProjectActualEndDateForStopActivity(
          projectDetailsData?.Projects_Partners_Mappings.filter(
            (p) => p.is_active,
          ) || [],
        ),
      };
      dispatch(stopActivity(payload)).then((res) => {
        setIsStopActivityModalOpen(false);
        if (res?.payload?.success) {
          navigate('/dashboard/project/' + project.id);
        }
      });
    } else {
      setIsStopActivityModalOpen(true);
    }
  };

  //fetching partners list
  useEffect(() => {
    dispatch(getPartners());
  }, [dispatch]);

  //populating form if is edit
  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        activity_desc: activityDetails?.activity_desc,
        responsibility: activityDetails?.responsibility,
        completion_status: activityDetails?.completion_status
          ? activityDetails?.completion_status
          : STATUS.inProgress,
        estimated_duration: activityDetails?.estimated_duration,
        overall_duration: activityDetails?.overall_duration,
        base_end_date: activityDetails?.base_end_date
          ? dayjs(activityDetails?.base_end_date)
          : null,
        base_start_date: activityDetails?.base_start_date
          ? dayjs(activityDetails?.base_start_date)
          : null,
        current_end_date: activityDetails?.current_end_date
          ? dayjs(activityDetails?.current_end_date)
          : null,
        current_start_date: activityDetails?.current_start_date
          ? dayjs(activityDetails?.current_start_date)
          : null,
        estimated_date: activityDetails?.estimated_date
          ? dayjs(activityDetails?.estimated_date)
          : null,
        actual_completion_date: activityDetails?.actual_completion_date
          ? dayjs(activityDetails?.actual_completion_date)
          : null,
        remarks: null,
      });
    }
  }, [isEdit, activityDetails]);

  //disabling past dates
  const disabledStartBaseDates = (current) => {
    return current && current < moment().startOf('day');
  };
  //same for base end date & estimated date & actual end date
  const disabledCurrentBaseDates = (current) => {
    const values = form.getFieldsValue();
    const start = moment(getFormattedDateToSend(values.base_start_date));
    return current && current.isBefore(start.startOf('day'));
  };

  //same for base end date
  const disabledCurrentBaseEndDates = (current) => {
    const values = form.getFieldsValue();
    const start = moment(getFormattedDateToSend(values.current_start_date));
    return current && current.isBefore(start.startOf('day'));
  };

  return (
    <Form
      form={form}
      onFinish={handleOnSubmit}
      onValuesChange={handleFormValuesChange}
      layout="vertical"
      className="sm:mx-2 md:mx-4 lg:mx-7"
    >
      <Spin
        spinning={
          partnersLoading ||
          stopActivityLoading ||
          updateActivityLoading ||
          createActvityLoading
        }
      >
        <Row className="mb-10">
          <Col span={24}>
            <FormInput
              name={'activity_desc'}
              label={'Activity Descripiton'}
              autoSize
              placeholder="Enter activity description"
              required
              validateMsg={'Activity description is required'}
              // hideColon
              disabled={isEdit}
            />
          </Col>
        </Row>

        {/* 1st row */}
        <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-5">
          <FormInput
            name={'responsibility'}
            label={'Responsibility'}
            placeholder="Select responsibility"
            type="select"
            options={transformedPartnersList}
            optionRender={(option) => {
              return (
                <span className="text-xs font-segoe-ui text-tertiary-text">
                  {option.label}
                </span>
              );
            }}
            loading={partnersLoading}
            required
            validateMsg={'Responsibility is required'}
            // hideColon
            disabled={isEdit}
          />
          <FormInput
            name={'base_start_date'}
            label={'Base Start Date'}
            placeholder="Select base start date"
            type="date"
            allowClear
            suffixIcon={<img src={calenderIcon} />}
            required
            validateMsg={'Base start date is required'}
            // hideColon
            disabled={isEdit}
            disabledDate={disabledStartBaseDates}
          />
          <FormInput
            name={'base_end_date'}
            label={'Base End Date'}
            placeholder="Select base end date"
            type="date"
            allowClear
            suffixIcon={<img src={calenderIcon} />}
            required
            validateMsg={'Base end date is required'}
            // hideColon
            disabled={isEdit}
            disabledDate={disabledCurrentBaseDates}
          />
          <FormInput
            name={'current_start_date'}
            label={'Current Start Date'}
            placeholder="Select current start date"
            type="date"
            allowClear
            suffixIcon={<img src={calenderIcon} />}
            rules={[
              {
                validator: (_, value) => {
                  const values = form.getFieldsValue();
                  if (values.current_end_date && !value) {
                    return Promise.reject(
                      new Error('Current start date is required'),
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
            disabled={
              activityDetails?.completion_status === STATUS.completed ||
              (isEdit &&
                activityDetails?.current_start_date &&
                role === GRANUAL_USER) ||
              (isEdit && (role === PARTNER_USER || role === PARTNER))
            }
            disabledDate={disabledCurrentBaseDates}
          />
          <FormInput
            name={'current_end_date'}
            label={'Current End Date'}
            placeholder="Select current end date"
            type="date"
            allowClear
            suffixIcon={<img src={calenderIcon} />}
            rules={[
              {
                validator: (_, value) => {
                  const values = form.getFieldsValue();
                  if (values.current_start_date && !value) {
                    return Promise.reject(
                      new Error('Current end date is required'),
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
            disabled={
              (isEdit && (role === PARTNER_USER || role === PARTNER)) ||
              activityDetails?.completion_status === STATUS.completed
            }
            disabledDate={disabledCurrentBaseEndDates}
          />

          {/* 2nd row */}
          <FormInput
            name={'estimated_date'}
            label={'Estimated Completion Date'}
            placeholder="Select estimated completion date"
            type="date"
            allowClear
            suffixIcon={<img src={calenderIcon} />}
            disabled={
              activityDetails?.completion_status === STATUS.completed ||
              (isCreate && role === GRANUAL_USER) ||
              (isEdit && role === GRANUAL_USER) ||
              (isEdit &&
                (activityDetails?.actual_completion_date ||
                  activityDetails?.estimated_date))
            }
            rules={[
              {
                validator: (_, value) => {
                  if (role === PARTNER_USER || role === PARTNER) {
                    const values = form.getFieldsValue([
                      'estimated_date',
                      'actual_completion_date',
                    ]);
                    if (!values.actual_completion_date && !value) {
                      return Promise.reject(
                        new Error('Estimated completion date is required'),
                      );
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
                dependencies: ['actual_completion_date'],
              },
            ]}
            disabledDate={disabledCurrentBaseDates}
          />
          <FormInput
            name={'actual_completion_date'}
            label={'Actual Completion Date'}
            placeholder="Select actual completion date"
            type="date"
            allowClear
            suffixIcon={<img src={calenderIcon} />}
            labelEllipsis
            rules={[
              {
                validator: (_, value) => {
                  if (role === PARTNER_USER || role === PARTNER) {
                    const values = form.getFieldsValue([
                      'estimated_date',
                      'actual_completion_date',
                    ]);
                    if (!values.estimated_date && !value) {
                      return Promise.reject(
                        new Error('Actual completion date is required'),
                      );
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
                dependencies: ['estimated_date'],
              },
            ]}
            disabled={
              (isCreate && role === GRANUAL_USER) ||
              (isEdit && role === GRANUAL_USER) ||
              (isEdit && activityDetails?.actual_completion_date)
            }
            disabledDate={disabledCurrentBaseDates}
          />
          <FormInput
            name={'overall_duration'}
            label={
              <div className="flex items-center justify-between gap-2">
                <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                  Overall Duration (In Months)
                </span>
                <Tooltip
                  title="Total time taken to complete the activity"
                  placement="top"
                >
                  <img src={infoIcon} className="hover:opacity-60" />
                </Tooltip>
              </div>
            }
            disabled
            labelEllipsisWithoutTooltip
            placeholder="Overall duration"
          />
          <FormInput
            name={'estimated_duration'}
            label={
              <div className="flex items-center justify-between gap-2">
                <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                  Estimated Duration (In Months)
                </span>
                <Tooltip
                  title="Difference of actual agreed base date"
                  placement="top"
                >
                  <img src={infoIcon} className="hover:opacity-60" />
                </Tooltip>
              </div>
            }
            disabled
            labelEllipsisWithoutTooltip
            placeholder="Estimated duration"
          />
          <FormInput
            name={'completion_status'}
            label={'Completion Status'}
            placeholder="Select status"
            type="select"
            optionRender={(option) => {
              return (
                <span className="text-xs font-segoe-ui text-tertiary-text">
                  {option.label}
                </span>
              );
            }}
            options={transformedStatusList}
            disabled={
              isCreate ||
              role === PARTNER ||
              role === PARTNER_USER ||
              (isEdit &&
                activityDetails?.completion_status === STATUS.inProgress &&
                !activityDetails?.actual_completion_date)
            }
          />
        </div>

        <Row className="mt-4">
          <Col span={24}>
            <FormInput
              name={'remarks'}
              label={'Remarks'}
              autoSize
              placeholder="Enter remarks"
              disabled={
                activityDetails?.completion_status === STATUS.completed &&
                (role === PARTNER || role === PARTNER_USER)
              }
            />
          </Col>
        </Row>

        {/* action buttons */}
        <Space size="middle" className="flex items-center justify-center mt-10">
          {/* .................back............ */}
          <Button
            className="!p-4.5 font-medium border border-primary-placeholder text-tertiary-text bg-transparent text-md font-segoe-ui hover:!border-primary-bg hover:!text-primary-bg"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>

          {/* .................create and edit............ */}
          <Button
            onClick={() => form.submit()}
            className="!p-4.5 font-medium bg-primary-bg text-secondary-bg hover:border hover:border-primary-bg hover:!bg-transparent hover:!text-primary-bg text-md font-segoe-ui"
            loading={createActvityLoading || updateActivityLoading}
            disabled={
              isEdit &&
              activityDetails?.completion_status === STATUS.completed &&
              !isFormDirty
            }
          >
            {isEdit ? 'Save' : 'Create'}
          </Button>

          {/* .................Accept............ */}
          {isEdit &&
            activityDetails?.completion_status === STATUS.completed &&
            PERMISSIONS.canAcceptAcitivity(role) && (
              <Button
                onClick={() => {
                  openNotification({
                    onApprove: () => handleActivityAccept(),
                    title: 'Accept activity',
                    subTitle: 'Are you sure you want to accept this activity?',
                    approveBtnClasses:
                      'w-full !py-2 bg-primary-green text-secondary-bg hover:!bg-transparent hover:!border-primary-green hover:!border hover:!text-primary-green',
                    okBtnLoading: updateActivityLoading,
                    cancelBtnClasses: 'w-full !py-2',
                    approveText: 'Accept',
                    cancelText: 'Cancel',
                  });
                }}
                className="!p-4.5 font-medium bg-primary-green text-secondary-bg hover:border hover:border-primary-green hover:!bg-transparent hover:!text-primary-green text-md font-segoe-ui"
                disabled={isFormDirty}
              >
                Accept
              </Button>
            )}

          {/* .................stop activity............ */}
          {isEdit &&
            activityDetails?.completion_status === STATUS.inProgress &&
            PERMISSIONS.canStopActivity(role) && (
              <Button
                className="!p-4.5 hover:text-primary-bg hover:border-primary-bg font-medium text-md font-segoe-ui border-primary-red text-primary-red"
                loading={stopActivityLoading}
                onClick={handleStopActivity}
              >
                Stop Activity
              </Button>
            )}

          {/* stop activity remarks popup */}
          <ModalCtx
            onApprove={(values) => handleStopActivity(values)}
            onClose={() => setIsStopActivityModalOpen(false)}
            isOpen={isStopActivityModalOpen}
            fieldName="stop_activity_remarks"
            fieldPlaceholder="Enter remarks"
            loading={stopActivityLoading}
            headerTitle="Remarks"
            description={'Remarks by Granules'}
            agreeText="Stop Activity"
            agreeBtnClasses={
              'w-full !py-4.5 bg-primary-red text-secondary-bg hover:!bg-transparent hover:!border-primary-red hover:!border hover:!text-primary-red'
            }
          />
        </Space>
      </Spin>
    </Form>
  );
}
