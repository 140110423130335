import { useResponsiveness } from '@hooks/useResponsiveness';
import { getFormattedDateToSend, PROJECT_STATUS } from '@utils/helpers';
import moment from 'moment';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export default function Chart({
  data,
  monthDuration,
  totalProjectRangeMonths,
  projectStartDate,
}) {
  const { xs, sm } = useResponsiveness();

  const getLast12Months = () => {
    const months = [];
    const currentMonth = moment();
    if (monthDuration) {
      for (let i = 0; i < monthDuration; i++) {
        months.push(currentMonth.clone().subtract(i, 'months').format('MMM'));
      }
    } else {
      for (let i = 0; i < totalProjectRangeMonths; i++) {
        months.push(
          moment(getFormattedDateToSend(projectStartDate))
            .clone()
            .add(i, 'months')
            .format('MMM'),
        );
      }
    }
    if (monthDuration) {
      return months.reverse();
    } else {
      return months;
    }
  };

  const CALENDER_MONTHS_ORDER = getLast12Months();

  //grouping projects by month
  const groupedProjectsByMonth = data?.reduce((acc, project) => {
    const month = moment(project.project_end_date).format('MMM');
    if (!acc[month]) {
      acc[month] = [];
    }
    acc[month].push(project);
    return acc;
  }, {});

  //tranforming projects for chart
  let transformedChartData = CALENDER_MONTHS_ORDER.map((month) => {
    const projects = groupedProjectsByMonth[month] || []; // Get projects for the month or empty array
    const counts = {
      completedCount: projects.filter(
        (p) => p.status === PROJECT_STATUS.completed,
      ).length,
      delayedCount: projects.filter((p) => p.status === PROJECT_STATUS.delayed)
        .length,
      ongoingCount: projects.filter((p) => p.status === PROJECT_STATUS.ongoing)
        .length,
    };

    return {
      month: month,
      ongoing: counts.ongoingCount,
      completed: counts.completedCount,
      delayed: counts.delayedCount,
    };
  });

  const chartData = [
    {
      name: 'Nov',
      uv: 1,
      pv: 3,
      onGoing: 4,
    },
    {
      name: 'Dec',
      onGoing: 4,
      uv: 5,
      pv: 3,
    },
    {
      name: 'Jan',
      onGoing: 4,
      uv: 1,
      pv: 3,
    },
    {
      name: 'Feb',
      onGoing: 4,
      uv: 1,
      pv: 3,
    },
    {
      name: 'Mar',
      onGoing: 4,
      uv: 3,
      pv: 5,
    },
    {
      name: 'Apr',
      onGoing: 4,
      uv: 1,
      pv: 2,
    },
    {
      name: 'May',
      onGoing: 4,
      uv: 1,
      pv: 3,
    },
    {
      name: 'Jun',
      onGoing: 4,
      uv: 2,
      pv: 3,
    },
    {
      name: 'Jul',
      onGoing: 4,
      uv: 2,
      pv: 5,
    },
    {
      name: 'Aug',
      onGoing: 4,
      uv: 2,
      pv: 4,
    },
    {
      name: 'Sep',
      onGoing: 4,
      uv: 1,
      pv: 4,
    },
    {
      name: 'Oct',
      onGoing: 4,
      uv: 2,
      pv: 3,
    },
  ];

  //rounded bar
  const RoundedBar = (props) => {
    const { x, y, width, height, fill, isBg } = props;

    return isBg ? (
      <Rectangle
        x={x}
        y={y}
        width={width * 1.5}
        height={height}
        fill={fill}
        radius={[6, 6, 0, 0]}
      />
    ) : (
      <Rectangle
        x={x - width}
        y={y}
        width={width}
        height={height}
        fill={fill}
        radius={[6, 6, 0, 0]}
      />
    );
  };

  //rendering custom legends
  const rendredCustomLegends = () => {
    return (
      <div className="flex flex-row items-end justify-center gap-4 m-0 md:relative md:flex-col md:m-4">
        <div className="flex items-center gap-1">
          <div className="w-3 h-3 rounded-full bg-[#0092DD]"></div>
          <label className="text-xs font-semibold font-segoe-ui text-tertiary-placeholder">
            Completed
          </label>
        </div>
        <div className="flex items-center gap-1">
          <div className="w-3 h-3 rounded-full bg-[#78D1FF]"></div>
          <label className="text-xs font-semibold font-segoe-ui text-tertiary-placeholder">
            Ongoing
          </label>
        </div>
        <div className="flex items-center gap-1">
          <div className="w-3 h-3 rounded-full bg-[#FF8686]"></div>
          <label className="text-xs font-semibold font-segoe-ui text-tertiary-placeholder">
            Delayed
          </label>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full h-96">
      <ResponsiveContainer>
        <BarChart
          data={
            monthDuration
              ? transformedChartData.reverse()
              : transformedChartData
          }
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          barGap={0}
          style={{ position: 'relative' }}
        >
          <CartesianGrid stroke="#F2F4F7" vertical={false} />
          <XAxis
            dataKey="month"
            tick={{
              fill: '#667085',
              fontSize: '12',
              textAnchor: 'end',
            }}
            tickLine={false}
            axisLine={false}
          />
          <YAxis
            type="number"
            tickLine={false}
            axisLine={false}
            tickFormatter={(value) => (value === 0 ? '' : value)}
            tick={{
              fill: '#667085',
              fontSize: '12',
              textAnchor: 'start',
            }}
            domain={0}
            label={
              xs || sm
                ? {}
                : {
                    value: 'No. of Projects',
                    angle: -90,
                    position: 'insideLeft',
                    style: {
                      textAnchor: 'middle',
                      fill: '#667085',
                      fontSize: 14,
                      fontWeight: '600',
                    },
                  }
            }
          />
          <Tooltip
            cursor={{ fill: 'transparent' }}
            contentStyle={{ borderRadius: '0.5rem' }}
          />
          <Legend
            content={rendredCustomLegends}
            layout={xs || sm ? 'horizontal' : 'vertical'}
            align="right"
            verticalAlign="top"
          />
          <Bar dataKey="ongoing" fill="#78D1FF" shape={<RoundedBar isBg />} />
          <Bar dataKey="completed" fill="#0092DD" shape={<RoundedBar />} />
          <Bar dataKey="delayed" fill="#FF8686" shape={<RoundedBar />} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
