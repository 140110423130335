import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import Label from '@common/Label';
import apiClient, { getLocalToken } from '@utils/apiClient';
import {
  ACCEPTANCE_STATUS,
  columnRenderer,
  getProjectStatus,
  headerRenderer,
} from '@utils/helpers';
import { Button, message, Space, Tooltip } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import ActivityDownloadTable from './ActivityDownloadTable';

export default function ActiveProjectDetails({ data, activeProject }) {
  const [downloadingId, setDownloadingId] = useState(null);

  const partnerNames = [
    ...new Set(
      data?.Projects_Partners_Mappings?.filter((part) =>
        part.activities.every(
          (p) => p.acceptance_status !== ACCEPTANCE_STATUS.shortClose,
        ),
      ).map((partner) =>
        partner.partner?.partner_company_name ? partner.partner?.partner_company_name : 'NA',
      ),
    ),
  ];

  const partnerTypes = [
    ...new Set(
      data?.Projects_Partners_Mappings?.filter((part) =>
        part.activities.every(
          (p) => p.acceptance_status !== ACCEPTANCE_STATUS.shortClose,
        ),
      ).map((partner) =>
        partner.PartnerType?.type ? partner.PartnerType?.type : 'NA',
      ),
    ),
  ];

  //handle download activity
  const handleDownloadActivity = async (partner) => {
    setDownloadingId(partner.id);
    try {
      const params = new URLSearchParams({
        type: 'Partner',
        id: partner.partner_id,
        project_id: partner.project_id,
        type_id: partner.partner_type_id,
        partner_user_id: partner.partner_user_id,
      });
      const response = await fetch(
        `${apiClient.Urls.downloadActivityByPartnerProjectType}?${params}`,
        {
          headers: {
            Authorization: getLocalToken(),
          },
        },
      );
      if (!response.ok) {
        throw new Error('Failed to download score card...');
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', 'activity.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setDownloadingId(null);
      message.error(error.message || 'Failed to download activity...');
    } finally {
      setDownloadingId(null);
    }
  };

  const columns = [
    {
      title: headerRenderer('Partner Company Name'),
      dataIndex: 'partner_name',
      key: 'partner_name',
      ellipsis: true,
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Partner User'),
      dataIndex: 'partner_user',
      key: 'partner_user',
      ellipsis: true,
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Partner Type'),
      dataIndex: 'partner_type',
      key: 'partner_type',
      ellipsis: true,
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Performance Rating'),
      dataIndex: 'performance_rating',
      key: 'performance_rating',
      ellipsis: true,
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Activity'),
      dataIndex: 'activity',
      key: 'activity',
      width: '14%',
      render: (text, record, index) =>
        downloadingId === record?.partnerData.id ? (
          <LoadingOutlined spin />
        ) : (
          <Tooltip title="Download">
            <DownloadOutlined
              className="text-xl text-primary-bg"
              onClick={() => handleDownloadActivity(record.partnerData)}
            />
          </Tooltip>
        ),
    },
  ];

  //handle download all activities of project
  const handleDownloadAllActivity = async (id, type) => {
    setDownloadingId(id);
    try {
      const params = new URLSearchParams({
        type,
        id,
      });
      const response = await fetch(
        `${apiClient.Urls.downloadActivity}?${params}`,
        {
          headers: {
            Authorization: getLocalToken(),
          },
        },
      );
      if (!response.ok) {
        throw new Error('Failed to download score card...');
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', 'activity.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setDownloadingId(null);
      message.error(error.message || 'Failed to download activity...');
    } finally {
      setDownloadingId(null);
    }
  };

  //transforming partner list
  const transformedPartnerList = data?.Projects_Partners_Mappings?.filter(
    (part) =>
      part.activities.every(
        (p) => p.acceptance_status !== ACCEPTANCE_STATUS.shortClose,
      ),
  )
    .map((partner) => {
      return {
        id: partner.id,
        key: partner.id,
        partner_name: partner.partner.partner_company_name,
        partner_user: partner.contact.contact_name,
        partner_type: partner.PartnerType.type,
        performance_rating: partner.rating,
        partnerData: partner,
      };
    })
    .sort((a, b) => a.partner_type.localeCompare(b.partner_type));

  //classess
  const labelClasses =
    'text-tertiary-placeholder font-medium text-xs font-segoe-ui w-full text-pretty break-keep sm:w-[25%] md:w-[12%]';
  const descriptionClasses =
    'text-tertiary-placeholder w-auto text-xs font-segoe-ui flex gap-1';
  const containerClasses = 'flex flex-col sm:flex-row w-full items-start gap-2';

  return (
    <div className="mt-5">
      {/* project details */}
      <div className="flex flex-col w-full gap-3 mt-5">
        <Label
          containerClasses={containerClasses}
          labelClasses={labelClasses}
          descriptionClasses={descriptionClasses}
          label={'Status:'}
          description={
            <Space size="small">{getProjectStatus(data?.status)}</Space>
          }
        />

        <Label
          containerClasses={containerClasses}
          labelClasses={labelClasses}
          descriptionClasses={descriptionClasses}
          label={'Completed date:'}
          description={
            data?.project_actual_end_date
              ? moment(data?.project_actual_end_date).format('DD MMMM YYYY')
              : '-'
          }
        />
        <Label
          containerClasses={containerClasses}
          labelClasses={labelClasses}
          descriptionClasses={descriptionClasses}
          label={'Partner Company Names:'}
          description={partnerNames.map((partner) => {
            return (
              <div
                key={partner}
                className="px-2 py-1 font-semibold rounded-md text-primary-bg bg-secondary-screen w-fit text-2xs font-segoe-ui"
              >
                {partner}
              </div>
            );
          })}
        />
        <Label
          containerClasses={containerClasses}
          labelClasses={labelClasses}
          descriptionClasses={descriptionClasses}
          label={'Partner Type:'}
          description={partnerTypes.map((type) => {
            return (
              <div
                key={type}
                className="px-2 py-1 font-semibold rounded-md text-tertiary-placeholder bg-[#F2F2F2] w-fit text-2xs font-segoe-ui"
              >
                {type}
              </div>
            );
          })}
        />
        <Label
          containerClasses={containerClasses}
          labelClasses={labelClasses}
          descriptionClasses={descriptionClasses}
          label={'Description:'}
          description={data?.project_desc}
        />
      </div>

      {/* project partners table */}
      <ActivityDownloadTable
        columns={columns}
        dataSource={transformedPartnerList}
        heading={'Project Details'}
        headingClasses="font-semibold font-segoe-ui text-tertiary-placeholder"
        containerClasses={'mt-8 px-4'}
        footerComp={() => (
          <Button
            className="!m-0 float-end !p-0 !font-segoe-ui !text-xs !font-semibold"
            type="link"
            icon={<DownloadOutlined />}
            onClick={() => handleDownloadAllActivity(activeProject, 'Project')}
            loading={downloadingId}
          >
            Download All
          </Button>
        )}
      />
    </div>
  );
}
