import Modal from '@common/Modal';
import { Collapse } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import AuditTrailsTable from './AuditTrailsTable';
import { selectActivitySlice } from '@features/activity/redux/slice';

export default function AuditTrails({ isOpen, onClose, activityList }) {
  const dispatch = useDispatch();
  const { auditTrailsLoading, auditTrailsData } =
    useSelector(selectActivitySlice);

  // transforming activity trail if activity is of partner
  const trails = auditTrailsData?.length
    ? auditTrailsData?.reduce((acc, item) => {
        if (!acc[item.activity_id]) {
          acc[item.activity_id] = [];
        }
        acc[item.activity_id].push(item);
        return acc;
      }, {})
    : {};

  //rendering audit trails for partner
  const renderedPartnerAuditTrails = Object.entries(trails)?.map(
    ([id, trail]) => {
      return (
        <Collapse
          key={id}
          className="!bg-[#F9FAFB] !font-segoe-ui !text-md"
          expandIconPosition="end"
          items={[
            {
              key: '1',
              label:
                activityList.find((act) => act.id === Number(id))
                  ?.activity_description || 'NA',
              children: (
                <AuditTrailsTable data={trail} loading={auditTrailsLoading} />
              ),
              classNames: {
                body: 'border border-gray-200 !p-0 hide-footer',
                header: '!p-3',
              },
            },
          ]}
          bordered={false}
          defaultActiveKey={['1']}
        />
      );
    },
  );

  return (
    <Modal
      footerComponent={' '}
      btnLoading={auditTrailsLoading}
      headerTitle="Audit Trail"
      isOpen={Boolean(isOpen?.id)}
      onClose={onClose}
      spanWidth={'!w-[90vw] md:!w-[80vw] lg:!w-[75vw]'}
      centered
    >
      {isOpen?.isSingleActivity ? (
        <Collapse
          className="!bg-[#F9FAFB] !font-segoe-ui !text-md"
          expandIconPosition="end"
          items={[
            {
              key: '1',
              label:
                activityList.find((act) => act.id === isOpen?.id)
                  ?.activity_description || 'NA',
              children: (
                <AuditTrailsTable
                  data={auditTrailsData}
                  loading={auditTrailsLoading}
                />
              ),
              classNames: {
                body: 'border border-gray-200 !p-0 hide-footer',
                header: '!p-3',
              },
            },
          ]}
          bordered={false}
          defaultActiveKey={['1']}
        />
      ) : (
        <div className="flex flex-col gap-4">{renderedPartnerAuditTrails}</div>
      )}
    </Modal>
  );
}
