import Card from '@common/Card';
import FormInput from '@common/FormInput';
import { Col, Form, message, Row, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import Label from '@common/Label';
import {
  getPartnerAddresses,
  selectMastersSlice,
} from '@features/masterRedux/slice';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@common/IconButton';
import plusCirleIcon from '@assets/icons/input/plus_circle.svg';
import plusCirleActiveIcon from '@assets/icons/input/plus_circle_active.svg';
import personPlusIcon from '@assets/icons/input/person_plus.svg';
import personPlusActiveIcon from '@assets/icons/input/person_plus_active.svg';
import {
  createPartnerUser,
  selectPartnerSlice,
} from '@features/partner/redux/slice';
import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { openNotification } from '@utils/notification';
import deleteIcon from '@assets/icons/input/delete.svg';
import deleteActiveIcon from '@assets/icons/input/delete_active.svg';
import ShortCloseModal from './ShortCloseModal';
import { selectProjectSlice } from '../redux/slice';
import { useParams } from 'react-router-dom';

const PartnerUserDetails = ({
  selectedPartnerUser,
  currentSelectedPartnerUsers,
  setCurrentSelectedPartnerUsers,
  partners,
  setPartners,
  selectedPartner,
  isEdit,
  isAddPartnerUserFormOpen,
  setIsAddPartnerUserFormOpen,
  partnerUsersList,
  activePartnerType,
  getProjectDetailsOnLoad,
  loadPartnerDetailsForm,
  partnerTypes,
  parentForm,
  setActivePartnerType,
}) => {
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);

  const { reAssignPartnerLoading, projectDetailsData } =
    useSelector(selectProjectSlice);
  const { projectId } = useParams();
  
  //handle add new partner user
  const handleAddNewPartnerUser = () => {
    if (isAddPartnerUserFormOpen) return;
    setIsAddPartnerUserFormOpen(true);
  };

  //handle reassign on partner user
  const handleOnReassignPartnerUser = () => {
    setIsReassignModalOpen(false);
    getProjectDetailsOnLoad(projectId);
    setActivePartnerType(activePartnerType + 77);
    // if (partnerTypes && activePartnerType) {
    //   const foundPartnerType = partnerTypes.find(
    //     (type) => type.type_id === activePartnerType,
    //   );
    //   if (foundPartnerType && foundPartnerType.partners) {
    //     loadPartnerDetailsForm(foundPartnerType.partners[0]);
    //   }
    // }
  };

  //handling removing partner user from selected partner users
  const handleRemovePartnerUser = () => {
    const foundUser = currentSelectedPartnerUsers.find(
      (user) => user.id === selectedPartnerUser.id,
    );

    if (foundUser) {
      //when is in edit and deactivating the user
      //checking if partner user is newly added or is old
      if (
        isEdit &&
        partners[selectedPartner]?.partner_users?.find(
          (user) =>
            user.partner_user_id === selectedPartnerUser.id && !user.is_new,
        )
      ) {
        setIsReassignModalOpen(true);

        // const isOtherPartnerUsersDeactivated = partners[
        //   selectedPartner
        // ].partner_users
        //   ?.filter((part) => part.partner_user_id !== selectedPartnerUser.id)
        //   .every((part) => !part.is_active);

        // if (isOtherPartnerUsersDeactivated) {
        //   message.error('Please select a partner user');
        //   return;
        // }

        // //confirmation dialog
        // openNotification({
        //   onApprove: () => {
        //     setCurrentSelectedPartnerUsers((prevUsers) => {
        //       const updatedList = [...prevUsers].map((user) =>
        //         user.id === foundUser.id
        //           ? {
        //               ...user,
        //               is_active:
        //                 user.is_active === false
        //                   ? true
        //                   : !user.is_active
        //                     ? false
        //                     : false,
        //             }
        //           : user,
        //       );
        //       return updatedList;
        //     });

        //     const partnerUsers = partners[selectedPartner].partner_users.map(
        //       (p) =>
        //         p.partner_user_id === selectedPartnerUser.id
        //           ? {
        //               ...p,
        //               is_active:
        //                 p.is_active === false
        //                   ? true
        //                   : !p.is_active
        //                     ? false
        //                     : false,
        //             }
        //           : p,
        //     );

        //     setPartners((prevPartner) => {
        //       const updatedPartner = [...prevPartner];
        //       updatedPartner[selectedPartner] = {
        //         ...updatedPartner[selectedPartner],
        //         partner_users: partnerUsers,
        //       };
        //       return updatedPartner;
        //     });
        //   },
        //   title: `${foundUser.is_active ? 'Deactivate' : 'Re-activate'} Partner user`,
        //   subTitle: `Are you sure you want to ${foundUser?.is_active ? 'deactivate' : 're-activate'} this partner user?`,
        //   approveBtnClasses: `w-full !py-4.5 ${foundUser?.is_active ? 'bg-primary-red hover:!border-primary-red hover:!text-primary-red' : 'bg-primary-green hover:!border-primary-green hover:!text-primary-green'} text-secondary-bg hover:!bg-transparent  hover:!border`,
        //   cancelBtnClasses: 'w-full !py-4.5',
        //   approveText: foundUser.is_active ? 'Deactivate' : 'Re-activate',
        //   cancelText: 'Cancel',
        // });

        //when is in create and deleting the user
      } else {
        setCurrentSelectedPartnerUsers((prevUsers) => {
          const updatedList = [...prevUsers].filter(
            (user) => user.id !== foundUser.id,
          );
          return updatedList;
        });

        const partnerUsers = partners[selectedPartner].partner_users.filter(
          (p) => p.partner_user_id !== selectedPartnerUser.id,
        );

        setPartners((prevPartner) => {
          const updatedPartner = [...prevPartner];
          updatedPartner[selectedPartner] = {
            ...updatedPartner[selectedPartner],
            partner_users: partnerUsers,
          };
          return updatedPartner;
        });
        parentForm.resetFields(['partner_user_name']);
      }
    }
  };

  const containerClasses =
    'flex my-1 md:m-0 flex-row md:flex-col items-end md:items-start w-full gap-1';
  const labelClasses =
    'font-segoe-ui flex-shrink-0 w-[35%] md:w-full text-xs pr-4 text-tertiary-placeholder';
  const descriptionClasses = `font-segoe-ui text-xs md:text-sm ${!isEdit && 'text-tertiary-text'} ${
    isEdit &&
    projectDetailsData?.Projects_Partners_Mappings.find(
      (p) => p.contact.id === selectedPartnerUser.id,
    )?.is_active !== false
      ? 'text-tertiary-text'
      : !projectDetailsData?.Projects_Partners_Mappings.find(
            (p) => p.contact.id === selectedPartnerUser.id,
          )?.is_active
        ? 'text-primary-placeholder'
        : ''
  }`;

  return (
    <Row gutter={24} className="relative">
      <Col span={24} md={{ span: 7 }}>
        <Label
          label={'Contact Name:'}
          description={selectedPartnerUser?.contact_name}
          containerClasses={containerClasses}
          labelClasses={labelClasses}
          descriptionClasses={descriptionClasses}
        />
      </Col>
      <Col span={24} md={{ span: 7 }}>
        <Label
          label={'Phone Number:'}
          description={selectedPartnerUser?.phone_number || '-'}
          containerClasses={containerClasses}
          labelClasses={labelClasses}
          descriptionClasses={descriptionClasses}
        />
      </Col>
      <Col span={24} md={{ span: 8 }}>
        <Label
          label={'Email ID:'}
          description={selectedPartnerUser?.email_id}
          containerClasses={containerClasses}
          labelClasses={labelClasses}
          descriptionClasses={descriptionClasses}
        />
      </Col>
      <Col
        span={24}
        md={{ span: 2 }}
        lg={{ span: 2 }}
        className="absolute top-0 right-0 flex gap-2 md:realtive"
      >
        {/* {isEdit && partners[selectedPartner].partner && (
          <IconButton
            src={plusCirleIcon}
            srcActive={plusCirleActiveIcon}
            className={'w-6 md:mt-2'}
            onClick={handleAddNewPartnerUser}
          />
        )} */}
        {isEdit &&
        partners[selectedPartner]?.partner_users?.find(
          (user) =>
            user.partner_user_id === selectedPartnerUser.id && !user.is_new,
        ) &&
        projectDetailsData?.Projects_Partners_Mappings.find(
          (p) => p.contact.id === selectedPartnerUser.id,
        )?.is_active === true ? (
          <IconButton
            src={personPlusIcon}
            srcActive={personPlusActiveIcon}
            className={'w-6 md:mt-2'}
            onClick={handleRemovePartnerUser}
            tooltipName={'Reassign partner user'}
          />
        ) : projectDetailsData?.Projects_Partners_Mappings.find(
            (p) => p.contact.id === selectedPartnerUser.id,
          )?.is_active === false ? null : (
          <IconButton
            src={deleteIcon}
            srcActive={deleteActiveIcon}
            className={'w-5 md:mt-2'}
            onClick={handleRemovePartnerUser}
            disabled={partners[selectedPartner].partner_users
              ?.filter(
                (part) => part.partner_user_id !== selectedPartnerUser.id,
              )
              .every((part) => !part.is_active)}
            tooltipName={'Delete partner user'}
          />
        )}
      </Col>

      {/* //short close modal */}
      <ShortCloseModal
        selectedPartnerUser={selectedPartnerUser}
        isOpen={isReassignModalOpen}
        onClose={() => setIsReassignModalOpen(false)}
        onReassign={handleOnReassignPartnerUser}
        loading={reAssignPartnerLoading}
        partnerUsersList={partnerUsersList}
        activePartnerType={activePartnerType}
        currentSelectedPartnerUsers={currentSelectedPartnerUsers}
        projectDetailsData={projectDetailsData}
      />
    </Row>
  );
};

export default function PartnerDetails({
  partners,
  setPartners,
  selectedPartner,
  partnerTypes,
  setPartnerTypes,
  activePartnerType,
  setActivePartnerType,
  setSelectedPartner,
  currentSelectedPartnerUsers,
  setCurrentSelectedPartnerUsers,
  isPartnerUserNameFillded,
  setIsPartnerUserNameFillded,
  isPartnerAddressFillded,
  setIsPartnerAddressFillded,
  isPartnerNameFilled,
  setIsPartnerNameFilled,
  isEdit,
  getProjectDetailsOnLoad,
}) {
  const dispatch = useDispatch();
  const {
    partnersLoading,
    partnersList,
    partnerAddressesLoading,
    partnerAddressesList,
  } = useSelector(selectMastersSlice);
  const { createPartnerUserLoading } = useSelector(selectPartnerSlice);

  const [form] = Form.useForm();
  //handle craete partner form finish
  const handleOnSubmit = (values) => {};

  //partner address and partner users state
  const [currentPartnerAddresses, setCurrentPartnerAddresses] = useState([]);
  const [currentAddressPartnerUsers, setCurrentAddressPartnerUsers] = useState(
    [],
  );

  const [isNewEmailFieldEnabled, setIsNewEmailFieldEnabled] = useState(false);

  //fields which filled to handle disable or hidden state
  const [isAddPartnerUserFormOpen, setIsAddPartnerUserFormOpen] =
    useState(false);

  //handle creating partner user in partner
  const handleCreatePartnerUser = () => {
    const values = form.getFieldsValue();

    const enteredPartnerUser = {
      contact_name: values.contact_name,
      email_id: values.email_id,
      phone_number: values.phone_number,
    };

    const payload = {
      contact_name: values.contact_name,
      email_id: values.email_id,
      phone_number: values.phone_number,
      partner_id: values.partner_name || partners[selectedPartner].partner_id,
      address_id:
        values.partner_address || partners[selectedPartner].partner_address_id,
    };
    dispatch(createPartnerUser(payload)).then((res) => {
      if (res?.payload?.success) {
        setIsAddPartnerUserFormOpen(false);
        form.resetFields(['contact_name', 'phone_number', 'email_id']);
        dispatch(getPartnerAddresses(payload.partner_id)).then((res) => {
          if (res?.payload?.status) {
            const data = res.payload.data;
            setCurrentPartnerAddresses(data?.PartnerAddresses);

            const foundAddress = data?.PartnerAddresses?.find(
              (address) => address.id === payload.address_id,
            );

            if (foundAddress) {
              setCurrentAddressPartnerUsers(foundAddress.contacts);
            }

            // if (isEdit && partners[selectedPartner].partner) {
            //   const selectedPartnerUser = foundAddress.contacts.find(
            //     (user) =>
            //       user.contact_name === enteredPartnerUser.contact_name &&
            //       user.phone_number === enteredPartnerUser.phone_number &&
            //       user.email_id === enteredPartnerUser.email_id,
            //   );

            //   if (
            //     selectedPartnerUser &&
            //     !currentSelectedPartnerUsers?.some(
            //       (user) => user.id === selectedPartnerUser.id,
            //     )
            //   ) {
            //     setIsPartnerNameFilled(true);
            //     setIsPartnerAddressFillded(true);
            //     setIsPartnerUserNameFillded(true);
            //     setPartners((prevPartner) => {
            //       const updatedPartner = [...prevPartner];
            //       updatedPartner[selectedPartner] = {
            //         ...updatedPartner[selectedPartner],
            //         partner_users: [
            //           ...currentSelectedPartnerUsers,
            //           selectedPartnerUser,
            //         ].map((user) => ({
            //           partner_user_id: user.id,
            //           is_active: true,
            //         })),
            //       };
            //       return updatedPartner;
            //     });
            //     setCurrentSelectedPartnerUsers([
            //       ...currentSelectedPartnerUsers,
            //       selectedPartnerUser,
            //     ]);
            //   }
            //   //////////////////
            // }
          }
        });
      }
    });
  };

  //structoring the partner addresses state on for values change
  const handleFormValuesChange = (changedVal, values) => {
    //CALLING PARTNER ADDRESSES API
    if (changedVal.partner_name) {
      dispatch(getPartnerAddresses(values.partner_name)).then((res) => {
        form.resetFields(['partner_address', 'partner_user_name']);
        setIsPartnerNameFilled(true);
        setIsPartnerAddressFillded(false);
        setIsPartnerUserNameFillded(false);

        setPartners((prevPartner) => {
          const updatedPartner = [...prevPartner];
          updatedPartner[selectedPartner] = {
            ...updatedPartner[selectedPartner],
            partner_id: values.partner_name,
            partner_address_id: null,
            partner_users: null,
          };
          return updatedPartner;
        });
        setCurrentSelectedPartnerUsers([]);
      });
    }

    //upadting current address users state
    if (changedVal.partner_address) {
      const selectedAddress = currentPartnerAddresses?.find(
        (address) => address.id === values.partner_address,
      );
      if (selectedAddress) {
        form.resetFields(['partner_user_name']);
        setIsPartnerNameFilled(true);
        setIsPartnerAddressFillded(true);
        setIsPartnerUserNameFillded(false);
        setPartners((prevPartner) => {
          const updatedPartner = [...prevPartner];
          updatedPartner[selectedPartner] = {
            ...updatedPartner[selectedPartner],
            partner_id: values.partner_name,
            partner_address_id: values.partner_address,
            partner_users: null,
          };
          return updatedPartner;
        });
        setCurrentAddressPartnerUsers(selectedAddress?.contacts);
        setCurrentSelectedPartnerUsers([]);
      }
    }

    //upadting current selected partner user state
    if (changedVal.partner_user_name) {
      const selectedPartnerUser = currentAddressPartnerUsers.find(
        (user) => user.id === values.partner_user_name,
      );

      if (
        currentSelectedPartnerUsers?.some(
          (user) => user.id === selectedPartnerUser.id,
        )
      ) {
        message.error('This partner user already selected');
        return;
      }

      const updatedSelectedPartnerUsers = currentSelectedPartnerUsers.map(
        (user) => {
          if (
            partners[selectedPartner]?.partner_users?.some(
              (p) => p.partner_user_id === user.id && p.is_new,
            )
          ) {
            return { ...user, is_new: true };
          } else {
            return user;
          }
        },
      );

      if (
        selectedPartnerUser &&
        !currentSelectedPartnerUsers?.some(
          (user) => user.id === selectedPartnerUser.id,
        )
      ) {
        setIsPartnerNameFilled(true);
        setIsPartnerAddressFillded(true);
        setIsPartnerUserNameFillded(true);
        setPartners((prevPartner) => {
          const updatedPartner = [...prevPartner];
          updatedPartner[selectedPartner] = {
            ...updatedPartner[selectedPartner],
            partner_id:
              isEdit && partners[selectedPartner].partner
                ? partners[selectedPartner].partner_id
                : values.partner_name,
            partner_address_id:
              isEdit && partners[selectedPartner].partner
                ? partners[selectedPartner].partner_address_id
                : values.partner_address,
            partner_users: [
              ...updatedSelectedPartnerUsers,
              { ...selectedPartnerUser, is_new: true },
            ].map((user) => {
              return user.is_new && isEdit
                ? {
                    partner_user_id: user.id,
                    is_active: user.is_active,
                    is_new: user.is_new,
                  }
                : {
                    partner_user_id: user.id,
                    is_active: user.is_active,
                  };
            }),
          };
          return updatedPartner;
        });

        setCurrentSelectedPartnerUsers([
          ...updatedSelectedPartnerUsers,
          { ...selectedPartnerUser, is_new: true },
        ]);
      }
    }
  };

  //transforming partners list
  const transformedPartnersList = partnersList?.map((pa) => {
    return { label: pa.partner_company_name, value: pa.id };
  });

  //transforming partner addresses list
  const transformedPartnerAddressesList = currentPartnerAddresses
    ?.filter((addr) => addr.is_active)
    ?.map((pa) => {
      return { label: pa.address, value: pa.id };
    });

  //transforming partner users list
  const transformedPartnerUsersList = currentAddressPartnerUsers
    ?.filter((pu) => pu.is_active)
    ?.map((pa) => {
      return { label: pa.contact_name, value: pa.id };
    });

  //handle change partner email
  const handleChangePartnerEmail = () => {
    form
      .validateFields()
      .then((values) => {
        setPartners((prevPartner) => {
          const updatedPartner = [...prevPartner];
          updatedPartner[selectedPartner] = {
            ...updatedPartner[selectedPartner],
            new_partner_email_id: values.new_partner_email_id,
          };

          //if want to update every other partner in the same type
          // updatedPartner.forEach((partner) =>
          //   partner.partner_id === updatedPartner[selectedPartner].partner_id
          //     ? { ...partner, new_partner_email_id: values.new_partner_email_id }
          //     : partner,
          // );
          return updatedPartner;
        });

        setIsNewEmailFieldEnabled(false);
      })
      .catch(() => {});
  };

  //loading selected partner to fields (reseting partner form)
  const loadPartnerDetailsForm = (data) => {
    const { partner_id, partner_address_id, partner_users } = data;
    setIsNewEmailFieldEnabled(false);
    if (partner_id) {
      setIsPartnerNameFilled(true);

      form.setFieldsValue({
        partner_name: partner_id,
      });

      if (data?.partner && isEdit) {
        form.setFieldsValue({
          new_partner_email_id: data?.new_partner_email_id
            ? data?.new_partner_email_id
            : data?.partner.partner_email,
        });
      }

      dispatch(getPartnerAddresses(partner_id)).then((res) => {
        if (res?.payload?.status) {
          const data = res.payload.data;
          setCurrentPartnerAddresses(data?.PartnerAddresses);
          if (partner_address_id) {
            setIsPartnerAddressFillded(true);

            form.setFieldsValue({
              partner_address: partner_address_id,
            });

            const foundAddress = data?.PartnerAddresses?.find(
              (address) => address.id === partner_address_id,
            );

            if (foundAddress) {
              const foundSelectedPartnerUsers = foundAddress.contacts?.filter(
                (cont) =>
                  partner_users?.some((p) => p.partner_user_id === cont.id),
              );
              setCurrentAddressPartnerUsers(foundAddress.contacts);

              if (partner_users && partner_users.length) {
                setIsPartnerUserNameFillded(true);

                form.setFieldsValue({
                  partner_user_name: partner_users[0].partner_user_id,
                });

                if (foundAddress.contacts) {
                  setCurrentSelectedPartnerUsers(foundSelectedPartnerUsers);
                }
              } else {
                form.setFieldsValue({
                  partner_user_name: null,
                });
                setIsPartnerUserNameFillded(false);
                setIsAddPartnerUserFormOpen(false);
              }
            }
          } else {
            form.setFieldsValue({
              partner_address: null,
              partner_user_name: null,
            });
            setIsPartnerAddressFillded(false);
            setIsPartnerUserNameFillded(false);
            setIsAddPartnerUserFormOpen(false);
          }
        }
      });
    } else {
      form.setFieldsValue({
        partner_name: null,
        partner_address: null,
        partner_user_name: null,
      });
      setIsPartnerNameFilled(false);
      setIsPartnerAddressFillded(false);
      setIsPartnerUserNameFillded(false);
      setIsAddPartnerUserFormOpen(false);
    }
  };

  //loading partner details
  useEffect(() => {
    loadPartnerDetailsForm(partners[selectedPartner]);
  }, [selectedPartner]);

  //updating current partner addresses state
  useEffect(() => {
    setCurrentPartnerAddresses(partnerAddressesList?.PartnerAddresses);
  }, [partnerAddressesList]);

  //load partners on partner types change
  useEffect(() => {
    if (partnerTypes && activePartnerType) {
      const foundPartnerType = partnerTypes.find(
        (type) => type.type_id === activePartnerType,
      );
      if (foundPartnerType && foundPartnerType.partners) {
        setPartners(foundPartnerType.partners);
        loadPartnerDetailsForm(foundPartnerType.partners[0]);
        setSelectedPartner(0);
      } else {
        form.resetFields();
        setPartners([
          {
            partner_id: null,
            partner_users: null,
            partner_address_id: null,
          },
        ]);
        setSelectedPartner(0);
      }
    }
  }, [activePartnerType]);

  return (
    <Form
      form={form}
      layout="vertical"
      id="partner-details-form"
      //   onFinish={handleOnSubmit}
      onValuesChange={handleFormValuesChange}
      className="flex flex-col gap-4"
    >
      {partners[selectedPartner].partner && isEdit ? (
        <div className="flex flex-col gap-5">
          <p className="flex flex-col gap-2 text-tertiary-text font-segoe-ui">
            <span className="text-sm">Partner Address</span>
            <span className="text-xs capitalize text-tertiary-placeholder">
              {
                transformedPartnerAddressesList?.find(
                  (address) =>
                    address.value ===
                    partners[selectedPartner].partner_address_id,
                )?.label
              }
            </span>
          </p>
          <Row gutter={10}>
            <Col span={22} lg={{ span: 7 }}>
              <FormInput
                label={'Partner Email Id'}
                name={'new_partner_email_id'}
                disabled={!isNewEmailFieldEnabled}
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid email',
                  },
                  {
                    required: true,
                    message: 'Email is required',
                  },
                ]}
                hideColon
              />
            </Col>
            {/* <Col span={2} lg={{ span: 1 }}>
              <FormInput
                type="placeholder"
                label={'Action'}
                hideLabel
                placeholderComponent={
                  isNewEmailFieldEnabled ? (
                    <Tooltip title="Save">
                      <CheckOutlined
                        onClick={handleChangePartnerEmail}
                        className="cursor-pointer"
                      />
                    </Tooltip>
                  ) : (
                    <IconButton
                      src={plusCirleIcon}
                      srcActive={plusCirleActiveIcon}
                      className={'w-6'}
                      tooltipName={'Change partner email id'}
                      onClick={() => setIsNewEmailFieldEnabled(true)}
                    />
                  )
                }
              />
            </Col> */}
            <Col
              hidden={!isPartnerAddressFillded || partnerAddressesLoading}
              span={22}
              lg={{ span: 7 }}
            >
              <FormInput
                label={'Partner User Name'}
                name={'partner_user_name'}
                type="select"
                placeholder="Select partner user name"
                options={transformedPartnerUsersList}
                optionRender={(option) => {
                  return (
                    <span className="text-xs font-segoe-ui text-tertiary-text">
                      {option.label}
                    </span>
                  );
                }}
                loading={partnerAddressesLoading}
              />
            </Col>
            {/* <Col
              hidden={!isPartnerAddressFillded || partnerAddressesLoading}
              span={2}
              lg={{ span: 1 }}
            >
              <FormInput
                label={'Action'}
                hideLabel
                type="placeholder"
                placeholderComponent={
                  <IconButton
                    onClick={() => setIsAddPartnerUserFormOpen(true)}
                    src={plusCirleIcon}
                    srcActive={plusCirleActiveIcon}
                    disabled={isAddPartnerUserFormOpen}
                    tooltipName={'Add New Partner User'}
                  />
                }
              />
            </Col> */}
          </Row>
        </div>
      ) : (
        <Row gutter={24}>
          <Col span={24} lg={{ span: 7 }}>
            <FormInput
              label={'Partner Company Name'}
              name={'partner_name'}
              type="select"
              placeholder="Select partner name"
              options={transformedPartnersList}
              optionRender={(option) => {
                return (
                  <span className="text-xs font-segoe-ui text-tertiary-text">
                    {option.label}
                  </span>
                );
              }}
              loading={partnersLoading}
            />
          </Col>
          <Col span={24} lg={{ span: 7 }}>
            <FormInput
              disabled={!isPartnerNameFilled || partnerAddressesLoading}
              label={'Partner Address'}
              name={'partner_address'}
              type="select"
              placeholder="Select partner address"
              options={transformedPartnerAddressesList}
              optionRender={(option) => {
                return (
                  <span className="text-xs font-segoe-ui text-tertiary-text">
                    {option.label}
                  </span>
                );
              }}
              loading={partnerAddressesLoading}
            />
          </Col>
          <Col
            hidden={!isPartnerAddressFillded || partnerAddressesLoading}
            span={24}
            lg={{ span: 7 }}
          >
            <FormInput
              label={'Partner User Name'}
              name={'partner_user_name'}
              type="select"
              placeholder="Select partner user name"
              options={transformedPartnerUsersList}
              optionRender={(option) => {
                return (
                  <span className="text-xs font-segoe-ui text-tertiary-text">
                    {option.label}
                  </span>
                );
              }}
              loading={partnerAddressesLoading}
            />
          </Col>
          {/* <Col
            hidden={!isPartnerAddressFillded || partnerAddressesLoading}
            span={24}
            lg={{ span: 3 }}
          >
            <FormInput
              label={'Action'}
              hideLabel
              type="placeholder"
              placeholderComponent={
                <IconButton
                  onClick={() => setIsAddPartnerUserFormOpen(true)}
                  src={plusCirleIcon}
                  srcActive={plusCirleActiveIcon}
                  disabled={isAddPartnerUserFormOpen}
                  tooltipName={'Add New Partner User'}
                />
              }
            />
          </Col> */}
        </Row>
      )}
      {isAddPartnerUserFormOpen && (
        <Row gutter={16}>
          <Col span={24} lg={{ span: 7 }}>
            <FormInput
              label={'Contact Name'}
              name={'contact_name'}
              placeholder="Enter contact name"
            />
          </Col>
          <Col span={24} lg={{ span: 7 }}>
            <FormInput
              label={'Phone Number'}
              name={'phone_number'}
              placeholder="Enter phone number"
            />
          </Col>
          <Col span={24} lg={{ span: 8 }}>
            <FormInput
              label={'Email ID'}
              name={'email_id'}
              placeholder="Enter email id"
            />
          </Col>
          <Col
            span={24}
            lg={{ span: 2 }}
            className="flex items-center gap-2 mt-6"
          >
            <IconButton
              src={plusCirleIcon}
              srcActive={plusCirleActiveIcon}
              className={'w-5'}
              onClick={handleCreatePartnerUser}
              loading={createPartnerUserLoading}
              tooltipName={'Add Partner User'}
            />

            <Tooltip title="Cancel">
              <CloseCircleOutlined
                onClick={() => {
                  setIsAddPartnerUserFormOpen(false);
                  form.resetFields([
                    'contact_name',
                    'phone_number',
                    'email_id',
                  ]);
                }}
                className="text-lg text-tertiary-placeholder hover:!text-primary-bg"
              />
            </Tooltip>
          </Col>
        </Row>
      )}
      {currentSelectedPartnerUsers.map((user, i) => (
        <Card
          hidden={!isPartnerUserNameFillded || partnerAddressesLoading}
          className={'py-2.5 px-5 bg-secondary-bg'}
          key={user.id}
        >
          <PartnerUserDetails
            loadPartnerDetailsForm={loadPartnerDetailsForm}
            partners={partners}
            setPartners={setPartners}
            selectedPartner={selectedPartner}
            selectedPartnerUser={user}
            currentSelectedPartnerUsers={currentSelectedPartnerUsers}
            setCurrentSelectedPartnerUsers={setCurrentSelectedPartnerUsers}
            isEdit={isEdit}
            isAddPartnerUserFormOpen={isAddPartnerUserFormOpen}
            setIsAddPartnerUserFormOpen={setIsAddPartnerUserFormOpen}
            partnerUsersList={currentAddressPartnerUsers}
            activePartnerType={activePartnerType}
            setActivePartnerType={setActivePartnerType}
            getProjectDetailsOnLoad={getProjectDetailsOnLoad}
            partnerTypes={partnerTypes}
            parentForm={form}
          />
        </Card>
      ))}
    </Form>
  );
}
