import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  checkDupPartnerUser,
  partnerCreate,
  partnerDeactivate,
  partnersWithPartnerUsers,
  partnerUserCreate,
  projectsByPartnerId,
} from './api';

const actions = {
  CREATE_PARTNER: 'partner/CREATE_PARTNER',
  PROJECT_BY_PARTNER: 'partner/PROJECT_BY_PARTNER',
  CREATE_PARTNER_USER: 'partner/CREATE_PARTNER_USER',
  PARTNERS_WITH_PARTNER_USERS: 'partner/PARTNERS_WITH_PARTNER_USERS',
  DEACTIVATE_PARTNER: 'partner/DEACTIVATE_PARTNER',
  CHECK_DUP_PARTNER_USER_EMAIL: "partner/CHECK_DUP_PARTNER_USER_EMAIL"
};

export const createPartner = createAsyncThunk(
  actions.CREATE_PARTNER,
  async (payload) => {
    const response = await partnerCreate(payload);
    return response;
  },
);

export const getProjectsByParterId = createAsyncThunk(
  actions.PROJECT_BY_PARTNER,
  async (payload) => {
    const response = await projectsByPartnerId(payload);
    return response;
  },
);

export const createPartnerUser = createAsyncThunk(
  actions.CREATE_PARTNER_USER,
  async (payload) => {
    const response = await partnerUserCreate(payload);
    return response;
  },
);

export const getPartnersWithPartnerUsers = createAsyncThunk(
  actions.PARTNERS_WITH_PARTNER_USERS,
  async (payload) => {
    const response = await partnersWithPartnerUsers(payload);
    return response;
  },
);

export const deactivatePartner = createAsyncThunk(
  actions.DEACTIVATE_PARTNER,
  async (payload) => {
    const response = await partnerDeactivate(payload);
    return response;
  },
);


export const checkDupPartnerUserEmail = createAsyncThunk(
  actions.CHECK_DUP_PARTNER_USER_EMAIL,
  async (payload) => {
    const response = await checkDupPartnerUser(payload);
    return response;
  },
);

const initialState = {
  createPartnerLoading: false,
  projectsByPartner: [],
  projectsByPartnerLoading: false,
  pagination: null,
  createPartnerUserLoading: false,
  partnersWithPartnerUsers: [],
  partnersWithPartnerUsersLoading: false,
  deactivatePartnerLoading: false,
  checkDupPartnerUserEmailLoading: false,
  partnerUserEmailAlreadyExist: false,
};

export const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create partner
    builder
      .addCase(createPartner.pending, (state) => {
        state.createPartnerLoading = true;
      })
      .addCase(createPartner.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.createPartnerLoading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createPartner.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.createPartnerLoading = false;
        message.error(errorMsg);
      });

    // get partners with partner users
    builder
      .addCase(getPartnersWithPartnerUsers.pending, (state) => {
        state.partnersWithPartnerUsersLoading = true;
      })
      .addCase(getPartnersWithPartnerUsers.fulfilled, (state, action) => {
        const { success, message: msg, data, pagination } = action.payload;
        state.partnersWithPartnerUsersLoading = false;

        if (success) {
          state.partnersWithPartnerUsers = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })
      .addCase(getPartnersWithPartnerUsers.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.partnersWithPartnerUsersLoading = false;
        message.error(errorMsg);
      });

    // projects by partner
    builder
      .addCase(getProjectsByParterId.pending, (state) => {
        state.projectsByPartnerLoading = true;
      })
      .addCase(getProjectsByParterId.fulfilled, (state, action) => {
        const { success, message: msg, data, pagination } = action.payload;
        state.projectsByPartnerLoading = false;

        if (success) {
          state.projectsByPartner = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })
      .addCase(getProjectsByParterId.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.projectsByPartnerLoading = false;
        message.error(errorMsg);
      });

    //create partner user
    builder
      .addCase(createPartnerUser.pending, (state) => {
        state.createPartnerUserLoading = true;
      })
      .addCase(createPartnerUser.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.createPartnerUserLoading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createPartnerUser.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.createPartnerUserLoading = false;
        message.error(errorMsg);
      });

    //deactivate a partner
    builder
      .addCase(deactivatePartner.pending, (state) => {
        state.deactivatePartnerLoading = true;
      })
      .addCase(deactivatePartner.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.deactivatePartnerLoading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(deactivatePartner.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.deactivatePartnerLoading = false;
        message.error(errorMsg);
      });

    // check duplicate partner user email
    builder
      .addCase(checkDupPartnerUserEmail.pending, (state) => {
        state.checkDupPartnerUserEmailLoading = true;
      })
      .addCase(checkDupPartnerUserEmail.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.checkDupPartnerUserEmailLoading = false;

        if (success) {
          state.partnerUserEmailAlreadyExist = false;
        } else {
          state.partnerUserEmailAlreadyExist = true;
        }
      })
      .addCase(checkDupPartnerUserEmail.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.checkDupPartnerUserEmailLoading = false;
        message.error(errorMsg);
      });
  },
});

export const selectPartnerSlice = (state) => {
  return state.partner;
};

export default partnerSlice.reducer;
