import otpImg from '@assets/images/otp.png';
import logoIcon from '@assets/icons/granules-logo.svg';
import emailIcon from '@assets/icons/input/email.svg';
import FormInput from '@common/FormInput';
import { Button, Form, Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, selectAuthSlice, sendOtpRequest } from '../redux/slice';
import Countdown from '@common/CountDown';
import moment from 'moment';
import CountdownBtn from '@common/CountdownBtn';

export default function Otp() {
  const [form] = Form.useForm();
  // state = email
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, accessToken, requestOtpLoading, requestOtpData } =
    useSelector(selectAuthSlice);
  const endTime = moment(requestOtpData?.otpExpiration);
  const startTime = moment(requestOtpData?.updatedAt);
  const [isOtpExpired, setIsOtpExpired] = useState(false);

  const onOtpExprire = (status) => setIsOtpExpired(status);

  //handle click on resend otp
  const handleResendOtp = (e) => {
    e?.preventDefault();
    dispatch(sendOtpRequest({ email: state })).then((res) => {
      if (res?.payload?.success) {
        setIsOtpExpired(false);
      }
    });
  };

  //handle click on login btn
  const handleLogin = (values) => {
    const payload = {
      otp: values.otp,
      email: state,
    };
    dispatch(loginUser(payload))
      .then((res) => {
        if (!res?.payload?.token) {
          form.resetFields();
        }
      })
      .catch((err) => form.resetFields());
  };

  //sending user to login screen if email id is not present in context state
  useEffect(() => {
    if (!state) {
      navigate('/login', { replace: true });
    }
  }, []);

  //redirecting after succefully logged in
  useEffect(() => {
    if (!loading && accessToken) {
      navigate('/', { replace: true });
    }
  }, [accessToken, navigate, loading]);

  return (
    <div className="flex flex-col justify-center items-center md:grid  md:grid-cols-[3fr_2fr] h-screen">
      {/* login image */}
      <img
        src={otpImg}
        alt="login image"
        className="hidden m-0 md:block md:mx-auto md:my-auto"
      />
      <img
        src={logoIcon}
        alt="granules logo"
        className="block w-32 mb-6 md:mb-0 sm:w-40 lg:w-auto md:hidden"
      />

      {/* login form */}
      <div className="flex flex-col items-center justify-between w-[90%] xl:w-3/4">
        <img
          src={otpImg}
          alt="login image"
          className="block m-0 md:mx-auto w-[80%] md:my-auto md:hidden"
        />
        <img
          src={logoIcon}
          alt="granules logo"
          className="hidden w-1/2 lg:w-44 md:block"
        />
        <div className="flex flex-col items-center gap-3 mt-6 mb-5">
          <h1 className="text-2xl font-semibold lg:mt-10 lg:text-3xl font-segoe-ui text-primary-bg">
            OTP Verification
          </h1>
          <p className="text-sm font-normal font-segoe-ui text-tertiary-placeholder">
            Enter the code sent to your email ID
          </p>
        </div>
        <Spin spinning={requestOtpLoading || loading}>
          <Form
            className="w-full flex flex-col items-center justify-center gap-3.5"
            onFinish={handleLogin}
            form={form}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !isOtpExpired && e.location === 0) {
                form.submit();
              }
            }}
          >
            <FormInput disabled={isOtpExpired} name="otp" type="otp" />
            {/* otp expiration countdown  */}
            {!isOtpExpired && (
              <Countdown
                startTime={startTime}
                endTime={endTime}
                onExpire={onOtpExprire}
              />
            )}
            <p className="text-sm font-normal text-center font-segoe-ui text-tertiary-placeholder">
              Didn’t get a code?{' '}
              <CountdownBtn
                onClick={handleResendOtp}
                className="underline cursor-pointer underline-offset-1 font-segoe-ui hover:text-primary-bg"
              />
              .
            </p>
            {isOtpExpired ? (
              <Button
                className="!m-0 font-segoe-ui !py-5 rounded-lg px-[18px] w-full bg-primary-bg text-white font-semibold !text-sm"
                loading={requestOtpLoading}
                onClick={handleResendOtp}
              >
                Request OTP
              </Button>
            ) : (
              <Button
                className="!m-0 font-segoe-ui !py-5 rounded-lg px-[18px] w-full bg-primary-bg text-white font-semibold !text-sm"
                loading={loading}
                disabled={isOtpExpired}
                onClick={() => form.submit()}
              >
                Continue
              </Button>
            )}
          </Form>
        </Spin>
      </div>
    </div>
  );
}
