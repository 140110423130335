import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  bottomPartners,
  leadingPartners,
  projectByDate,
  projectByMonth,
  projectDetails,
  projectsByPartnerId,
} from './api';

const actions = {
  PROJECT_BY_MONTH: 'admin/PROJECT_BY_MONTH',
  PROJECT_BY_DATE: 'admin/PROJECT_BY_DATE',
  LEADING_PARTNERS: 'admin/LEADING_PARTNERS',
  BOTTOM_PARTNERS: 'admin/BOTTOM_PARTNERS',
  TOP5_PROJECTS_BY_PARTNER: 'admin/TOP5_PROJECTS_BY_PARTNER',
  PROJECT_BY_ID: 'admin/PROJECT_BY_ID',
};

export const getProjectsByMonth = createAsyncThunk(
  actions.PROJECT_BY_MONTH,
  async (payload) => {
    const response = await projectByMonth(payload);
    return response;
  },
);

export const getProjectsByDate = createAsyncThunk(
  actions.PROJECT_BY_DATE,
  async (payload) => {
    const response = await projectByDate(payload);
    return response;
  },
);

export const getLeadingPartners = createAsyncThunk(
  actions.LEADING_PARTNERS,
  async (payload) => {
    const response = await leadingPartners(payload);
    return response;
  },
);

export const getBottomPartners = createAsyncThunk(
  actions.BOTTOM_PARTNERS,
  async (payload) => {
    const response = await bottomPartners(payload);
    return response;
  },
);

export const getTop5ProjectsByPartner = createAsyncThunk(
  actions.TOP5_PROJECTS_BY_PARTNER,
  async (payload) => {
    const response = await projectsByPartnerId(payload);
    return response;
  },
);

export const getProjectDetailsById = createAsyncThunk(
  actions.PROJECT_BY_ID,
  async (payload) => {
    const response = await projectDetails(payload);
    return response;
  },
);

const initialState = {
  projectsData: [],
  projectsDataLoading: false,
  leadingPartners: [],
  leadingPartnersLoading: false,
  bottomPartners: [],
  bottomPartnersLoading: false,
  projectsByPartner: [],
  projectsByPartnerLoading: false,
  projectDetailsLoading: false,
  projectDetails: null,
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get projects by month
    builder
      .addCase(getProjectsByMonth.pending, (state) => {
        state.projectsDataLoading = true;
      })
      .addCase(getProjectsByMonth.fulfilled, (state, action) => {
        const {
          status,
          message: msg,
          data,
          last_year_data,
          projects,
        } = action.payload;
        state.projectsDataLoading = false;

        if (status) {
          state.projectsData = { data, last_year_data, projects };
        } else {
          message.error(msg);
        }
      })
      .addCase(getProjectsByMonth.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.projectsDataLoading = false;
        message.error(errorMsg);
      });

    // get projects by date
    builder
      .addCase(getProjectsByDate.pending, (state) => {
        state.projectsDataLoading = true;
      })
      .addCase(getProjectsByDate.fulfilled, (state, action) => {
        const {
          status,
          message: msg,
          data,
          last_year_data,
          projects,
        } = action.payload;
        state.projectsDataLoading = false;

        if (status) {
          state.projectsData = { data, last_year_data, projects };
        } else {
          message.error(msg);
        }
      })
      .addCase(getProjectsByDate.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.projectsDataLoading = false;
        message.error(errorMsg);
      });

    // get leading partners
    builder
      .addCase(getLeadingPartners.pending, (state) => {
        state.leadingPartnersLoading = true;
      })
      .addCase(getLeadingPartners.fulfilled, (state, action) => {
        const { status, message: msg, data } = action.payload;
        state.leadingPartnersLoading = false;

        if (status) {
          state.leadingPartners = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getLeadingPartners.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.leadingPartnersLoading = false;
        message.error(errorMsg);
      });

    // get bottom partners
    builder
      .addCase(getBottomPartners.pending, (state) => {
        state.bottomPartnersLoading = true;
      })
      .addCase(getBottomPartners.fulfilled, (state, action) => {
        const { status, message: msg, data } = action.payload;
        state.bottomPartnersLoading = false;

        if (status) {
          state.bottomPartners = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getBottomPartners.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.bottomPartnersLoading = false;
        message.error(errorMsg);
      });

    // get top 5 projects by partner
    builder
      .addCase(getTop5ProjectsByPartner.pending, (state) => {
        state.projectsByPartnerLoading = true;
      })
      .addCase(getTop5ProjectsByPartner.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.projectsByPartnerLoading = false;

        if (success) {
          state.projectsByPartner = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getTop5ProjectsByPartner.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.projectsByPartnerLoading = false;
        message.error(errorMsg);
      });

    // get project details by id
    builder
      .addCase(getProjectDetailsById.pending, (state) => {
        state.projectDetailsLoading = true;
      })
      .addCase(getProjectDetailsById.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.projectDetailsLoading = false;

        if (success) {
          state.projectDetails = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getProjectDetailsById.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.projectDetailsLoading = false;
        message.error(errorMsg);
      });
  },
});

export const selectAdminSlice = (state) => {
  return state.admin;
};

export default adminSlice.reducer;
