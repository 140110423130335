import Card from '@common/Card';
import FormInput from '@common/FormInput';
import { useResponsiveness } from '@hooks/useResponsiveness';
import { Button, Col, Form, message, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import expandIcon from '@assets/icons/input/arrow_down.svg';
import IconButton from '@common/IconButton';
import deleteIcon from '@assets/icons/input/trashcan.svg';
import ContactListItem from './ContactListItem';
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { openNotification } from '@utils/notification';
import { useDispatch, useSelector } from 'react-redux';
import { checkDupPartnerUserEmail, selectPartnerSlice } from '../redux/slice';
import { debounce } from 'lodash';
import { PHONE_NUMBER_REGEX } from '@utils/helpers';

const PartnerDetailsForm = ({
  form,
  contacts,
  setContacts,
  selectedContact,
  setSelectedContact,
  parentForm,
  allContacts,
}) => {
  const { checkDupPartnerUserEmailLoading, partnerUserEmailAlreadyExist } =
    useSelector(selectPartnerSlice);
  const dispatch = useDispatch();

  //handle craete partner form finish
  const handleOnSubmit = (values) => {
    const isContactAlreadyExist = contacts.find(
      (cont) => cont.contact_name === values.contact_name,
    );
    if (isContactAlreadyExist) {
      message.error('Contact already exists by name ' + values.contact_name);
      return;
    }
    const isEmailAlreadyExist = allContacts.find(
      (cont) => cont.email_id === values.email_id,
    );
    if (isEmailAlreadyExist) {
      message.error('Email already in use');
      return;
    }
    setContacts([...contacts, { ...values }]);
    form.resetFields();
    setSelectedContact(null);
    parentForm.setFieldsValue({ partner_user: null });
  };

  //debouncing the partner user email search
  const debouncedCheckPartnerUserEmail = debounce((partner_user_email) => {
    dispatch(checkDupPartnerUserEmail({ partner_user_email }));
  }, 500);

  //structoring the partner addresses state on for values change
  const handleFormValuesChange = (changedVal, values) => {
    if (changedVal.email_id) {
      debouncedCheckPartnerUserEmail(values.email_id);
    }
  };

  //loading selected contact to fields
  useEffect(() => {
    if (selectedContact) {
      form.setFieldsValue(selectedContact);
    }
  }, [selectedContact]);

  //validating partner suer email for duplications
  const validatePartnerUserEmail = (_, value) => {
    if (partnerUserEmailAlreadyExist) {
      return Promise.reject(new Error('Email ID already exists'));
    }
    return Promise.resolve();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      id="partner-address-form"
      onFinish={handleOnSubmit}
      onValuesChange={handleFormValuesChange}
      className="flex flex-col gap-5 !ps-4 !pe-8 rounded-lg bg-tertiary-bg py-2 m-2"
    >
      <Row gutter={24}>
        <Col span={24} lg={{ span: 7 }}>
          <FormInput
            label={'Contact Name'}
            name={'contact_name'}
            placeholder="Enter contact name"
            labelClasess={'text-xs text-tertiary-text font-segoe-ui'}
            required
            validateMsg={'Contact name is required '}
          />
        </Col>
        <Col span={24} lg={{ span: 8 }}>
          <FormInput
            label={'Phone Number'}
            name={'phone_number'}
            placeholder="Enter phone number"
            labelClasess={'text-xs text-tertiary-text font-segoe-ui'}
            rules={[
              {
                required: true,
                message: 'Phone number is required',
              },
              {
                pattern: PHONE_NUMBER_REGEX,
                message: 'Enter a valid phone number',
              },
            ]}
          />
        </Col>
        <Col span={24} lg={{ span: 7 }}>
          <FormInput
            label={
              <Space>
                <span>Email ID</span>
                {checkDupPartnerUserEmailLoading && (
                  <LoadingOutlined spin={checkDupPartnerUserEmailLoading} />
                )}
              </Space>
            }
            name={'email_id'}
            placeholder="Enter email id"
            labelClasess={'text-xs text-tertiary-text font-segoe-ui'}
            rules={[
              {
                required: true,
                message: 'Email ID is required',
              },
              {
                type: 'email',
                message: 'Enter a valid email ID',
              },
              { validator: validatePartnerUserEmail },
            ]}
          />
        </Col>
        <Col
          span={24}
          lg={{ span: 2 }}
          className="flex justify-center lg:justify-start"
        >
          <FormInput
            type="placeholder"
            hideLabel
            label={'Action'}
            placeholderComponent={
              <Button
                onClick={() => {
                  form.submit();
                }}
                className="px-6 md:px-4 !py-4.5 font-medium border border-primary-bg text-primary-bg bg-transparent text-sm font-segoe-ui"
              >
                Add
              </Button>
            }
          />
        </Col>
      </Row>
    </Form>
  );
};

export default function PartnerAddress({
  address,
  setAddress,
  selectedAddress,
  isEdit,
}) {
  const { xs, sm, md } = useResponsiveness();
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [form] = Form.useForm();
  const [childForm] = Form.useForm();
  const [isAddPartnerUserFormOpen, setIsAddPartnerUserFormOpen] =
    useState(false);

  //tranforming contacts for select field
  const transformedOptions = contacts.map((contact, index) => ({
    label: `Partner User (${(index + 1).toString().padStart(2, '0')})`,
    value: index,
    name: contact.contact_name,
    id: contact.id ? contact.id : null,
  }));

  //handle craete partner form finish
  const handleOnSubmit = (values) => {};

  //structoring the partner addresses state on for values change
  const handleFormValuesChange = (changedVal, values) => {
    if (changedVal.partner_user || changedVal.partner_user === 0) {
      setSelectedContact(contacts[changedVal.partner_user]);
    }

    if (changedVal.partner_address) {
      setAddress((prevAddress) => {
        const updatedAddress = [...prevAddress];
        updatedAddress[selectedAddress] = {
          ...updatedAddress[selectedAddress],
          address: values.partner_address,
        };
        return updatedAddress;
      });
    } else if (!changedVal.partner_address) {
      setAddress((prevAddress) => {
        const updatedAddress = [...prevAddress];
        updatedAddress[selectedAddress] = {
          ...updatedAddress[selectedAddress],
          address: '',
        };
        return updatedAddress;
      });
    }
  };

  //loading contactsa on edit
  useEffect(() => {
    if (isEdit) {
      setSelectedContact(0);
      setContacts(address[selectedAddress]?.contacts);
      form.setFieldsValue({
        partner_address: address[selectedAddress]?.address,
      });
    }
  }, [isEdit, address, selectedAddress]);

  //handle remove user
  const handleRemoveUser = (index, isExisting, isEditBtn = false) => {
    if (isExisting) {
      // setContacts((prevCont) => {
      //   return [...prevCont].map((item, i) =>
      //     index === i ? { ...item, is_active: false } : item,
      //   );
      // });

      // setAddress((prevAddress) => {
      //   const updatedAddress = [...prevAddress];

      //   const updatedContacts = [...updatedAddress[selectedAddress].contacts];
      //   updatedContacts[index] = {
      //     ...updatedContacts[index],
      //     is_active: false,
      //   };

      //   updatedAddress[selectedAddress] = {
      //     ...updatedAddress[selectedAddress],
      //     contacts: updatedContacts,
      //   };
      //   return updatedAddress;
      // });
      setContacts((prevCont) => {
        return prevCont.filter((con, i) => i !== index);
      });
    } else {
      setContacts((prevCont) => {
        return prevCont.filter((con, i) => i !== index);
      });
    }

    if (isEditBtn) return;
    if (index > 0) {
      form.setFieldsValue({ partner_user: index - 1 });
      setSelectedContact(contacts[index - 1]);
    } else {
      childForm.resetFields();
      if (contacts.length) {
        form.setFieldsValue({ partner_user: index + 1 });
        setSelectedContact(contacts[index + 1]);
      }
    }
  };

  //setting / updating existing address log if new contact is added
  useEffect(() => {
    setAddress((prevAddress) => {
      const updatedAddress = [...prevAddress];
      updatedAddress[selectedAddress] = {
        ...updatedAddress[selectedAddress],
        contacts,
      };
      return updatedAddress;
    });
  }, [contacts?.length]);

  //loading selected contact to fields (reseting contact form)
  useEffect(() => {
    form.setFieldsValue({
      partner_address: address[selectedAddress]?.address,
      partner_user: null,
    });
    setContacts(address[selectedAddress]?.contacts);
    childForm.resetFields();
    setSelectedContact(null);
  }, [selectedAddress]);

  return (
    <Form
      form={form}
      layout="vertical"
      id="partner-address-form"
      //   onFinish={handleOnSubmit}
      onValuesChange={handleFormValuesChange}
      className="flex flex-col gap-5"
    >
      <FormInput
        type="textarea"
        placeholder="Enter partner user address"
        rows={xs || sm || md ? 3 : 2}
        name={'partner_address'}
        disabled={isEdit && address[selectedAddress]?.id}
      />
      <Card
        header={
          <div
            className={
              contacts?.length
                ? 'px-2 gap-2 flex items-center'
                : 'px-4 flex gap-2 items-center'
            }
          >
            {(!isEdit && contacts?.length) || isAddPartnerUserFormOpen ? (
              <FormInput
                type="select"
                name="partner_user"
                options={transformedOptions}
                showSearch={false}
                selectInputClasses={
                  'font-segoe-ui !p-0 !text-xs custom-select-label md:!text-sm !shadow-none max-w-fit'
                }
                suffixIcon={<img src={expandIcon} />}
                optionRender={(option) => {
                  return (
                    <div className="flex justify-between gap-2">
                      <span className="text-sm capitalize font-segoe-ui text-tertiary-placeholder">
                        {contacts[option.value]?.contact_name}
                      </span>
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          openNotification({
                            onApprove: () =>
                              handleRemoveUser(
                                option.value,
                                Boolean(option.data.id),
                              ),
                            title: `Delete Partner user`,
                            subTitle: `Are you sure you want to delete this partner user?`,
                            approveBtnClasses:
                              'w-full !py-4.5 !bg-primary-red hover:!border-primary-red hover:!text-primary-red  !text-secondary-bg hover:!bg-transparent  hover:!border',
                            cancelBtnClasses: 'w-full !py-4.5',
                            approveText: 'Delete',
                            cancelText: 'Cancel',
                          });
                        }}
                        className="cursor-pointer hover:opacity-50"
                        src={deleteIcon}
                      />
                    </div>
                  );
                }}
                placeholder="Partner User"
                selectplaceholderClasses={
                  'font-segoe-ui font-semibold text-sm text-tertiary-text'
                }
              />
            ) : !isEdit ? (
              'Add Partner User'
            ) : (
              'Partner User'
            )}
            {isEdit && (
              <Button
                icon={
                  isAddPartnerUserFormOpen ? (
                    <CloseOutlined />
                  ) : (
                    <PlusOutlined />
                  )
                }
                className="text-primary-bg font-semibold text-sm font-segoe-ui !border-none hover:!text-secondary-bg hover:!bg-primary-bg"
                onClick={() =>
                  isAddPartnerUserFormOpen
                    ? setIsAddPartnerUserFormOpen(false)
                    : setIsAddPartnerUserFormOpen(true)
                }
              >
                Add Partner User
              </Button>
            )}
          </div>
        }
        className={'!bg-secondary-bg py-2.5 flex flex-col gap-2.5'}
        headerClasses={'font-segoe-ui font-semibold text-sm text-tertiary-text'}
      >
        <>
          {!isEdit || (isEdit && isAddPartnerUserFormOpen) ? (
            <PartnerDetailsForm
              form={childForm}
              contacts={contacts}
              setContacts={setContacts}
              selectedContact={selectedContact}
              setSelectedContact={setSelectedContact}
              parentForm={form}
              allContacts={address.flatMap((con) => con.contacts)}
            />
          ) : (
            contacts.map((contact, i) => {
              return (
                <ContactListItem
                  key={i}
                  contact={contact}
                  onClick={(e) => {
                    e.stopPropagation();
                    openNotification({
                      onApprove: () =>
                        handleRemoveUser(i, Boolean(contact.id), true),
                      title: `Delete Partner user`,
                      subTitle: `Are you sure you want to delete this partner user?`,
                      approveBtnClasses:
                        'w-full !py-4.5 !bg-primary-red hover:!border-primary-red hover:!text-primary-red  !text-secondary-bg hover:!bg-transparent  hover:!border',
                      cancelBtnClasses: 'w-full !py-4.5',
                      approveText: 'Delete',
                      cancelText: 'Cancel',
                    });
                  }}
                />
              );
            })
          )}
        </>
      </Card>
    </Form>
  );
}
