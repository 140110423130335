import { Form } from 'antd';
import FormInput from './FormInput';
import searchIcon from '@assets/icons/input/search.svg';
import { useCallback } from 'react';
import debounce from 'lodash/debounce';

export default function SearchBar({ onChange, classes, disabled, placeholder = "Search by project name" }) {
  //debouncing the search
  const debouncedOnChange = useCallback(
    debounce((value) => {
      onChange(value);
    }, 300),
    [onChange],
  );

  //handle value change
  const handleValueChange = (changedVal, values) => {
    debouncedOnChange(values.term);
  };

  return (
    <Form className="p-2" onValuesChange={handleValueChange}>
      <FormInput
        disabled={disabled}
        name="term"
        prefix={<img src={searchIcon} className="mr-1" />}
        placeholder={placeholder}
        inputClasses={
          classes
            ? `${classes} font-segoe-ui !shadow-none input-placeholder border border-[#D0D5DD] rounded-lg hover:!border-primary-bg`
            : 'py-[6px] px-3.5 font-segoe-ui border border-[#D0D5DD] text-sm input-placeholder rounded-lg hover:!border-primary-bg !shadow-none w-auto sm:w-80  md:w-96'
        }
      />
    </Form>
  );
}
