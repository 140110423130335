import Card from '@common/Card';
import FormInput from '@common/FormInput';
import { Button, Col, Form, message, Row, Space, Tag } from 'antd';
import { useEffect, useState } from 'react';
import PartnerAddress from '../components/PartnerAddress';
import {
  CheckOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import trashcanIcon from '@assets/icons/input/trashcan.svg';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkDupPartnerUserEmail,
  createPartner,
  selectPartnerSlice,
} from '../redux/slice';
import debounce from 'lodash/debounce';
import {
  checkDupPartnerEmail,
  selectMastersSlice,
} from '@features/masterRedux/slice';
import RequiredAsterisk from '@common/RequiredAsterisk';
import IconButton from '@common/IconButton';
import editIcon from '@assets/icons/input/edit.svg';
import editActiveIcon from '@assets/icons/input/edit_active.svg';
import { openNotification } from '@utils/notification';
import { PHONE_NUMBER_REGEX } from '@utils/helpers';

export default function CreatePartner() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createPartnerLoading } = useSelector(selectPartnerSlice);
  const { checkDupPartnerEmailLoading, partnerEmailAlreadyExist } =
    useSelector(selectMastersSlice);
  const { checkDupPartnerUserEmailLoading, partnerUserEmailAlreadyExist } =
    useSelector(selectPartnerSlice);
  const [isCreateBtnDisabled, setIsCreateBtnDisabled] = useState(false);
  const [isPartnerEmailEditing, setIsPartnerEmailEditing] = useState(false);
  const [isPartnerPhoneEditing, setIsPartnerPhoneEditing] = useState(false);
  const [isPartnerNameEditing, setIsPartnerNameEditing] = useState(false);
  const [mode] = useSearchParams()[0].getAll(['mode']);
  const { state } = useLocation();
  // console.log(state);

  const [form] = Form.useForm();

  //partner addrees state
  const [partnerAddresses, setPartnerAddresses] = useState([
    {
      address: '',
      contacts: [],
    },
  ]);
  const [selectedAddresses, setSelectedAddresses] = useState(0);

  // adding new address tab for the partner
  const handleAddNewAddress = () => {
    setPartnerAddresses([
      ...partnerAddresses,
      {
        address: '',
        contacts: [],
      },
    ]);
    setSelectedAddresses(partnerAddresses.length);
  };

  //handle craete partner form finish
  const handleOnSubmit = (values) => {
    if (mode === 'edit') {
      if (isPartnerNameEditing) {
        message.error('Please save partner name');
        return;
      }

      if (isPartnerEmailEditing) {
        message.error('Please save partner email ID');
        return;
      }

      if (isPartnerPhoneEditing) {
        message.error('Please save partner phone number');
        return;
      }

      const transformedAddresses = state.PartnerAddresses?.map((address) => {
        const contacts = address.contacts.map((contact) => {
          return {
            id: contact.id,
            is_active: contact.is_active,
            contact_name: contact.contact_name,
            email_id: contact.email_id,
            phone_number: contact.phone_number,
          };
        });
        return {
          address: address.address,
          id: address.id,
          contacts,
        };
      });

      const updatedPartnerAddresses = partnerAddresses.map((address) => {
        const transformedAddress = transformedAddresses.find(
          (addr) => addr.id === address.id,
        );

        const updatedContacts = address.contacts.map((contact) => {
          if (contact.id) {
            return {
              id: contact.id,
              is_active: contact.is_active,
              contact_name: contact.contact_name,
              email_id: contact.email_id,
              phone_number: contact.phone_number,
            };
          } else {
            return contact;
          }
        });

        transformedAddress?.contacts?.forEach((transformedContact) => {
          const isPresent = updatedContacts.some(
            (updatedContact) => updatedContact.id === transformedContact.id,
          );

          if (!isPresent) {
            updatedContacts.push({
              ...transformedContact,
              is_active: false,
            });
          }
        });

        return {
          ...address,
          is_active: true,
          contacts: updatedContacts,
        };
      });

      const deletedAddresses = transformedAddresses
        .filter(
          (addr) => !updatedPartnerAddresses.some((ad) => ad.id === addr.id),
        )
        .map((ad) => ({ ...ad, is_active: false }));

      const payload = {
        ...values,
        addresses: [...updatedPartnerAddresses, ...deletedAddresses],
        partner_id: state.id,
      };

      dispatch(createPartner(payload)).then((res) => {
        if (res?.payload?.success) {
          navigate('/partners', { replace: true });
        }
      });
    } else {
      const updatedPartnerAddresses = partnerAddresses.map((address) => {
        return {
          ...address,
          contacts: [
            ...address.contacts,
            {
              contact_name: values.partner_name,
              email_id: values.partner_email,
              phone_number: values.partner_contact,
            },
          ],
        };
      });
      const payload = {
        ...values,
        addresses: updatedPartnerAddresses,
      };
      dispatch(createPartner(payload)).then((res) => {
        if (res?.payload?.success) {
          navigate('/partners', { replace: true });
        }
      });
    }
  };

  //debouncing the partner user email search
  const debouncedCheckPartnerUserEmail = debounce((partner_user_email) => {
    dispatch(checkDupPartnerUserEmail({ partner_user_email }));
  }, 500);

  //debouncing the prokect name
  const debouncedCheckPartnerEmail = debounce((partner_email) => {
    dispatch(checkDupPartnerEmail({ partner_email }));
  }, 500);

  //handling form value change
  const handleFormValuesChange = (changedVal, values) => {
    if (changedVal.partner_email) {
      debouncedCheckPartnerEmail(values.partner_email);
      debouncedCheckPartnerUserEmail(values.partner_email);
    }
  };

  //validating partner email for duplications
  const validatePartnerEmail = (_, value) => {
    if (partnerEmailAlreadyExist) {
      return Promise.reject(new Error('Email ID already exists'));
    }
    return Promise.resolve();
  };

  //load partner details
  const loadPartnerDetails = (addresses) => {
    const transformedAddresses = addresses
      .filter((addr) => addr.is_active)
      .map((address) => {
        const contacts = address.contacts
          .map((contact) => {
            return {
              id: contact.id,
              is_active: contact.is_active,
              contact_name: contact.contact_name,
              email_id: contact.email_id,
              phone_number: contact.phone_number,
            };
          })
          .filter((c) => c.is_active);
        return {
          address: address.address,
          id: address.id,
          contacts,
        };
      });

    setPartnerAddresses(transformedAddresses);
    setSelectedAddresses(0);
  };

  //loading and rendering partner details
  useEffect(() => {
    if (mode === 'edit') {
      loadPartnerDetails(state.PartnerAddresses);
      form.setFieldsValue({
        partner_name: state.partner_name,
        partner_email: state.partner_email,
        partner_company_name: state.partner_company_name,
        partner_address: state.partner_address,
        partner_contact: state.partner_contact,
      });
    }
  }, [mode, state]);

  //checking if all details are field
  useEffect(() => {
    if (
      partnerAddresses.some(
        (address) => !address.address || !address.contacts.length,
      )
    ) {
      setIsCreateBtnDisabled(true);
      return;
    } else {
      setIsCreateBtnDisabled(false);
    }
  }, [partnerAddresses]);

  return (
    <Card
      className="pb-4 my-2 md:my-8"
      headerClasses={'font-segoe-ui font-semibold text-2xl text-primary-bg'}
      header={mode === 'edit' ? 'Edit Partner' : 'Create Partner'}
    >
      <Card
        className={
          'mt-5 w-full rounded-lg !bg-secondary-bg p-7 font-segoe-ui shadow-equal-sides'
        }
        loading={createPartnerLoading}
      >
        <Form
          form={form}
          layout="vertical"
          id="create-partner-form"
          onFinish={handleOnSubmit}
          onValuesChange={handleFormValuesChange}
          className="flex flex-col gap-5"
        >
          <div className="flex flex-col">
            <div className="flex items-center gap-2 px-4 py-2 text-xs font-semibold rounded-t-lg cursor-pointer select-none bg-tertiary-bg w-fit shrink-0 font-segoe-ui md:text-sm text-tertiary-text">
              Partner Company Details
            </div>
            <div className="flex flex-col gap-4 bg-tertiary-bg rounded-b-lg p-2.5">
              <Row gutter={24}>
                <Col span={24} md={{ span: 12 }}>
                  <FormInput
                    label={'Partner Company Name'}
                    name={'partner_company_name'}
                    placeholder="Enter partner company name"
                    required
                    validateMsg={'Partner company name is required'}
                    disabled={mode === 'edit'}
                  />
                </Col>
                <Col span={24} md={{ span: 12 }}>
                  <FormInput
                    label={'Partner Company Address'}
                    name={'partner_address'}
                    placeholder="Enter partner company address"
                    required
                    validateMsg={'Partner company address is required'}
                    disabled={mode === 'edit'}
                  />
                </Col>
              </Row>

              <Row gutter={24}>
                <Col
                  span={mode === 'edit' ? 22 : 24}
                  md={{ span: mode === 'edit' ? 6 : 8 }}
                >
                  <FormInput
                    label={'Partner Name'}
                    name={'partner_name'}
                    placeholder="Enter partner name"
                    required
                    validateMsg={'Partner name is required'}
                    disabled={mode === 'edit' && !isPartnerNameEditing}
                  />
                </Col>

                {mode === 'edit' && (
                  <Col span={2}>
                    <FormInput
                      type="placeholder"
                      label={<span className="hidden">action</span>}
                      placeholderComponent={
                        isPartnerNameEditing ? (
                          <CheckOutlined
                            onClick={() => setIsPartnerNameEditing(false)}
                            className="text-primary-green hover:!text-primary-bg"
                          />
                        ) : (
                          <IconButton
                            srcActive={editActiveIcon}
                            src={editIcon}
                            tooltipName={'Edit partner email'}
                            onClick={() => setIsPartnerNameEditing(true)}
                          />
                        )
                      }
                    />
                  </Col>
                )}

                <Col
                  span={mode === 'edit' ? 22 : 24}
                  md={{ span: mode === 'edit' ? 6 : 8 }}
                >
                  <FormInput
                    label={'Partner Phone Number'}
                    name={'partner_contact'}
                    placeholder="Enter partner phone number"
                    rules={[
                      {
                        required: true,
                        message: 'Partner Phone number is required',
                      },
                      {
                        pattern: PHONE_NUMBER_REGEX,
                        message: 'Enter a valid phone number',
                      },
                    ]}
                    disabled={mode === 'edit' && !isPartnerPhoneEditing}
                  />
                </Col>
                {mode === 'edit' && (
                  <Col span={2}>
                    <FormInput
                      type="placeholder"
                      label={<span className="hidden">action</span>}
                      placeholderComponent={
                        isPartnerPhoneEditing ? (
                          <CheckOutlined
                            onClick={() => setIsPartnerPhoneEditing(false)}
                            className="text-primary-green hover:!text-primary-bg"
                          />
                        ) : (
                          <IconButton
                            srcActive={editActiveIcon}
                            src={editIcon}
                            tooltipName={'Edit partner email'}
                            onClick={() => setIsPartnerPhoneEditing(true)}
                          />
                        )
                      }
                    />
                  </Col>
                )}

                <Col
                  span={mode === 'edit' ? 22 : 24}
                  md={{ span: mode === 'edit' ? 6 : 8 }}
                >
                  <FormInput
                    label={
                      <Space>
                        <span>Partner Email ID</span>
                        {(checkDupPartnerEmailLoading ||
                          checkDupPartnerUserEmailLoading) && (
                          <LoadingOutlined
                            spin={
                              checkDupPartnerEmailLoading ||
                              checkDupPartnerUserEmailLoading
                            }
                          />
                        )}
                      </Space>
                    }
                    name={'partner_email'}
                    placeholder="Enter partner email id"
                    rules={[
                      { required: true, message: 'Partner email is required' },
                      { type: 'email', message: 'Email is not valid' },
                      { validator: validatePartnerEmail },
                    ]}
                    disabled={mode === 'edit' && !isPartnerEmailEditing}
                  />
                  {(partnerEmailAlreadyExist ||
                    partnerUserEmailAlreadyExist) && (
                    <span className="text-sm font-segoe-ui text-primary-red">
                      Email ID already exists
                    </span>
                  )}
                </Col>
                {mode === 'edit' && (
                  <Col span={2}>
                    <FormInput
                      type="placeholder"
                      label={<span className="hidden">action</span>}
                      placeholderComponent={
                        isPartnerEmailEditing ? (
                          <CheckOutlined
                            onClick={() => setIsPartnerEmailEditing(false)}
                            className="text-primary-green hover:!text-primary-bg"
                          />
                        ) : (
                          <IconButton
                            srcActive={editActiveIcon}
                            src={editIcon}
                            tooltipName={'Edit partner email'}
                            onClick={() => setIsPartnerEmailEditing(true)}
                          />
                        )
                      }
                    />
                  </Col>
                )}
              </Row>
            </div>
          </div>
          {/* address form */}
          <div>
            <div className="flex w-full overflow-x-auto scrollbar-1">
              {[...partnerAddresses, { isBtn: true }].map((address, i) => {
                return address?.isBtn ? (
                  <Button
                    icon={<PlusOutlined />}
                    className="text-primary-bg font-semibold text-sm font-segoe-ui !border-none hover:!text-secondary-bg hover:!bg-primary-bg"
                    onClick={handleAddNewAddress}
                  >
                    Add Address
                  </Button>
                ) : (
                  <div
                    key={i}
                    className={`${
                      i === selectedAddresses
                        ? 'bg-tertiary-bg cursor-not-allowed'
                        : 'bg-transparent hover:text-primary-bg'
                    } py-2 px-4 rounded-t-lg shrink-0 cursor-pointer font-segoe-ui text-xs md:text-sm font-semibold text-tertiary-text select-none flex items-center gap-2`}
                    onClick={() => setSelectedAddresses(i)}
                  >
                    <span>
                      <RequiredAsterisk />
                      {partnerAddresses.length > 1
                        ? `Address ${(i + 1).toString().padStart(2, '0')}`
                        : 'Partner User Address'}
                    </span>
                    {/* address delete */}
                    {partnerAddresses.length > 1 && selectedAddresses !== i && (
                      <img
                        src={trashcanIcon}
                        className="hover:opacity-50"
                        onClick={(e) => {
                          e.stopPropagation();

                          openNotification({
                            onApprove: () => {
                              const filteredAddressList = [
                                ...partnerAddresses,
                              ].filter(
                                (address) =>
                                  partnerAddresses.indexOf(address) !== i,
                              );
                              setPartnerAddresses(filteredAddressList);
                              if (selectedAddresses === 0) return;
                              setSelectedAddresses(selectedAddresses - 1);
                            },
                            title: `Delete Address`,
                            subTitle: `Are you sure you want to delete address: ${address.address}?`,
                            approveBtnClasses:
                              'w-full !py-4.5 !bg-primary-red hover:!border-primary-red hover:!text-primary-red  !text-secondary-bg hover:!bg-transparent  hover:!border',
                            cancelBtnClasses: 'w-full !py-4.5',
                            approveText: 'Delete',
                            cancelText: 'Cancel',
                          });
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>

            {/* partner address form */}
            <div className="bg-tertiary-bg rounded-b-lg p-2.5">
              <PartnerAddress
                address={partnerAddresses}
                setAddress={setPartnerAddresses}
                selectedAddress={selectedAddresses}
                isEdit={mode === 'edit'}
              />
            </div>
          </div>

          {/* action buttons */}
          <Space size="middle" className="flex items-center justify-center">
            <Button
              className="!p-4.5 font-medium border border-primary-placeholder text-tertiary-text bg-transparent text-md font-segoe-ui hover:!border-primary-bg hover:!text-primary-bg"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Button
              onClick={() => form.submit()}
              className={`!p-4.5 font-medium text-md font-segoe-ui ${!isCreateBtnDisabled ? 'bg-primary-bg text-secondary-bg hover:border hover:border-primary-bg hover:!bg-transparent hover:!text-primary-bg' : ''}`}
              loading={createPartnerLoading}
              disabled={isCreateBtnDisabled}
            >
              {mode === 'edit' ? 'Save' : 'Create'}
            </Button>
          </Space>
        </Form>
      </Card>
    </Card>
  );
}
