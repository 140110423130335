import apiClient from '@utils/apiClient';

// project by month
export const projectByMonth = (payload) => {
  return apiClient.get(apiClient.Urls.projectsByMonth, payload, true);
};

// project by date range
export const projectByDate = (payload) => {
  return apiClient.get(apiClient.Urls.projectsByDateRange, payload, true);
};

// leading partners
export const leadingPartners = (payload) => {
  return apiClient.get(apiClient.Urls.leadingPartners, payload, true);
};

// bottom partners
export const bottomPartners = (payload) => {
  return apiClient.get(apiClient.Urls.bottomPartners, payload, true);
};

// get top 5 projects by partner id
export const projectsByPartnerId = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.projectsByPartner}/${payload.id}`,
    payload,
    true,
  );
};

// get project by id
export const projectDetails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.projectDetails}/${payload.id}`,
    payload,
    true,
  );
};
