import { Avatar, Layout } from 'antd';
import logoIcon from '@assets/icons/granules-logo.svg';
import avatarImg from '@assets/images/avatar.png';
import ProfileDropdown from './ProfileDropdown';
import Navlinks from './NavLinks';

export default function Header() {
  return (
    <Layout.Header className="sticky top-0 z-20 flex items-center justify-between py-9 px-2 sm:!px-6 md:!px-14 lg:!px-20 xl:!px-28 bg-secondary-bg">
      <img src={logoIcon} alt="granules-logo" className="w-24 sm:w-28" />
      <Navlinks />
      <ProfileDropdown>
        <Avatar shape="square" className='cursor-pointer' size={40} icon={<img src={avatarImg} />} />
      </ProfileDropdown>
    </Layout.Header>
  );
}
