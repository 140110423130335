import { StopOutlined } from '@ant-design/icons';
import {
  ACCEPTANCE_STATUS,
  checkActivityAcceptanceStatus,
  columnRenderer,
  getAcceptanceStatusClasses,
  getAcceptanceStatusLabel,
  getActivityStatusClasses,
  getFormattedDate,
  getFormattedDateToSend,
  getHighestActualEndDate,
  getLoggedInUser,
  headerRenderer,
  PROJECT_STATUS,
  STATUS,
} from '@utils/helpers';
import { Button, Space } from 'antd';
import timelineIcon from '@assets/icons/input/timeline.svg';
import timelineActiveIcon from '@assets/icons/input/timeline_active.svg';
import editIcon from '@assets/icons/input/edit.svg';
import editActiveIcon from '@assets/icons/input/edit_active.svg';
import deleteIcon from '@assets/icons/input/delete.svg';
import deleteActiveIcon from '@assets/icons/input/delete_active.svg';
import IconButton from '@common/IconButton';
import { useEffect, useState } from 'react';
import DraggableRowTable from '@common/DraggableRowTable';
import { arrayMove } from '@dnd-kit/sortable';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProjectDetails,
  selectProjectSlice,
  shortClosePartner,
  toggleActivity,
} from '../redux/slice';
import { useNavigate } from 'react-router-dom';
import { openNotification } from '@utils/notification';
import { getProjectsByParterId } from '@features/partner/redux/slice';
import ShortCloseModal from './ShortCloseModal';
import AuditTrails from './AuditTrails';
import {
  deleteActivity,
  getAuditTrailsById,
  getAuditTrailsByPartnerId,
  selectActivitySlice,
} from '@features/activity/redux/slice';
import {
  GRANUAL_USER,
  PARTNER,
  PARTNER_USER,
  PERMISSIONS,
} from '@utils/roleRights';
import moment from 'moment';

const SINGLE_PARTNER_USER = 'single_partner_user';

export default function ActivityList({
  activities,
  project,
  partner,
  isConsolidated,
  currentPartnerUserPartnerId,
  currentPartnerUserMappingId,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role, user } = getLoggedInUser();
  const [dataSource, setDataSource] = useState([]);
  const { shortClosePartnerLoading, reAssignPartnerLoading } =
    useSelector(selectProjectSlice);
  const { deleteActivityLoading } = useSelector(selectActivitySlice);
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
  const [isAuditTrailOpen, setIsAuditTrailOpen] = useState(null);

  //delete activity
  const activityDelete = (id) => {
    dispatch(deleteActivity(id)).then((res) => {
      if (res?.payload?.success) {
        const filteredData = [...dataSource].filter((data) => data.id !== id);
        setDataSource(filteredData);
        dispatch(getProjectDetails(project.id));
      }
    });
  };

  //function to move/reposition rows
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.id === active?.id,
        );
        const overIndex = prevState.findIndex(
          (record) => record.id === over?.id,
        );
        let payload = null;
        if (activeIndex < overIndex) {
          const disturbedData = dataSource.slice(0, overIndex + 1);
          payload = disturbedData.map((data, i) => {
            if (i === activeIndex) {
              return {
                id: data.id,
                sequence: activeIndex === 0 ? overIndex : overIndex + 1,
              };
            } else {
              return { id: data.id, sequence: i };
            }
          });
        } else {
          const activeItem = dataSource[activeIndex];
          const disturbedData = dataSource.slice(overIndex, activeIndex + 1);
          payload = disturbedData.map((data, i) => {
            if (data.id === activeItem.id) {
              return { id: data.id, sequence: overIndex };
            } else {
              return { id: data.id, sequence: overIndex + i + 1 };
            }
          });
        }
        dispatch(toggleActivity(payload));
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const columns = [
    {
      title: (
        <div className="flex items-center gap-2">
          <label className="text-xs font-bold font-segoe-ui text-tertiary-placeholder">
            Activity Description
          </label>
          {PERMISSIONS.canReadActivityAuditTrails(role) && (
            <IconButton
              tooltipName={'Complete Activity Timeline'}
              src={timelineIcon}
              srcActive={timelineActiveIcon}
              onClick={() =>
                setIsAuditTrailOpen({ isSingleActivity: false, id: partner.id })
              }
            />
          )}
        </div>
      ),
      dataIndex: 'activity_description',
      key: 'activity_description',
      ellipsis: true,
      width: '15%',
      render: (text, record, index) =>
        columnRenderer(text, true, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Responsibility',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'responsibility',
      key: 'responsibility',
      ellipsis: true,
      width: '10%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Base Start Date',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'base_start_date',
      key: 'base_start_date',
      ellipsis: true,
      width: '10%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Base End Date',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'base_end_date',
      key: 'base_end_date',
      ellipsis: true,
      width: '10%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Current Start Date',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'current_start_date',
      key: 'current_start_date',
      ellipsis: true,
      width: '10%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Current End Date',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'current_end_date',
      key: 'current_end_date',
      ellipsis: true,
      width: '10%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Estimated Completion Date',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'estimated_date',
      key: 'estimated_date',
      width: '10%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Actual Completion Date',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'actual_completion_date',
      key: 'actual_completion_date',
      width: '10%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Overall Duration',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'overall_duration',
      key: 'overall_duration',
      width: '10%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Estimated Duration',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'estimated_duration',
      key: 'estimated_duration',
      width: '10%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Status',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'status',
      key: 'status',
      width: '8%',
      render: (text, record, index) =>
        columnRenderer(
          text,
          false,
          null,
          getActivityStatusClasses(record.status),
          '-',
        ),
    },
    {
      title: headerRenderer(
        'Acceptance Status',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'acceptance_status',
      key: 'acceptance_status',
      width: '8%',
      render: (text, record, index) =>
        columnRenderer(
          getAcceptanceStatusLabel(text),
          false,
          null,
          getAcceptanceStatusClasses(record.acceptance_status),
        ),
    },
    {
      title: headerRenderer(
        'Remarks',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'remarks',
      key: 'remarks',
      width: '10%',
      render: (text, record, index) => columnRenderer(text, true),
    },
    {
      title: null,
      dataIndex: 'action',
      key: 'action',
      width: '8%',
      render: (_, record) => (
        <Space size="middle">
          {!isConsolidated && partner?.partner.is_active && (
            <>
              <IconButton
                tooltipName={'Edit'}
                src={editIcon}
                srcActive={editActiveIcon}
                disabled={
                  record.acceptance_status === ACCEPTANCE_STATUS.accept ||
                  record.acceptance_status === ACCEPTANCE_STATUS.stopActivty ||
                  record.acceptance_status === ACCEPTANCE_STATUS.shortClose ||
                  (role === PARTNER_USER &&
                    currentPartnerUserMappingId !==
                      record?.partnerUserMappingId)
                }
                onClick={() =>
                  navigate('/dashboard/activity?mode=edit', {
                    state: {
                      activityId: record.id,
                      project,
                      partner,
                      activities: dataSource,
                    },
                  })
                }
              />
              {PERMISSIONS.canDeleteActivity(role) && (
                <IconButton
                  tooltipName={'Delete'}
                  src={deleteIcon}
                  srcActive={deleteActiveIcon}
                  disabled={
                    record.acceptance_status === ACCEPTANCE_STATUS.accept ||
                    record.acceptance_status ===
                      ACCEPTANCE_STATUS.stopActivty ||
                    record.acceptance_status === ACCEPTANCE_STATUS.shortClose
                  }
                  onClick={() => {
                    if (record.id) {
                      openNotification({
                        onApprove: () => activityDelete(record.id),
                        title: 'Delete activity',
                        subTitle: 'Do you want to delete the activity',
                        approveBtnClasses:
                          'w-full !py-2 bg-primary-red text-secondary-bg hover:!bg-transparent hover:!border-primary-red hover:!border hover:!text-primary-red',
                        okBtnLoading: deleteActivityLoading,
                        cancelBtnClasses: 'w-full !py-2',
                        approveText: 'Yes, delete',
                        cancelText: 'Cancel',
                      });
                    }
                  }}
                />
              )}
            </>
          )}
          {PERMISSIONS.canReadActivityAuditTrails(role) && (
            <IconButton
              tooltipName={'Activity Timeline'}
              src={timelineIcon}
              srcActive={timelineActiveIcon}
              onClick={() =>
                setIsAuditTrailOpen({ isSingleActivity: true, id: record.id })
              }
            />
          )}
        </Space>
      ),
    },
  ];

  //transforming activities
  const transformedActivities = activities?.map((activity) => {
    return {
      id: activity?.id,
      key: activity?.id,
      partner_id: activity.responsibility,
      responsibility: activity.partner?.partner_name || '-',
      activity_description: activity.activity_desc || '-',
      base_start_date: activity.base_start_date
        ? getFormattedDate(activity.base_start_date)
        : '-',
      base_end_date: activity.base_end_date
        ? getFormattedDate(activity.base_end_date)
        : '-',
      current_start_date: activity.current_start_date
        ? getFormattedDate(activity.current_start_date)
        : '-',
      current_end_date: activity.current_end_date
        ? getFormattedDate(activity.current_end_date)
        : '-',
      estimated_date: activity.estimated_date
        ? getFormattedDate(activity.estimated_date)
        : '-',
      actual_completion_date: activity.actual_completion_date
        ? getFormattedDate(activity.actual_completion_date)
        : '-',
      overall_duration: activity.overall_duration,
      estimated_duration: activity.estimated_duration,
      status: activity.completion_status,
      acceptance_status: activity.acceptance_status,
      remarks: activity.remarks || '-',
      sequence: activity.sequence,
      partnerUserMappingId: activity.partner_user_mapping_id,
    };
  });

  //get project actual end date
  const getProjectActualEndDate = (mappings) => {
    const partners = mappings.filter((part) => part.id !== partner.id);

    if (
      checkActivityAcceptanceStatus(partners.flatMap((part) => part.activities))
    ) {
      return getFormattedDateToSend(
        getHighestActualEndDate(partners.flatMap((part) => part.activities)),
      );
    } else {
      return null;
    }
  };

  //get project status
  const getProjectStatus = (mappings) => {
    const projectEndDate = moment(
      getFormattedDateToSend(project?.project_end_date),
    );
    const activities = mappings
      .filter((part) => part.id !== partner.id)
      .flatMap((part) => part.activities);

    if (!activities.length) {
      return PROJECT_STATUS.ongoing;
    }

    if (checkActivityAcceptanceStatus(activities)) {
      if (getHighestActualEndDate(activities).isAfter(projectEndDate)) {
        return PROJECT_STATUS.completedDelayed;
      } else {
        return PROJECT_STATUS.completed;
      }
    } else {
      if (getHighestActualEndDate(activities).isAfter(projectEndDate)) {
        return PROJECT_STATUS.delayed;
      } else {
        return PROJECT_STATUS.ongoing;
      }
    }
  };

  //handling short close
  const handleShortClose = () => {
    const payload = {
      id: partner.id,
      acceptance_status: ACCEPTANCE_STATUS.shortClose,
      project_id: project.id,
      project_actual_end_date: getProjectActualEndDate(
        project?.Projects_Partners_Mappings.filter((p) => p.is_active) || [],
      ),
      status: getProjectStatus(
        project?.Projects_Partners_Mappings.filter((p) => p.is_active) || [],
      ),
    };
    dispatch(shortClosePartner(payload)).then((res) => {
      if (res?.payload?.success) {
        if (project.id) {
          dispatch(getProjectDetails(project.id));
        }
      }
    });
  };

  //checking when short closing if the activity is the last activity
  const checkForLastActivityOnShortClose = () => {
    const partners = project?.Projects_Partners_Mappings.filter(
      (p) => p.is_active,
    );

    //if only single partner user is present/active
    if (partners.length === 1) return SINGLE_PARTNER_USER;

    const activities = partners
      .flatMap((part) => part.activities)
      .filter(
        (act) =>
          act.acceptance_status !== ACCEPTANCE_STATUS.shortClose &&
          act.acceptance_status !== ACCEPTANCE_STATUS.stopActivty &&
          act.acceptance_status !== ACCEPTANCE_STATUS.accept,
      );

    //if activities length is greater than 1
    if (activities.length > 1) return false;

    return true;
  };

  //setting activities in state
  useEffect(() => {
    setDataSource(
      transformedActivities.filter(
        (record) =>
          role === GRANUAL_USER ||
          (role === PARTNER_USER &&
            record.partner_id === currentPartnerUserPartnerId) ||
          (role === PARTNER && record.partner_id === Number(user.partner_id)),
      ),
    );
  }, [activities]);

  // fetching audit trails
  useEffect(() => {
    if (isAuditTrailOpen?.id) {
      if (isAuditTrailOpen?.isSingleActivity) {
        dispatch(getAuditTrailsById({ id: isAuditTrailOpen?.id }));
      } else {
        dispatch(getAuditTrailsByPartnerId({ id: isAuditTrailOpen?.id }));
      }
    }
  }, [isAuditTrailOpen?.id]);

  return (
    <div className="flex flex-col w-full">
      <div>
        {!isConsolidated &&
          activities.length &&
          PERMISSIONS.canShortClosePartner(role) &&
          partner?.partner.is_active &&
          activities?.some(
            (activity) =>
              activity.acceptance_status !== ACCEPTANCE_STATUS.accept,
          ) &&
          activities?.some(
            (activity) =>
              activity.acceptance_status !== ACCEPTANCE_STATUS.shortClose,
          ) && (
            <Button
              type="link"
              icon={<StopOutlined />}
              className="text-[#F43E3E] float-end !font-segoe-ui !text-xs font-semibold"
              onClick={() => {
                openNotification({
                  onApprove: () => handleShortClose(),
                  title: 'Short Close Partner',
                  subTitle:
                    checkForLastActivityOnShortClose() === SINGLE_PARTNER_USER
                      ? `Given that this is the only active partner type in the project, you cannot short-close this partner user.`
                      : checkForLastActivityOnShortClose() === true
                        ? 'Are you sure you want to short close the existing partner? This action will stop all activities assigned to this partner on the project, and going forward, the responsibility cannot be reassigned or added as the project is nearing completion.'
                        : 'Are you sure you want to short close the existing partner? This action will stop all activities assigned to this partner on the project.',
                  approveBtnClasses: `w-full !py-2 bg-primary-red text-secondary-bg hover:!bg-transparent hover:!border-primary-red hover:!border hover:!text-primary-red ${checkForLastActivityOnShortClose() === SINGLE_PARTNER_USER ? 'hidden' : ''}`,
                  okBtnLoading: shortClosePartnerLoading,
                  cancelBtnClasses: 'w-full !py-2',
                  approveText: 'Yes',
                  cancelText: 'Cancel',
                });
              }}
            >
              Short Close
            </Button>
          )}

        {/* //short close modal */}
        {/* <ShortCloseModal
          project={project}
          partner={partner}
          isOpen={isReassignModalOpen}
          onClose={() => setIsReassignModalOpen(false)}
          onReassign={handleShortClose}
          loading={reAssignPartnerLoading}
        /> */}
      </div>
      <div className="border-2 border-gray-200 rounded-lg">
        <DraggableRowTable
          columns={columns}
          dataSource={dataSource}
          onDragEnd={onDragEnd}
          loading={deleteActivityLoading || shortClosePartnerLoading}
          isConsolidated={isConsolidated}
        />
      </div>

      {/* //audit trail modal */}
      <AuditTrails
        isOpen={isAuditTrailOpen}
        onClose={() => setIsAuditTrailOpen(null)}
        activityList={transformedActivities}
      />
    </div>
  );
}
