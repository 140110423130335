import apiClient from "@utils/apiClient";

// project list
export const projectList = (payload) => {
  return apiClient.get(apiClient.Urls.projectList, payload, true);
};

// partners project list
export const partnersProjectList = (payload) => {
  return apiClient.get(`${apiClient.Urls.projectsByPartner}/${payload.id}`, payload, true);
};

// partner users project list
export const partnerUsersProjectList = (payload) => {
  return apiClient.get(`${apiClient.Urls.projectsByPartnerUser}/${payload.id}`, payload, true);
};
