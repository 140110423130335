import SearchBar from '@common/SearchBar';
import Table from '@common/Table';
import { useResponsiveness } from '@hooks/useResponsiveness';
import {
  ACCEPTANCE_STATUS,
  columnRenderer,
  getFormattedDate,
  headerRenderer,
  STATUS,
} from '@utils/helpers';
import { Flex, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProjectsByParterId, selectPartnerSlice } from '../redux/slice';
import expandIcon from '@assets/icons/input/arrow_down.svg';
import shrinkIcon from '@assets/icons/input/arrow_up.svg';
import ActivityList from '@features/project/components/ActivityList';
import moment from 'moment';

export default function PartnerProjListView({ partnerId, partnerName }) {
  const { xs, sm, md } = useResponsiveness();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectsByPartner, projectsByPartnerLoading, pagination } =
    useSelector(selectPartnerSlice);

  const [expandedProject, setExpandedProject] = useState(null);

  //handle click on expand icon
  const handleExpandClick = (project) => {
    setExpandedProject((prev) => (prev?.id === project.id ? null : project));
    getProjects(partnerId, searchTerm, currentPage);
  };

  const columns = [
    {
      title: headerRenderer('Project Name'),
      dataIndex: 'project_name',
      key: 'project_name',
      ellipsis: true,
      width: '10%',
      sorter: (a, b) => a.project_name?.localeCompare(b.project_name),
      render: (text, record, index) => {
        const isExpanded = expandedProject?.id === record.id;
        return (
          <div className="flex items-center justify-between w-full gap-2 font-segoe-ui">
            <div className="flex items-center w-3/5 gap-2">
              <Tooltip title={text}>
                <p className="overflow-hidden text-sm text-primary-column font-segoe-ui text-ellipsis whitespace-nowrap">
                  {text || 'NA'}
                </p>
              </Tooltip>
              <span className="flex items-center px-1 rounded-sm text-2xs text-secondary-bg font-segoe-ui bg-primary-placeholder aspect-square">
                {record?.project?.activities?.length || 0}
              </span>
            </div>
            <button
              className="flex items-center w-2/5 gap-1"
              onClick={(e) => {
                e.stopPropagation();
                handleExpandClick(record);
              }}
            >
              <span className="font-semibold text-primary-bg">
                {isExpanded ? 'Shrink' : 'Expand'}
              </span>
              <img src={isExpanded ? shrinkIcon : expandIcon} />
            </button>
          </div>
        );
      },
    },
    {
      title: headerRenderer('Project ID'),
      dataIndex: 'project_id',
      key: 'project_id',
      ellipsis: true,
      width: '10%',
      render: (text, record, index) => columnRenderer(text, false, null),
    },
    {
      title: headerRenderer('Partner Type', true, true),
      dataIndex: 'partner_type',
      key: 'partner_type',
      ellipsis: true,
      width: '8%',
      render: (text, record, index) => columnRenderer(text, false, null),
    },
    {
      title: headerRenderer('Start Date'),
      dataIndex: 'start_date',
      key: 'start_date',
      width: '8%',
      render: (text, record, index) => columnRenderer(text, false, null),
    },
    {
      title: headerRenderer('Completion Date', true, true),
      dataIndex: 'completion_date',
      key: 'completion_date',
      width: '8%',
      render: (text, record, index) => columnRenderer(text, false, null),
    },
  ];

  //transforming project partners
  const transformedPartnerProjects = projectsByPartner?.map((project) => {
    const start_date = project.activities.length
      ? project.activities
          ?.map((activity) => moment(activity.base_start_date))
          .reduce((minDate, currentDate) =>
            currentDate.isBefore(minDate) ? currentDate : minDate,
          )
      : false;

    const completion_date =
      project.activities.length &&
      project.activities?.filter(
        (act) => act.acceptance_status !== ACCEPTANCE_STATUS.stopActivty,
      ).length &&
      project.activities
        ?.filter(
          (act) => act.acceptance_status !== ACCEPTANCE_STATUS.stopActivty,
        )
        ?.every(
          (activity) =>
            activity.completion_status === STATUS.completed &&
            activity.acceptance_status === ACCEPTANCE_STATUS.accept,
        )
        ? project.activities
            .map((activity) => moment(activity.actual_completion_date))
            .reduce((maxDate, currentDate) =>
              currentDate.isAfter(maxDate) ? currentDate : maxDate,
            )
        : false;

    return {
      project,
      id: project?.id,
      key: project?.id,
      project_id: project.Project.project_code || 'NA',
      project_name: project.Project.project_name || 'NA',
      partner_type: project.PartnerType.type || 'NA',
      start_date: getFormattedDate(start_date),
      completion_date: getFormattedDate(completion_date),
    };
  });

  //get projects by partner
  const getProjects = (id, search, page) => {
    const payload = {
      page,
      search,
      id,
    };
    dispatch(getProjectsByParterId(payload));
  };

  //fetching project list by partner on search, currentpage and partner id change
  useEffect(() => {
    if (partnerId) {
      getProjects(partnerId, searchTerm, currentPage);
    }
  }, [currentPage, searchTerm, partnerId]);

  return (
    <Flex vertical>
      {/* header */}
      <div className="flex items-center justify-between px-6 py-2">
        <h1 className="text-lg font-semibold font-segoe-ui text-tertiary-text">
          {partnerName}
        </h1>
        <SearchBar
          onChange={setSearchTerm}
          disabled={projectsByPartnerLoading}
        />
      </div>

      {/* content */}
      <Flex className="relative w-full mb-2 border-t border-gray-200 sticky-footer">
        <Table
          totalPages={pagination?.total_page}
          pagination={true}
          pageSize={pagination?.total_perpage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          columns={columns}
          bordered
          dataSource={transformedPartnerProjects}
          hideScroll={!xs && !sm && !md}
          loading={projectsByPartnerLoading}
          rowClassName={(record, index) =>
            record.id === expandedProject?.id
              ? 'shadow-md'
              : `hover:!bg-primary-screen`
          }
          expandable={{
            expandedRowRender: (record) => {
              return (
                <ActivityList
                  activities={record.project.activities}
                  project={record.project.Project}
                  partner={{
                    id: record.project.partner_id,
                    partner_type: record.partner_type,
                  }}
                  isConsolidated={true}
                />
              );
            },
            rowExpandable: () => true,
            expandIcon: () => null,
            expandedRowKeys: expandedProject ? [expandedProject.id] : [],
            showExpandColumn: false,
          }}
        />
      </Flex>
    </Flex>
  );
}
