import { configureStore } from '@reduxjs/toolkit';
import authReducer from '@features/login/redux/slice';
import dashboardReducer from '@features/dashboard/redux/slice';
import partnerReducer from '@features/partner/redux/slice';
import projectReducer from '@features/project/redux/slice';
import mastersReducer from '@features/masterRedux/slice';
import activityReducer from '@features/activity/redux/slice';
import partnerScoreReducer from '@features/partner_score/redux/slice';
import adminReducer from '@features/admin/redux/slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    partner: partnerReducer,
    project: projectReducer,
    masters: mastersReducer,
    activity: activityReducer,
    partnerScore: partnerScoreReducer,
    admin: adminReducer,
  },
});
