import Card from '@common/Card';
import IconButton from '@common/IconButton';
import SearchBar from '@common/SearchBar';
import Table from '@common/Table';
import { columnRenderer, headerRenderer } from '@utils/helpers';
import { Button, Flex, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import editIcon from '@assets/icons/input/edit.svg';
import editActiveIcon from '@assets/icons/input/edit_active.svg';
import deleteIcon from '@assets/icons/input/delete.svg';
import deleteActiveIcon from '@assets/icons/input/delete_active.svg';
import { useResponsiveness } from '@hooks/useResponsiveness';
import {
  deactivatePartner,
  getPartnersWithPartnerUsers,
  selectPartnerSlice,
} from '../redux/slice';
import { openNotification } from '@utils/notification';

export default function PartnersList() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { xs, sm } = useResponsiveness();
  const {
    partnersWithPartnerUsers,
    partnersWithPartnerUsersLoading,
    pagination,
    deactivatePartnerLoading,
  } = useSelector(selectPartnerSlice);
  const [isDeactivatingId, setIsDeactivatingId] = useState(null);

  //handling deactivate partner
  const handleDeativatePartner = (partner_id) => {
    setIsDeactivatingId(partner_id);
    dispatch(deactivatePartner({ id: partner_id })).then((res) => {
      if (res?.payload?.success) {
        setIsDeactivatingId(null);
        const payload = {
          page: currentPage,
          search: searchTerm,
        };

        dispatch(getPartnersWithPartnerUsers(payload));
      }
    });
  };

  const columns = [
    {
      title: headerRenderer('Partner Comapny Name', true),
      dataIndex: 'partner_company_name',
      key: 'partner_company_name',
      width: '8%',
      render: (text, record, index) => columnRenderer(text),
    },
    {
      title: headerRenderer('Partner Company Address', true),
      dataIndex: 'partner_address',
      key: 'partner_address',
      width: '8%',
      render: (text, record, index) => columnRenderer(text),
    },
    {
      title: headerRenderer('Partner Name'),
      dataIndex: 'partner_name',
      key: 'partner_name',
      width: '8%',
      render: (text, record, index) => columnRenderer(text),
    },
    {
      title: headerRenderer('Partner Email Id'),
      dataIndex: 'partner_email_id',
      key: 'partner_email_id',
      width: '8%',
      render: (text, record, index) => columnRenderer(text, true),
    },
    // {
    //   title: headerRenderer('Partner Address'),
    //   dataIndex: 'partner_address',
    //   key: 'partner_address',
    //   ellipsis: true,
    //   width: '8%',
    //   render: (text, record, index) => columnRenderer(text, true),
    // },
    {
      title: headerRenderer('Partner User'),
      dataIndex: 'partner_user',
      key: 'partner_user',
      ellipsis: true,
      width: '8%',
      render: (text, record, index) => columnRenderer(text, true),
    },

    {
      title: headerRenderer('Action'),
      dataIndex: 'action',
      key: 'action',
      width: '6%',
      onCell: (record) => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (_, record) => (
        <Space>
          <IconButton
            onClick={() =>
              navigate(`/create/partner?mode=edit`, {
                state: record.partner,
              })
            }
            tooltipName={'Edit'}
            src={editIcon}
            srcActive={editActiveIcon}
          />
          <IconButton
            loading={
              deactivatePartnerLoading && isDeactivatingId === record.key
            }
            tooltipName={'Deactivate partner'}
            src={deleteIcon}
            srcActive={deleteActiveIcon}
            onClick={() => {
              openNotification({
                onApprove: () => handleDeativatePartner(record.key),
                title: `Delete Partner`,
                subTitle: `Are you sure you want to delete this partner?`,
                approveBtnClasses:
                  'w-full !py-4.5 !bg-primary-red hover:!border-primary-red hover:!text-primary-red  !text-secondary-bg hover:!bg-transparent  hover:!border',
                cancelBtnClasses: 'w-full !py-4.5',
                approveText: 'Delete',
                cancelText: 'Cancel',
              });
            }}
          />
        </Space>
      ),
    },
  ];

  //transforming partner list
  const transformedPartnersList = partnersWithPartnerUsers.map((partner) => {
    const users = partner.PartnerAddresses.filter((p) => p.is_active)
      .flatMap((pat) => pat.contacts)
      .filter((c) => c.is_active)
      .map((user) => user.contact_name);
    const userAddresses = partner.PartnerAddresses.filter(
      (p) => p.is_active,
    ).map((user) => user.address);
    return {
      key: partner.id,
      partner_name: partner.partner_name,
      partner_company_name: partner.partner_company_name,
      partner_address: partner.partner_address,
      partner_email_id: partner.partner_email,
      partner,
      partner_user: [...new Set(users)].join(', '),
      // partner_address: [...new Set(userAddresses)].join(', '),
    };
  });

  //fetching aprtners list
  useEffect(() => {
    const payload = {
      page: currentPage,
      search: searchTerm,
    };

    dispatch(getPartnersWithPartnerUsers(payload));
  }, [searchTerm, currentPage]);

  return (
    <Card
      className="pb-4 my-2 md:my-8"
      headerClasses={
        'font-segoe-ui flex justify-end font-semibold text-2xl text-primary-bg'
      }
      header={
        <Button
          className="!py-4 text-md font-segoe-ui bg-primary-bg  text-secondary-bg border hover:!border-primary-bg hover:!text-primary-bg hover:!bg-transparent"
          onClick={() => navigate('/create/partner')}
        >
          Create Partner
        </Button>
      }
    >
      <Card
        className={
          'mt-5 w-full rounded-lg !bg-secondary-bg font-segoe-ui shadow-equal-sides'
        }
      >
        <Flex vertical>
          {/* header */}
          <div className="flex items-center justify-between px-6 py-5">
            <Flex vertical gap={'4px'}>
              <h1 className="text-lg font-segoe-ui text-tertiary-text">
                Partners
              </h1>
              <p className="text-xs sm:text-sm font-segoe-ui text-tertiary-placeholder">
                Keep track of partners and their details
              </p>
            </Flex>
            <SearchBar
              onChange={setSearchTerm}
              disabled={partnersWithPartnerUsersLoading}
              placeholder="Search by partner name"
            />
          </div>

          {/* content */}
          <Flex className="relative w-full mb-2 border-t border-gray-200 sticky-footer">
            <Table
              onRow={(record) => ({
                onClick: (e) => {
                  e.stopPropagation();
                  //   navigate(`/dashboard/project/${record.id}`);
                },
              })}
              // hideScroll={!xs && !sm}
              totalPages={pagination?.total_page}
              pagination={true}
              pageSize={pagination?.total_perpage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              columns={columns}
              bordered
              dataSource={transformedPartnersList}
              loading={partnersWithPartnerUsersLoading}
            />
          </Flex>
        </Flex>
      </Card>
    </Card>
  );
}
