import { PlusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import Card from '@common/Card';
import ListView from '../components/ListView';
import welcomeCardImg from '@assets/images/welcome_card.png';
import { useNavigate } from 'react-router-dom';
import { getLoggedInUser, TabTitle } from '@utils/helpers';
import {
  GRANUAL_USER,
  PARTNER,
  PARTNER_USER,
  PERMISSIONS,
} from '@utils/roleRights';

export default function DashboardPage() {
  TabTitle('Dashboard | Granules Alliance');

  const navigate = useNavigate();
  const { user, role } = getLoggedInUser();

  return (
    <div className="flex flex-col items-center justify-center">
      {/* dasboard welcome card */}
      {(role === GRANUAL_USER || role === PARTNER) && (
        <div className="w-full py-12 my-8 rounded-[30px] flex overflow-hidden relative object-fill justify-center gap-5 items-center flex-col">
          <img
            src={welcomeCardImg}
            alt="welcome card"
            className="absolute w-full h-full -z-0 rounded-[30px]"
          />
          <h1 className="z-10 text-3xl font-semibold font-segoe-ui text-secondary-bg">
            Welcome! {user?.username}
          </h1>
          <p className="text-md z-10 font-segoe-ui text-balance text-center text-[#EAECF0]">
            Manage and track all your ongoing projects efficiently, with
            real-time updates and detailed insights
          </p>
          <Space size="middle" className="flex flex-wrap justify-center">
            {PERMISSIONS.canCreateProject(role) && (
              <Button
                icon={<PlusOutlined />}
                className="!py-5 bg-primary-green border-none text-secondary-bg text-sm font-segoe-ui"
                onClick={() => navigate('/project?mode=create')}
              >
                Create New Project
              </Button>
            )}
            {PERMISSIONS.canCreatePartner(role) && (
              <Button
                icon={<PlusOutlined />}
                className="!py-5 bg-secondary-bg text-primary-bg hover:!text-secondary-bg hover:!bg-transparent hover:!border hover:!border-secondary-bg text-sm font-segoe-ui"
                onClick={() => navigate('/partners')}
              >
                Update Partner Details
              </Button>
            )}
            {PERMISSIONS.canAddPartnerScore(role) && (
              <Button
                icon={<PlusOutlined />}
                className="!py-5 border border-secondary-bg text-secondary-bg bg-transparent text-sm font-segoe-ui"
                onClick={() => navigate('/dashboard/partner_score')}
              >
                Add Partner Score
              </Button>
            )}
          </Space>
        </div>
      )}

      {/* dasboard projects list view */}
      <Card
        className={`bg-secondary-bg ${role === PARTNER_USER ? 'my-8' : ''}`}
      >
        <ListView />
      </Card>
    </div>
  );
}
