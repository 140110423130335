import FormInput from '@common/FormInput';
import { getPartners, selectMastersSlice } from '@features/masterRedux/slice';
import { Col, Form, message, Row } from 'antd';
import Modal from 'common/Modal';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import emailIcon from '@assets/icons/input/email.svg';
import { reAssignPartner } from '../redux/slice';
import { useParams } from 'react-router-dom';

export default function ShortCloseModal({
  isOpen,
  onClose,
  onReassign,
  loading,
  partnerUsersList,
  selectedPartnerUser,
  activePartnerType,
  currentSelectedPartnerUsers,
  projectDetailsData,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { projectId } = useParams();

  //transforming partners list
  const transformedPartnerUserList = partnerUsersList
    ?.filter((pu) => pu.is_active)
    ?.map((pa) => {
      return { label: pa.contact_name, value: pa.id };
    });

  //handle craete partner form finish
  const handleOnSubmit = (values) => {
    const payload = {
      ...values,
      project_id: projectId,
      partner_id: selectedPartnerUser.partner_id,
      partner_type_id: activePartnerType,
      from_partner_user_id: selectedPartnerUser.id,
      to_partner_user_id: values.reassign_to,
      to_email: partnerUsersList.find((pa) => pa.id === values.reassign_to)
        ?.email_id,
      cc: 'admin@granules.com',
    };
    if (payload.from_partner_user_id === payload.to_partner_user_id) {
      message.error('Please choose different partner user');
      return;
    }

    dispatch(reAssignPartner(payload)).then((res) => {
      if (res?.payload?.status) {
        onReassign();
      }
    });
  };

  //structoring the partner addresses state on for values change
  const handleFormValuesChange = (changedVal, values) => {
    if (changedVal.reassign_to) {
      const to_email = partnerUsersList.find(
        (pa) => pa.id === values.reassign_to,
      )?.email_id;
      form.setFieldsValue({ to_email: to_email });
    }
  };

  //populating form values
  useEffect(() => {
    form.setFieldsValue({
      reassign_from: selectedPartnerUser.id,
      cc: 'admin@granules.com',
    });
  }, [isOpen]);

  //fetching partners
  useEffect(() => {
    if (isOpen) {
      dispatch(getPartners());
    }
  }, [isOpen]);

  return (
    <Modal
      btnLoading={loading}
      headerTitle="Reassign"
      isOpen={isOpen}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      onAgree={() => form.submit()}
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
      agreeText="Reassign"
      cancelText="Cancel"
      spanWidth={' !w-[80vw] md:!w-[40vw] lg:!w-[30vw]'}
    >
      <Form
        id="reassign_partner_form"
        className="flex flex-col justify-center gap-4"
        layout="vertical"
        onFinish={handleOnSubmit}
        onValuesChange={handleFormValuesChange}
        form={form}
      >
        <Col span={24}>
          <FormInput
            name={'reassign_from'}
            options={transformedPartnerUserList}
            disabled
            label={'Reassign From'}
            type="select"
          />
        </Col>
        <Col span={24}>
          <FormInput
            name={'reassign_to'}
            options={transformedPartnerUserList
              .filter((p) => p.value !== selectedPartnerUser.id)
              .filter((p) => {
                return !currentSelectedPartnerUsers.some(
                  (par) =>
                    par.id === p.value &&
                    projectDetailsData?.Projects_Partners_Mappings.some(
                      (c) => c.contact.id === par.id,
                    ),
                );
              })}
            placeholder="Select partner user"
            optionRender={(option) => {
              return (
                <span className="text-xs font-segoe-ui text-tertiary-text">
                  {option.label}
                </span>
              );
            }}
            label={'Reassign To'}
            type="select"
            required
            validateMsg={'Select partner user'}
          />
        </Col>
        <Col span={24}>
          <FormInput
            name={'to_email'}
            label={'To'}
            prefix={<img src={emailIcon} />}
            disabled
          />
        </Col>
        <Col span={24}>
          <FormInput
            name={'cc'}
            label={'CC'}
            prefix={<img src={emailIcon} />}
            disabled
          />
        </Col>
      </Form>
    </Modal>
  );
}
