import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  activityStop,
  activityToggle,
  bulkActivity,
  partnerReAssign,
  projectCreate,
  projectDetails,
  projectUpdate,
  shortClose,
} from './api';

const actions = {
  CREATE_PROJECT: 'project/CREATE_PROJECT',
  PROJECT_DETAILS: 'project/PROJECT_DETAILS',
  BULK_ACTIVITY: 'project/BULK_ACTIVITY',
  RESASSIGN_PARTNER: 'project/RESASSIGN_PARTNER',
  SHORT_CLOSE_PARTNER: 'project/SHORT_CLOSE_PARTNER',
  STOP_ACTIVITY: 'project/STOP_ACTIVITY',
  TOGGLE_ACTIVITY: 'project/TOGGLE_ACTIVITY',
  UPDATE_PROJECT: 'project/UPDATE_PROJECT',
};

export const createProject = createAsyncThunk(
  actions.CREATE_PROJECT,
  async (payload) => {
    const response = await projectCreate(payload);
    return response;
  },
);

export const getProjectDetails = createAsyncThunk(
  actions.PROJECT_DETAILS,
  async (payload) => {
    const response = await projectDetails(payload);
    return response;
  },
);

export const uploadBulkActivity = createAsyncThunk(
  actions.BULK_ACTIVITY,
  async (payload) => {
    const response = await bulkActivity(payload);
    return response;
  },
);

export const reAssignPartner = createAsyncThunk(
  actions.RESASSIGN_PARTNER,
  async (payload) => {
    const response = await partnerReAssign(payload);
    return response;
  },
);

export const shortClosePartner = createAsyncThunk(
  actions.SHORT_CLOSE_PARTNER,
  async (payload) => {
    const response = await shortClose(payload);
    return response;
  },
);

export const stopActivity = createAsyncThunk(
  actions.STOP_ACTIVITY,
  async (payload) => {
    const response = await activityStop(payload);
    return response;
  },
);

export const toggleActivity = createAsyncThunk(
  actions.TOGGLE_ACTIVITY,
  async (payload) => {
    const response = await activityToggle(payload);
    return response;
  },
);

export const updateProject = createAsyncThunk(
  actions.UPDATE_PROJECT,
  async (payload) => {
    const response = await projectUpdate(payload);
    return response;
  },
);

const initialState = {
  createProjectLoading: false,
  projectDetailsLoading: false,
  projectDetailsData: null,
  uploadBulkActivityLoading: false,
  reAssignPartnerLoading: false,
  shortClosePartnerLoading: false,
  stopActivityLoading: false,
  updateProjectLoading: false,
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create partner
    builder
      .addCase(createProject.pending, (state) => {
        state.createProjectLoading = true;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        const { status, message: msg } = action.payload;
        state.createProjectLoading = false;

        if (status) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createProject.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.createProjectLoading = false;
        message.error(errorMsg);
      });

    // get project details
    builder
      .addCase(getProjectDetails.pending, (state) => {
        state.projectDetailsLoading = true;
      })
      .addCase(getProjectDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.projectDetailsLoading = false;

        if (success) {
          state.projectDetailsData = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getProjectDetails.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.projectDetailsLoading = false;
        message.error(errorMsg);
      });

    // upload bulk activity
    builder
      .addCase(uploadBulkActivity.pending, (state) => {
        state.uploadBulkActivityLoading = true;
      })
      .addCase(uploadBulkActivity.fulfilled, (state, action) => {
        const { status, message: msg } = action.payload;
        state.uploadBulkActivityLoading = false;

        if (status) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(uploadBulkActivity.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.uploadBulkActivityLoading = false;
        message.error(errorMsg);
      });

    // reassign partner before short close
    builder
      .addCase(reAssignPartner.pending, (state) => {
        state.reAssignPartnerLoading = true;
      })
      .addCase(reAssignPartner.fulfilled, (state, action) => {
        const { status, message: msg } = action.payload;
        state.reAssignPartnerLoading = false;

        if (status) {
          // message.success("msg");
        } else {
          message.error(msg);
        }
      })
      .addCase(reAssignPartner.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.reAssignPartnerLoading = false;
        message.error(errorMsg);
      });

    // short close activity
    builder
      .addCase(shortClosePartner.pending, (state) => {
        state.shortClosePartnerLoading = true;
      })
      .addCase(shortClosePartner.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.shortClosePartnerLoading = false;

        if (success) {
          message.success("Partner is short closed for this project");
        } else {
          message.error(msg);
        }
      })
      .addCase(shortClosePartner.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.shortClosePartnerLoading = false;
        message.error(errorMsg);
      });

    // activity stop
    builder
      .addCase(stopActivity.pending, (state) => {
        state.stopActivityLoading = true;
      })
      .addCase(stopActivity.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.stopActivityLoading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(stopActivity.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.stopActivityLoading = false;
        message.error(errorMsg);
      });

    // activity toggle
    builder.addCase(toggleActivity.rejected, (state, action) => {
      const errorMsg = action.payload.message;
      message.error(errorMsg);
    });

    // activity stop
    builder
      .addCase(updateProject.pending, (state) => {
        state.updateProjectLoading = true;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        const { status, message: msg } = action.payload;
        state.updateProjectLoading = false;

        if (status) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(updateProject.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.updateProjectLoading = false;
        message.error(errorMsg);
      });
  },
});

export const selectProjectSlice = (state) => {
  return state.project;
};

export default projectSlice.reducer;
