import leadingIcon from '@assets/icons/input/leading.svg';
import bottomIcon from '@assets/icons/input/bottom.svg';

export const PARTNER_CARDS = [
  {
    index: 0,
    description: 'with the highest project success rates',
    heading: '5 Leading',
    icon: <img src={leadingIcon} />,
  },
  {
    index: 1,
    description: 'with the lowest project success rates',
    heading: 'Bottom 5',
    icon: <img src={bottomIcon} />,
  },
];
