import apiClient from '@utils/apiClient';

// create partner
export const partnerCreate = (payload) => {
  return apiClient.post(apiClient.Urls.createPartner, payload, true);
};

// project by partner id
export const projectsByPartnerId = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.projectsByPartner}/${payload.id}`,
    payload,
    true,
  );
};

// create partner user
export const partnerUserCreate = (payload) => {
  return apiClient.post(`${apiClient.Urls.createPartnerUser}`, payload, true);
};

// get partners with partner users
export const partnersWithPartnerUsers = (payload) => {
  return apiClient.get(`${apiClient.Urls.partnersWithPartnerUsers}`, payload, true);
};

// deacivate a partner
export const partnerDeactivate = (payload) => {
  return apiClient.patch(`${apiClient.Urls.deactivatePartner}`, payload, true);
};

// check dup partner user email
export const checkDupPartnerUser = (payload) => {
  return apiClient.get(`${apiClient.Urls.checkDupPartnerUserEmail}`, payload, true);
};
