import { Col, Row, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectDetailsById, selectAdminSlice } from '../redux/slice';
import ActiveProjectDetails from './ActiveProjectDetails';
import { PROJECT_STATUS } from '@utils/helpers';

const getStatus = (status) => {
  switch (status) {
    case 1:
      return PROJECT_STATUS.completed;
    case 2:
      return PROJECT_STATUS.ongoing;
    case 3:
      return PROJECT_STATUS.delayed;
    case 4:
      return PROJECT_STATUS.completedDelayed;
    default:
      return null;
  }
};

export default function ProjectDetails({ projectList, activeStatus }) {
  const [activeProject, setActiveProject] = useState(null);
  const dispatch = useDispatch();
  const { projectDetailsLoading, projectDetails } =
    useSelector(selectAdminSlice);

  //handle project change e = projectId
  const handleOnProjectChange = (e) => {
    setActiveProject(e);
    dispatch(getProjectDetailsById({ id: e }));
  };

  //transforming project list
  const transformedProjectList = activeStatus
    ? projectList
        .filter((p) => p.status?.toLowerCase() === getStatus(activeStatus))
        .map((p) => ({ label: p.project_name, value: p.id }))
    : [];

  //reseting active project if project status card changes
  useEffect(() => {
    if (activeProject) {
      setActiveProject(null);
    }
  }, [activeStatus]);

  return (
    <div>
      <Row>
        <Col className="flex flex-col gap-2" span={24} md={{ span: 6 }}>
          <label className="text-xs font-semibold font-segoe-ui text-tertiary-text">
            Project
          </label>
          <Select
            placeholder="Please select project"
            showSearch
            optionRender={(option) => {
              return (
                <span className="text-xs font-segoe-ui text-tertiary-text">
                  {option.label}
                </span>
              );
            }}
            loading={projectDetailsLoading}
            className="font-segoe-ui border-2 placeholder:!text-tertiary-placeholder border-[#D0D5DD] !text-xs md:!text-sm input-placeholder selected-label-14 rounded-lg !shadow-none"
            options={transformedProjectList}
            filterOption={(input, option) =>
              (String(option?.label) ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            value={activeProject}
            onChange={handleOnProjectChange}
          />
        </Col>
      </Row>
      {/* project details */}
      <Spin spinning={projectDetailsLoading}>
        {activeProject && (
          <>
            <h3 className="mt-3 text-lg font-semibold font-segoe-ui text-tertiary-text">
              {projectDetails?.project_name}
            </h3>
            <ActiveProjectDetails data={projectDetails} activeProject={activeProject} />
          </>
        )}
      </Spin>
    </div>
  );
}
