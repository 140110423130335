import Table from '@common/Table';
import {
  columnRenderer,
  getFormattedDate,
  headerRenderer,
} from '@utils/helpers';

export default function AuditTrailsTable({ data, loading }) {
  const columns = [
    {
      title: headerRenderer(
        'Base Start Date',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'base_start_date',
      key: 'base_start_date',
      ellipsis: true,
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Base End Date',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'base_end_date',
      key: 'base_end_date',
      ellipsis: true,
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Current Start Date',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'current_start_date',
      key: 'current_start_date',
      ellipsis: true,
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Current End Date',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'current_end_date',
      key: 'current_end_date',
      ellipsis: true,
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Estimated Completion Date',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'estimated_date',
      key: 'estimated_date',
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Actual Completion Date',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'actual_completion_date',
      key: 'actual_completion_date',
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Overall Duration',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'overall_duration',
      key: 'overall_duration',
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Estimated Duration',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'estimated_duration',
      key: 'estimated_duration',
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Status',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'completion_status',
      key: 'completion_status',
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Date of remarks',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'date_of_remarks',
      key: 'date_of_remarks',
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, true, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Remarks by Granules',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'remarks_by_granules',
      key: 'remarks_by_granules',
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, true, null, 'text-xs'),
    },
    {
      title: headerRenderer(
        'Remarks by partner',
        null,
        false,
        '-',
        'text-xs font-bold font-segoe-ui text-tertiary-placeholder',
      ),
      dataIndex: 'remarks_by_partner',
      key: 'remarks_by_partner',
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, true, null, 'text-xs'),
    },
  ];

  //transforming activities
  const transformedactivities = data?.map((trail) => {
    return {
      id: trail?.id,
      key: trail?.id,
      base_start_date: trail.base_start_date
        ? getFormattedDate(trail.base_start_date)
        : '-',
      base_end_date: trail.base_end_date
        ? getFormattedDate(trail.base_end_date)
        : '-',
      current_start_date: trail.current_start_date
        ? getFormattedDate(trail.current_start_date)
        : '-',
      current_end_date: trail.current_end_date
        ? getFormattedDate(trail.current_end_date)
        : '-',
      estimated_date: trail.estimated_date
        ? getFormattedDate(trail.estimated_date)
        : '-',
      actual_completion_date: trail.actual_completion_date
        ? getFormattedDate(trail.actual_completion_date)
        : '-',
      overall_duration: trail.overall_duration || '-',
      estimated_duration: trail.estimated_duration || '-',
      completion_status: trail.completion_status || '-',
      remarks_by_granules: trail.remarks_by_granules || '-',
      remarks_by_partner: trail.remarks_by_partner || '-',
      date_of_remarks: trail.createdAt
        ? getFormattedDate(trail.createdAt)
        : '-',
    };
  });

  return (
    <Table
      columns={columns}
      loading={loading}
      spanWidth={2200}
      rowClassName={(record, index) => `hover:!bg-primary-screen`}
      components={{
        body: {
          cell: (props) => (
            <td {...props} className="!px-3 !py-2 !border-gray-200" />
          ),
        },
        header: {
          cell: (props) => (
            <th
              {...props}
              className="!bg-secondary-bg !py-2 !px-3 !border-gray-200"
            >
              {props.children}
            </th>
          ),
        },
      }}
      dataSource={transformedactivities}
    />
  );
}
